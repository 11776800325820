import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { COMING_SOON_TAB, DEAL_STATUSES, ON_GOING_TAB } from 'Component/FlashSaleHomeWidget/FlashSaleHomeWidget.config';
import { FLASH_SALE_DISCOUNT_TYPES } from 'Component/FlashSaleTimer/FlashSaleTimer.config';
import { PERCENT_STATIC } from 'Component/ProductPrice/ProductPrice.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { flashSaleHomePageDataType } from 'Type/FlashSale';
import { getFlashSaleTimeDiffWithCurrentTime, isFlashSaleEnabled } from 'Util/FlashSale';
import { getRoundedRegularPrice } from 'Util/Price/Price';
import { getProductTaxConfig } from 'Util/Product/Product';

import FlashSaleHomeWidget from './FlashSaleHomeWidget.component';

export const FlashSaleProductsDispatcher = import(
    'Store/FlashSale/FlashSale.dispatcher'
);

/** @namespace AdmScandipwa/Component/FlashSaleHomeWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    flashSaleData: state.FlashSaleReducer.flashSaleWidgetData,
    isFlashSaleWidgetLoading: state.FlashSaleReducer.isFlashSaleWidgetLoading,
    runningFlashSalePath: state.ConfigReducer.flashsale_pwa_running_deal_category,
    upcomingFlashSalePath: state.ConfigReducer.flashsale_pwa_upcoming_deal_category,
    timeZone: state.ConfigReducer.timezone
});

/** @namespace AdmScandipwa/Component/FlashSaleHomeWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getFlashSaleHomePageData: () => FlashSaleProductsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getFlashSaleHomePageData(dispatch)
    ),
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace AdmScandipwa/Component/FlashSaleHomeWidget/Container/FlashSaleHomeWidgetContainer */
export class FlashSaleHomeWidgetContainer extends PureComponent {
    static propTypes = {
        getFlashSaleHomePageData: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        timeZone: PropTypes.string.isRequired,
        flashSaleData: flashSaleHomePageDataType
    };

    static defaultProps = {
        flashSaleData: { items: [] }
    };

    state = {
        runningFlashSaleProducts: [],
        upcomingFlashSaleProducts: [],
        productData: {
            products: {
                items: [],
                total_count: 0,
                page_info: { current_page: 1 }
            }
        },
        activeTab: ON_GOING_TAB
    };

    containerFunctions = {
        setActiveTab: this.setActiveTab.bind(this)
    };

    componentDidMount() {
        this.isFlashSaleEnabled = isFlashSaleEnabled();
        this.getFlashSaleHomePageData();
    }

    componentDidUpdate(prevProps) {
        const { flashSaleData: prevFlashSaleData } = prevProps;
        const { flashSaleData } = this.props;
        if (flashSaleData !== prevFlashSaleData) {
            this.setProducts();
            const { runningFlashSaleProducts } = this.state;
            this.setActiveTab(runningFlashSaleProducts.length ? ON_GOING_TAB : COMING_SOON_TAB);
        }
    }

    async getFlashSaleHomePageData() {
        if (!this.isFlashSaleEnabled) {
            return;
        }
        const { getFlashSaleHomePageData, showErrorNotification } = this.props;
        this.setState({ isLoading: true });
        try {
            const flashSaleData = await getFlashSaleHomePageData();
            this.setState({ flashSaleData });
            this.setProducts();
            const { runningFlashSaleProducts } = this.state;
            this.setActiveTab(runningFlashSaleProducts.length ? ON_GOING_TAB : COMING_SOON_TAB);
        } catch (error) {
            showErrorNotification('Error in getting flash sale details');
        }
        this.setState({ isLoading: false });
    }

    getDiscountedFlashSalePrice({ product, flashSaleDeal }) {
        const {
            deal_discount_amount,
            deal_discount_type
        } = flashSaleDeal;

        const {
            flash_sale: { product_flash_sale_price } = {}
        } = product;

        if (product_flash_sale_price) {
            return product_flash_sale_price;
        }

        const regularPrice = getRoundedRegularPrice({
            productProps: { ...product, price: product.price_range, displayTaxInPrice: getProductTaxConfig() }
        });

        if (deal_discount_type === FLASH_SALE_DISCOUNT_TYPES.PERCENTAGE) {
            return ((regularPrice * (PERCENT_STATIC - deal_discount_amount)) / PERCENT_STATIC);
        }

        return regularPrice - deal_discount_amount;
    }

    updateProductsWithFlashSaleDetails(flashSaleDeal = {}) {
        const { timeZone } = this.props;
        const {
            deal_products = [],
            deal_start_at,
            deal_status,
            deal_end_at
        } = flashSaleDeal;

        if (!deal_products.length) {
            return deal_products;
        }

        const { totalSecondDiff: difference } = getFlashSaleTimeDiffWithCurrentTime({
            flashSale: flashSaleDeal, timeZone
        });

        // Skip the products here which has the difference in negative or zero, otherwise it goes in the loop
        if (difference <= 0) {
            return [];
        }

        return deal_products.map((product) => ({
            ...product,
            flash_sale: {
                ...product.flash_sale,
                isHomePageWidget: true,
                product_flash_sale_price: this.getDiscountedFlashSalePrice({ flashSaleDeal, product }),
                deal_end_at,
                deal_start_at,
                deal_status
            }
        })).filter((product) => product.is_salable);
    }

    setProducts() {
        const { flashSaleData } = this.props;

        const runningFlashSaleDeal = flashSaleData.items
            .find(({ deal_status }) => deal_status === DEAL_STATUSES.RUNNING);
        const runningFlashSaleProducts = this.updateProductsWithFlashSaleDetails(runningFlashSaleDeal);

        const upcomingFlashSaleDeal = flashSaleData.items
            .find(({ deal_status }) => deal_status === DEAL_STATUSES.UPCOMING);
        const upcomingFlashSaleProducts = this.updateProductsWithFlashSaleDetails(upcomingFlashSaleDeal);

        this.setState({ runningFlashSaleProducts, upcomingFlashSaleProducts });
    }

    setActiveTab(activeTab) {
        const { runningFlashSaleProducts, upcomingFlashSaleProducts } = this.state;
        const products = activeTab === ON_GOING_TAB ? runningFlashSaleProducts : upcomingFlashSaleProducts;
        const totalProducts = products.length;
        const productData = JSON.parse(JSON.stringify({
            products: {
                items: products,
                total_count: totalProducts,
                page_info: { current_page: 1 }
            }
        }));

        this.setState({ productData, activeTab });
    }

    render() {
        if (!this.isFlashSaleEnabled) {
            return null;
        }

        return (
            <FlashSaleHomeWidget
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.state }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashSaleHomeWidgetContainer);
