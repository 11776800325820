import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuItemContainer as SourceMenuItemContainer
} from 'SourceComponent/MenuItem/MenuItem.container';

/** @namespace AdmScandipwa/Component/MenuItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace AdmScandipwa/Component/MenuItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace AdmScandipwa/Component/MenuItem/Container/MenuItemContainer */
export class MenuItemContainer extends SourceMenuItemContainer {}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
