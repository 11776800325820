import {
    getInitialState as sourceGetInitialState
} from 'SourceStore/Checkout/Checkout.reducer';

import {
    UPDATE_CHECKOUT_ALL_REWARD_CARD_NUMBER,
    UPDATE_DELIVERY_ESTIMATION,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_PICKUP_STORE_LIST,
    UPDATE_SELECTED_PICKUP_STORE_ID, UPDATE_SHIPPING_ADDRESS_INFO,
    UPDATE_SHIPPING_FIELDS
} from './Checkout.action';

/** @namespace AdmScandipwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    storeList: [],
    selectedPickupStoreId: false,
    deliveryEstimation: false
});

/** @namespace AdmScandipwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };

    case UPDATE_EMAIL:
        const { email } = action;
        return {
            ...state,
            email
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;
        return {
            ...state,
            isEmailAvailable
        };

    case UPDATE_PICKUP_STORE_LIST:
        const { storeList } = action;
        return {
            ...state,
            storeList
        };

    case UPDATE_SELECTED_PICKUP_STORE_ID:
        const { selectedPickupStoreId } = action;
        return {
            ...state,
            selectedPickupStoreId
        };
    case UPDATE_CHECKOUT_ALL_REWARD_CARD_NUMBER:
        const { allRewardCardNumber } = action;
        return {
            ...state,
            allRewardCardNumber
        };

    case UPDATE_DELIVERY_ESTIMATION:
        const { deliveryEstimation } = action;
        return {
            ...state,
            deliveryEstimation
        };

    case UPDATE_SHIPPING_ADDRESS_INFO:
        const { shippingAddressData } = action;
        return {
            ...state,
            shippingAddressData
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
