import {
    NewVersionPopupContainer as SourceNewVersionPopup
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';

/** @namespace AdmScandipwa/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends SourceNewVersionPopup {
    /**
     * Overridden to remove new version popup loading on changes
     * @override
     */

    componentDidMount = () => false;
}

export default NewVersionPopupContainer;
