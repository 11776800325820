import { ProductCompareQuery as SourceProductListQuery } from 'SourceQuery/ProductCompare.query';
import { isFlashSaleEnabled } from 'Util/FlashSale';

/** @namespace AdmScandipwa/Query/ProductCompare/Query/ProductCompareQuery */
export class ProductCompareQuery extends SourceProductListQuery {
    _getQueryFields() {
        return [
            'count',
            'ids',
            this._getProductsField()
        ];
    }

    _getProductsFields() {
        const productFields = [
            'id',
            'name',
            'sku',
            'url',
            'type_id',
            'is_salable',
            this._getProductThumbnailField(),
            this._getProductSmallField(),
            this._getPriceRangeField(),
            this._getAttributesField(),
            this._getConfigurableProductFragment(),
            this._getGroupedProductItems(),
            this._getCustomizableProductFragment()
        ];

        if (isFlashSaleEnabled()) {
            productFields.push(this._getFlashSaleField());
        }

        return productFields;
    }
}
export default new ProductCompareQuery();
