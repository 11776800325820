import React from 'react';

import Loader from 'Component/Loader';
import SourceUrlRewrites from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import './UrlRewrites.style.scss';

/** @namespace AdmScandipwa/Route/UrlRewrites/Component/UrlRewritesComponent */
export class UrlRewritesComponent extends SourceUrlRewrites {
    renderDefaultPage() {
        return (
            <main
              block="UrlRewrites"
              elem="main"
            >
                <Loader isLoading />
            </main>
        );
    }
}

export default UrlRewritesComponent;
