/* eslint-disable max-lines */
import { GENDER_OPTIONS } from 'Component/MyAccountCustomerForm/MyAccountCustomerForm.config';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { AUTH_TOKEN } from 'Util/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getDateFromStringYYYYMMDD } from 'Util/Date';
import { FIND_SHARE_EVENT_TYPES, sendFindShareEvent } from 'Util/GTM/findShare';

import { getFinalPrice } from '../Price/Price';

export const BRAND_ATTRIBUTE = 'brand';
export const MAX_MOENGAGE_CATEGORIES = 3;

/** @namespace AdmScandipwa/Util/GTM/sendGTMEvent */
export const sendGTMEvent = ({ event, eventData, otherData = {} }) => {
    const eventObj = {
        event,
        ...otherData,
        ecommerce: eventData
    };

    if (window.dataLayer) {
        window.dataLayer.push(eventObj);
    }
};

/** @namespace AdmScandipwa/Util/GTM/sendMoEngageEvent */
export const sendMoEngageEvent = ({
    event, eventData, destroySession, customer_id
}) => {
    if (window.Moengage) {
        window.Moengage.track_event(event, eventData);
        if (destroySession) {
            window.Moengage.destroy_session();
        }
        if (customer_id) {
            window.Moengage.add_unique_user_id(customer_id);
        }
    }
};

/** @namespace AdmScandipwa/Util/GTM/__getProductBrand */
export const __getProductBrand = (product) => {
    const { attributes = {} } = product;
    if (Array.isArray(attributes)) {
        const { attribute_options } = attributes.find(
            ({ attribute_code }) => attribute_code === BRAND_ATTRIBUTE
        ) || {};

        return { item_brand: !attribute_options ? '' : attribute_options[0].label };
    }

    const { attribute_options, attribute_value } = Object.values(attributes).find(
        ({ attribute_code }) => attribute_code === BRAND_ATTRIBUTE
    ) || {};

    return { item_brand: !attribute_options ? '' : attribute_options[attribute_value].label };
};

/** @namespace AdmScandipwa/Util/GTM/__getProductPrice */
export const __getProductPrice = (product, sku) => {
    const productVariant = (product.variants || []).find((record) => record.sku === sku) || product;
    return product.price || getFinalPrice({
        productProps: { product: productVariant, price: productVariant.price_range }
    });
};

/** @namespace AdmScandipwa/Util/GTM/__getProductCategory */
export const __getProductCategory = ({ categories = [] }) => categories.reduce(
    (finalObj, { name }, index) => ({ ...finalObj, [`item_category${index + 1 === 1 ? '' : (index + 1)}`]: name }),
    {}
);

/** @namespace AdmScandipwa/Util/GTM/__getMoProductCategory */
export const __getMoProductCategory = ({ categories = [] }) => categories.slice(0, MAX_MOENGAGE_CATEGORIES).reduce(
    (finalObj, { name, id }, index) => ({
        ...finalObj,
        [`category${index + 1 === 1 ? '' : (index + 1)}`]: name,
        [`category_id${index + 1 === 1 ? '' : (index + 1)}`]: id
    }),
    {}
);

/** @namespace AdmScandipwa/Util/GTM/__getProductListName */
export const __getProductListName = ({ categories = [] }) => categories.map(({ name }) => name).toString();

/** @namespace AdmScandipwa/Util/GTM/__getProductCategoryPath */
export const __getProductCategoryPath = ({ categories = [] }) => categories.map(({ url }) => url).join(';');

/** @namespace AdmScandipwa/Util/GTM/__getTwoDigitNumber */
// eslint-disable-next-line no-magic-numbers
export const __getTwoDigitNumber = (number) => (number < 10 ? `0${number}` : number);

/** @namespace AdmScandipwa/Util/GTM/__getOrderDate */
export const __getOrderDate = () => {
    const date = new Date();
    return `${date.getFullYear()}-${__getTwoDigitNumber(date.getMonth() + 1)}-${__getTwoDigitNumber(date.getDate())}`
        + ` ${__getTwoDigitNumber(date.getHours())}:`
        + `${__getTwoDigitNumber(date.getMinutes())}:${__getTwoDigitNumber(date.getSeconds())}`;
};

/** @namespace AdmScandipwa/Util/GTM/__getProductConfigurableOptions */
export const __getProductConfigurableOptions = ({ cartItem }) => {
    const { product, sku, customizable_options = [] } = cartItem;
    const { configurable_options = {}, attributes } = product;
    const selectedProductVariant = (product.variants || []).find((record) => record.sku === sku) || product;
    const selectedConfigurableOptions = Object.values(configurable_options).map((configurableOption) => {
        const { attribute_code, attribute_label: optionName } = configurableOption;
        const attributeOptions = attributes[attribute_code].attribute_options;
        const selectedAttributeValue = selectedProductVariant.attributes[attribute_code].attribute_value;
        const optionValue = attributeOptions[selectedAttributeValue].label;
        return { optionName, optionValue };
    });

    const selectedCustomizableOptions = customizable_options.map((record) => {
        const { label: optionName, values } = record;
        return { optionName, optionValue: values.map((record) => record.label).toString() };
    });

    const allOptions = [...selectedConfigurableOptions, ...selectedCustomizableOptions];

    const configurableOptionNames = allOptions.map(({ optionName }) => optionName).join(';');

    const configurableOptionValues = allOptions.map(({ optionValue }) => optionValue).join(';');

    return { configurableOptionNames, configurableOptionValues };
};

/** @namespace AdmScandipwa/Util/GTM/__getProductAttributeSet */
export const __getProductAttributeSet = () => ({
    attribute_set_id: '4',
    attribute_set_name: 'Default'
});

/** @namespace AdmScandipwa/Util/GTM/__getVariant */
export const __getVariant = (item) => (item.variant || (item?.product?.sku !== item.sku ? item.sku : null));

/** @namespace AdmScandipwa/Util/GTM/__getId */
export const __getId = (product) => product.sku || product.id;

/** @namespace AdmScandipwa/Util/GTM/__getProductObj */
export const __getProductObj = ({
    product, item = {}, position, currency, showListObj
}) => {
    const productData = item.product || product;
    return ({
        id: __getId(productData),
        sku: __getId(productData),
        name: productData.name,
        item_variant: __getVariant(item),
        price: __getProductPrice(productData),
        list: showListObj ? __getProductListName(productData) : undefined,
        position,
        quantity: item.qty || item.quantity,
        ...__getProductCategory(productData),
        ...__getProductBrand(productData),
        currency,
        path: productData.url,
        category_path: __getProductCategoryPath(productData)
    });
};

/** @namespace AdmScandipwa/Util/GTM/__getMoProductObj */
export const __getMoProductObj = (productObj) => {
    const { product, item = {} } = productObj;
    const productData = item.product || product || item;
    return ({
        product_name: productData.name,
        product_id: __getId(productData),
        ...__getMoProductCategory(productData),
        product_price: __getProductPrice(productData),
        quantity: item.qty || item.quantity
    });
};

/** @namespace AdmScandipwa/Util/GTM/openProductPageGTMEvent */
export const openProductPageGTMEvent = (product) => {
    const eventData = {
        detail: {
            actionField: { list: 'Product View' },
            products: [__getProductObj({ product })]
        }
    };

    sendMoEngageEvent({
        event: 'View Product',
        eventData: { ...__getMoProductObj({ product }) }
    });

    sendGTMEvent({ event: 'gtm.load', eventData });
    sendFindShareEvent({ event: FIND_SHARE_EVENT_TYPES.OPEN_PRODUCT_PAGE, eventData: product });
};

/** @namespace AdmScandipwa/Util/GTM/openProductListGTMEVent */
export const openProductListGTMEVent = ({ products, currencyCode }) => {
    const eventData = {
        currencyCode,
        impressions: products.map((product, position) => __getProductObj({
            product, position, showListObj: true
        }))
    };

    sendGTMEvent({ event: 'gtm.load', eventData });
};

/** @namespace AdmScandipwa/Util/GTM/addToCartGTMEvent */
export const addToCartGTMEvent = (cartItem) => {
    const {
        currencyCode, product
    } = cartItem;
    const eventData = {
        currencyCode,
        add: {
            products: [
                __getProductObj({ product, item: cartItem })
            ]
        }
    };

    sendMoEngageEvent({
        event: 'Add to Cart',
        eventData: { ...__getMoProductObj({ product, item: cartItem }) }
    });
    sendGTMEvent({ event: 'addToCart', eventData });
};

/** @namespace AdmScandipwa/Util/GTM/removeFromCartGTMEvent */
export const removeFromCartGTMEvent = (item) => {
    const { currencyCode } = item;
    const eventData = {
        currencyCode,
        remove: {
            products: [
                __getProductObj({ item })
            ]
        }
    };

    sendGTMEvent({ event: 'removeFromCart', eventData });
};

/** @namespace AdmScandipwa/Util/GTM/viewCartGTMEvent */
export const viewCartGTMEvent = ({
    cartItems = [], coupon, total, currencyCode
}) => {
    const eventData = {
        checkout: {
            actionField: {
                step: '1',
                option: 'Shopping Cart'
            },
            products: cartItems.map((item) => __getProductObj({ item, currency: currencyCode })),
            hasItems: !!cartItems.length,
            hasCoupon: !!coupon,
            coupon: coupon || '',
            total: total || 0
        }
    };

    sendGTMEvent({ event: 'view_cart', eventData });
};

/** @namespace AdmScandipwa/Util/GTM/openHomePageGTMEvent */
export const openHomePageGTMEvent = ({ currencyCode }) => {
    const eventData = {
        currencyCode
    };

    const otherData = {
        dynx_itemid: '0',
        dynx_pagetype: 'home',
        dynx_totalvalue: '0'
    };

    sendGTMEvent({ event: 'gtm.load', eventData, otherData });
};

/** @namespace AdmScandipwa/Util/GTM/__getMoEngagePurchaseEventProductCategories */
export const __getMoEngagePurchaseEventProductCategories = (moEngageProducts) => {
    const categoryLevels = ['', '2', '3'];
    return categoryLevels.reduce((reducedCategoryObj, categoryIndex) => {
        const categoryKey = `category${categoryIndex}`;
        const categoryIdKey = `category${categoryIndex}_id`;
        const categoryInputIdKey = `category_id${categoryIndex}`;
        const categoryObj = {
            [categoryKey]: moEngageProducts.reduce(
                (categories, { [categoryKey]: category = '' }) => ([...categories, category]), []
            ),
            [categoryIdKey]: moEngageProducts.reduce(
                (categoryIds, { [categoryInputIdKey]: categoryId = '' }) => ([...categoryIds, categoryId]), []
            )
        };

        return { ...reducedCategoryObj, ...categoryObj };
    }, {});
};

/** @namespace AdmScandipwa/Util/GTM/__sendMoEngageOrderSuccessEvent */
export const __sendMoEngageOrderSuccessEvent = ({
    orderDetails,
    moEngageConversionFromBE
}) => {
    const { items } = orderDetails;

    // Create the Obj for Mo engage
    const moEngageProducts = (items).map(
        (item) => __getMoProductObj({ item })
    );

    const moEngageConversionFromBEJSON = moEngageConversionFromBE && JSON.parse(moEngageConversionFromBE);

    const moEngageObj = {
        event: 'Purchase',
        eventData: moEngageConversionFromBEJSON || {
            product_name: moEngageProducts.reduce(
                (productNames, { product_name }) => ([...productNames, product_name]), []
            ),
            product_id: moEngageProducts.reduce(
                (productIds, { product_id }) => ([...productIds, product_id]), []
            ),
            product_price: moEngageProducts.reduce(
                (productPrices, { product_price }) => ([...productPrices, product_price]), []
            ),
            ...__getMoEngagePurchaseEventProductCategories(moEngageProducts),
            currency: orderDetails.quote_currency_code,
            subtotal: orderDetails.subtotal,
            discount: orderDetails.discount_amount,
            grand_total: orderDetails.grand_total,
            promo_code: orderDetails.coupon_code || '',
            shipping_fee: orderDetails.shipping_amount
        }
    };

    sendMoEngageEvent(moEngageObj);
};

/** @namespace AdmScandipwa/Util/GTM/onOrderSuccessGTMEvent */
export const onOrderSuccessGTMEvent = ({
    orderId,
    orderDetails = { items: [] },
    paymentInformation = { paymentMethod: { code: '' } },
    gtmConversionFromBE,
    moEngageConversionFromBE
}) => {
    const { paymentMethod: { code: payment_type } } = paymentInformation;
    const {
        subtotal,
        items = [],
        shipping_amount: shipping,
        tax_amount: tax,
        grand_total: revenue,
        coupon_code: coupon,
        discount_amount: discount,
        quote_currency_code: currencyCode
    } = orderDetails;

    // Process the Obj from BE
    const gtmConversionFromBEJson = gtmConversionFromBE && JSON.parse(gtmConversionFromBE);
    const gtmConversionFromBEEcomObj = gtmConversionFromBEJson && gtmConversionFromBEJson.ecommerce;

    // Create the obj for the GTM event
    const eventData = gtmConversionFromBEEcomObj || {
        purchase: {
            actionField: {
                id: orderId,
                affiliation: 'Main Website-Alldaymarket-Default Store View',
                order_id: orderId,
                subtotal,
                shipping,
                tax,
                revenue,
                discount,
                coupon: coupon || '',
                currency: currencyCode,
                payment_type,
                created_at: __getOrderDate(),
                items: items.reduce(
                    (itemsString, item, index) => `${itemsString}${item.sku}${index === (items.length - 1) ? '' : ';'}`,
                    ''
                ),
                items_qty: items.reduce(
                    (itemsString, item, index) => `${itemsString}${item.sku}:`
                        + `${item.qty}${index === (items.length - 1) ? '' : ';'}`,
                    ''
                )
            },
            products: items.map((item) => __getProductObj({
                item, currency: currencyCode
            }))
        }
    };

    sendGTMEvent({ event: 'purchase', eventData });
    __sendMoEngageOrderSuccessEvent({ orderDetails, moEngageConversionFromBE });
    sendFindShareEvent({
        event: FIND_SHARE_EVENT_TYPES.ORDER_SUCCESS,
        eventData: { orderId, orderDetails, gtmConversionFromBEJson }
    });
};

/** @namespace AdmScandipwa/Util/GTM/openCheckoutPageGTMEvent */
export const openCheckoutPageGTMEvent = ({
    checkoutStep,
    orderDetails,
    currencyCode
}) => {
    const {
        items = [],
        grand_total: total,
        coupon_code: coupon
    } = orderDetails;
    const eventData = {
        checkout: {
            actionField: {
                step: checkoutStep === SHIPPING_STEP ? '2' : '3',
                option: 'Checkout Page'
            },
            products: items.map((item) => __getProductObj({
                item, currency: currencyCode
            })),
            hasItems: !!items.length,
            hasCoupon: !!coupon,
            coupon,
            total
        }
    };

    sendGTMEvent({ event: 'checkout', eventData });
};

/** @namespace AdmScandipwa/Util/GTM/onSubscribeNewsLatterGTMEvent */
export const onSubscribeNewsLatterGTMEvent = ({ email }) => {
    sendGTMEvent({ event: 'new_subscriber', otherData: { email } });
};

/** @namespace AdmScandipwa/Util/GTM/onAddToHomeScreenGTMEvent */
export const onAddToHomeScreenGTMEvent = () => {
    sendGTMEvent({ event: 'installWebApp' });
};

/** @namespace AdmScandipwa/Util/GTM/onRegisterUserGTMEvent */
export const onRegisterUserGTMEvent = ({ customer: { firstname, lastname, email } }) => {
    const userData = {
        first_name: firstname,
        last_name: lastname,
        user_name: `${firstname} ${lastname}`,
        email,
        registration_date: new Date()
    };

    sendMoEngageEvent({
        event: 'Registration Completed',
        eventData: userData
    });
};

/** @namespace AdmScandipwa/Util/GTM/__getUserName */
export const __getUserName = ({ firstname, lastname }) => `${firstname} ${lastname}`;

/** @namespace AdmScandipwa/Util/GTM/__setMoengageCustomerDetails */
export const __setMoengageCustomerDetails = (customer) => {
    if (!customer || !customer.email) {
        return;
    }

    const {
        email, firstname, lastname,
        gender, date_of_birth
    } = customer;
    const user_name = __getUserName({ firstname, lastname });

    if (window.Moengage) {
        window.Moengage.add_first_name(firstname);
        window.Moengage.add_last_name(lastname);
        window.Moengage.add_email(email);
        window.Moengage.add_user_name(user_name);
        if (gender) {
            const moEngageGenderObj = GENDER_OPTIONS.find(({ value }) => value === gender);
            if (moEngageGenderObj) {
                window.Moengage.add_gender(moEngageGenderObj.moEngageValue);
            }
        }

        if (date_of_birth) {
            const date = getDateFromStringYYYYMMDD(date_of_birth);
            if (date) {
                window.Moengage.add_birthday(date);
            }
        }
    }
};

/** @namespace AdmScandipwa/Util/GTM/onLoginUserGTMEvent */
export const onLoginUserGTMEvent = (customer = {}) => {
    if (!customer || !customer.email) {
        return;
    }
    const {
        email, firstname, lastname, customer_id
    } = customer;
    const user_name = __getUserName({ firstname, lastname });

    const loginData = {
        email,
        user_name
    };

    __setMoengageCustomerDetails(customer);

    sendMoEngageEvent({
        event: 'Login',
        customer_id,
        eventData: loginData
    });
};

/** @namespace AdmScandipwa/Util/GTM/__setMoengageAddressDetails */
export const __setMoengageAddressDetails = (customer) => {
    if (!customer || !customer.addresses || !customer.default_shipping || !BrowserDatabase.getItem(AUTH_TOKEN)) {
        return;
    }

    const address = customer.addresses.find((record) => record.id.toString() === customer.default_shipping);

    if (!address) {
        return;
    }

    const {
        city, district, region: { region }, telephone, street: [street]
    } = address;

    const telephoneWithCountryCode = telephone.indexOf('+') > -1 ? telephone : `+63${telephone.substring(1)}`;

    if (window.Moengage) {
        window.Moengage.add_user_attribute('city', city);
        window.Moengage.add_user_attribute('province', region);
        window.Moengage.add_user_attribute('baranggay', district);
        window.Moengage.add_mobile(telephoneWithCountryCode);
        window.Moengage.add_user_attribute('street_address', street);
    }
};

/** @namespace AdmScandipwa/Util/GTM/onUpdateCustomerDetailsGTMEvent */
export const onUpdateCustomerDetailsGTMEvent = (customer) => {
    __setMoengageCustomerDetails(customer);
    __setMoengageAddressDetails(customer);
};

/** @namespace AdmScandipwa/Util/GTM/onLogoutGTMEvent */
export const onLogoutGTMEvent = (customer = {}) => {
    if (!customer || !customer.email) {
        return;
    }
    const { email, firstname, lastname } = customer;
    const loginData = {
        email,
        user_name: `${firstname} ${lastname}`
    };

    sendMoEngageEvent({
        event: 'Logout',
        eventData: loginData,
        destroySession: true
    });
};
