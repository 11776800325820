import { UPDATE_LINK_NEXT, UPDATE_LINK_PRV } from './LinkHeader.action';

/** @namespace AdmScandipwa/Store/LinkHeader/Reducer/getInitialState */
export const getInitialState = () => ({
    linkData: {}
});

/** @namespace AdmScandipwa/Store/LinkHeader/Reducer/LinkHeaderReducer */
export const LinkHeaderReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        linkDataNext,
        linkDataPrv
    } = action;

    switch (type) {
    case UPDATE_LINK_NEXT:
        return {
            ...state,
            ...linkDataNext
        };
    case UPDATE_LINK_PRV:
        return {
            ...state,
            ...linkDataPrv
        };
    default:
        return state;
    }
};

export default LinkHeaderReducer;
