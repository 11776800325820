import { Breadcrumbs as SourceBreadcrumbs } from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

/** @namespace AdmScandipwa/Component/Breadcrumbs/Component/BreadcrumbsComponent */
export class BreadcrumbsComponent extends SourceBreadcrumbs {
    /**
     * @extends to toggle home breadcrumb base on isHomeVisible State
     * @param {Object} breadcrumbs
     */

    renderBreadcrumbList(breadcrumbs) {
        const { isHomeBreadcrumbVisible } = this.props;
        const breadcrumbList = isHomeBreadcrumbVisible
            ? [...breadcrumbs, { url: '/', name: __('Home') }] : breadcrumbs;

        return breadcrumbList.map((_, i) => this.renderBreadcrumb(
            breadcrumbList[breadcrumbList.length - 1 - i], i
        ));
    }
}

export default BreadcrumbsComponent;
