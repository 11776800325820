import { updateCustomerIsAuthTokenExpired } from '@scandipwa/scandipwa/src/store/MyAccount/MyAccount.action';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import history from 'Util/History';
import { AUTH_ERROR_MESSAGE_SHOW_TIMEOUT } from 'Util/Request/Request.config';

/** @namespace AdmScandipwa/Component/MyAccountLogoutNotifier/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isAuthTokenExpired: state.MyAccountReducer.isAuthTokenExpired
});

/** @namespace AdmScandipwa/Component/MyAccountLogoutNotifier/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setIsAuthTokenExpired: (status) => dispatch(updateCustomerIsAuthTokenExpired(status)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace AdmScandipwa/Component/MyAccountLogoutNotifier/Container/MyAccountLogoutNotifierContainer */
export class MyAccountLogoutNotifierContainer extends PureComponent {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        isAuthTokenExpired: PropTypes.bool.isRequired,
        showNotification: PropTypes.func.isRequired,
        setIsAuthTokenExpired: PropTypes.func.isRequired
    };

    componentDidUpdate(prevProps) {
        const {
            isSignedIn,
            isAuthTokenExpired,
            setIsAuthTokenExpired,
            showNotification
        } = this.props;
        const { isSignedIn: prevIsSignedIn } = prevProps;

        if (isSignedIn !== prevIsSignedIn && isAuthTokenExpired) {
            setTimeout(() => setIsAuthTokenExpired(false), 0);
            const { location: { pathname } } = history;
            if (pathname.indexOf('checkout') > -1) { // Set the redirect for the checkout page only
                // set timeout to show the error message before redirect
                setTimeout(() => {
                    window.location.href = '/cart';
                }, AUTH_ERROR_MESSAGE_SHOW_TIMEOUT);
            }
            showNotification('error', __('Your session has been expired. Please login!'));
        }
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountLogoutNotifierContainer);
