/** @namespace Store/Config/Reducer */
import {
    ProductCompareReducer as sourceProductCompareReducer
} from 'SourceStore/ProductCompare/ProductCompare.reducer';
import { SET_COMPARE_LIST } from 'Store/ProductCompare/ProductCompare.action';
import { getIndexedProducts } from 'Util/Product/Product';

/** @namespace AdmScandipwa/Store/ProductCompare/Reducer/getSortedCompareProducts */
export const getSortedCompareProducts = ({ compareProducts, ids }) => {
    const sortedProducts = [];
    ids.forEach((sortId) => {
        sortedProducts.push(compareProducts.find((record) => record.id === sortId));
    });

    return sortedProducts;
};

/** @namespace AdmScandipwa/Store/ProductCompare/Reducer/ProductCompareReducer */
export const ProductCompareReducer = (state, action) => {
    const { type } = action;

    switch (type) {
    case SET_COMPARE_LIST: {
        const { count = 0, products = [], ids } = action;
        const sortedProducts = getSortedCompareProducts({ compareProducts: products, ids });

        return {
            ...state,
            count,
            products: getIndexedProducts(sortedProducts)
        };
    }

    default:
        return sourceProductCompareReducer(state, action);
    }
};

export default ProductCompareReducer;
