import { connect } from 'react-redux';

import { LAST_REDIRECT_ORDER } from 'Route/Checkout/Checkout.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import { SIMPLE } from 'SourceUtil/Product';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace AdmScandipwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace AdmScandipwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currencyCode: state.ConfigReducer.currencyData.current_currency_code
});

/** @namespace AdmScandipwa/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    /**
     * extended to preventDefault event
     * @param {object} event click event
     * @extends
     */

    buttonClick(event) {
        event.preventDefault();
        event.stopPropagation();
        const { product } = this.props;

        if (!this.redirectIfRequired(product)) {
            super.buttonClick(event);
        }
    }

    /**
     * extended to Validation info
     * success message content changed
     * @extends
     */

    afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault
        } = this.props;

        showNotification('success', __('Product has been added to shopping cart!'));
        setQuantityToDefault();

        this.removeProductFromWishlist();
        this.setState({ isLoading: false });
        BrowserDatabase.deleteItem(LAST_REDIRECT_ORDER);
    }

    /**
     * Redirect to product page if options needs to be selected
     * @param {Object} product object
     */

    redirectIfRequired(product) {
        const {
            url, type_id, has_options, options = []
        } = product;
        const { showErrorNotification } = this.props;
        const needsRedirect = url !== location.pathname && (has_options || type_id !== SIMPLE || options?.length);
        if (needsRedirect) {
            history.push(appendWithStoreCode(url));
            showErrorNotification(__('You need to choose options for your item before adding to cart'));
        }

        return needsRedirect;
    }

    addGroupedProductToCart() {
        const {
            product,
            currencyCode,
            product: { items },
            groupedProductQuantity,
            addProduct
        } = this.props;

        Promise.all(items.map((item) => {
            const { product: groupedProductItem } = item;

            const newProduct = {
                ...groupedProductItem,
                parent: product
            };

            const quantity = groupedProductQuantity[groupedProductItem.id];

            if (!quantity) {
                return Promise.resolve();
            }

            return addProduct({
                product: newProduct,
                currencyCode,
                quantity
            });
        })).then(
            /** @namespace AdmScandipwa/Component/AddToCart/Container/all/then */
            () => this.afterAddToCart(),
            /** @namespace AdmScandipwa/Component/AddToCart/Container/all/then */
            () => this.resetLoading()
        );
    }

    addConfigurableProductToCart() {
        const {
            product,
            quantity,
            currencyCode,
            addProduct,
            configurableVariantIndex,
            productOptionsData
        } = this.props;

        addProduct({
            product: {
                ...product,
                configurableVariantIndex
            },
            currencyCode,
            quantity,
            productOptionsData
        }).then(
            /** @namespace AdmScandipwa/Component/AddToCart/Container/addProduct/then */
            () => this.afterAddToCart(),
            /** @namespace AdmScandipwa/Component/AddToCart/Container/addProduct/then */
            () => this.resetLoading()
        );
    }

    addSimpleProductToCart() {
        const {
            product,
            quantity,
            addProduct,
            currencyCode,
            productOptionsData
        } = this.props;

        addProduct({
            product,
            quantity,
            currencyCode,
            productOptionsData
        }).then(
            /** @namespace AdmScandipwa/Component/AddToCart/Container/addProduct/then */
            () => this.afterAddToCart(),
            /** @namespace AdmScandipwa/Component/AddToCart/Container/addProduct/then */
            () => this.resetLoading()
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
