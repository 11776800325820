import {
    getInitialState
} from 'SourceStore/ProductList/ProductList.reducer';
import {
    APPEND_PAGE, REMOVE_FLASH_SALE_FROM_PRODUCT_LIST_ITEM,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS
} from 'Store/ProductList/ProductList.action';
import { getIndexedProducts } from 'Util/Product';
import { getPriceRangeWithDiscount } from 'Util/Product/Product';

export * from 'SourceStore/ProductList/ProductList.reducer';

/**
 * @extends to pass currentPage value into APPEND_PAGE
 * @namespace AdmScandipwa/Store/ProductList/Reducer/ProductListReducer
 */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        items: initialItems = [],
        total_pages: totalPages,
        total_count: totalItems,
        currentPage,
        isLoading,
        args: currentArgs
    } = action;

    const newState = { ...state, currentArgs: { ...state?.currentArgs, currentPage } };

    switch (type) {
    case APPEND_PAGE:
        return {
            ...newState,
            isPageLoading: false,
            pages: {
                ...state.pages,
                [currentPage]: getIndexedProducts(initialItems)
            }
        };

    case UPDATE_PRODUCT_LIST_ITEMS:
        return {
            ...state,
            currentArgs,
            isLoading: false,
            totalItems,
            totalPages,
            pages: { [currentPage]: getIndexedProducts(initialItems) }
        };

    case UPDATE_PAGE_LOAD_STATUS:
        return {
            ...state,
            isPageLoading: true
        };

    case UPDATE_LOAD_STATUS:
        return {
            ...state,
            isLoading
        };

    case REMOVE_FLASH_SALE_FROM_PRODUCT_LIST_ITEM:
        const { pages = {} } = state;
        const newPages = { ...pages };
        const { productId } = action;

        if (!Object.keys(newPages).length) {
            return state;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const pageKey of Object.keys(newPages)) {
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const index in newPages[pageKey]) {
                const product = newPages[pageKey][index];
                const { id } = product;
                const { flash_sale, ...updatedProduct } = product; // Removing the flash sale object here
                if (id === productId) {
                    if (!flash_sale) {
                        return state;
                    }
                    newPages[pageKey][index] = {
                        ...updatedProduct,
                        price_range: getPriceRangeWithDiscount(updatedProduct)
                    };
                }
            }
        }

        return {
            ...state,
            pages: newPages
        };

    default:
        return state;
    }
};

export default ProductListReducer;
