import { connect } from 'react-redux';

import NavigationTabs from 'Component/NavigationTabs/NavigationTabs.component';
import {
    ACCOUNT_TAB,
    CART_TAB,
    CHECKOUT_TAB, HOME_TAB,
    ORDERTRACKER_TAB
} from 'Component/NavigationTabs/NavigationTabs.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NavigationTabsContainer as SourceNavigationTabsContainer
} from 'SourceComponent/NavigationTabs/NavigationTabs.container';
import browserHistory from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace AdmScandipwa/Component/NavigationTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace AdmScandipwa/Component/NavigationTabs/Container/NavigationTabsContainer */
export class NavigationTabsContainer extends SourceNavigationTabsContainer {
    routeMap = {
        '/track-order': { name: ORDERTRACKER_TAB },
        '/my-account': { name: ACCOUNT_TAB },
        '/checkout': { name: CHECKOUT_TAB, isHidden: true },
        '/cart': { name: CART_TAB },
        '/': { name: HOME_TAB }
    };

    containerFunctions = {
        ...this.containerFunctions,
        onOrderTrackerButtonClick: this.onOrderTrackerButtonClick.bind(this)
    };

    onMenuButtonClick() {
        // This is done for solving cms page issues showing in menu
        browserHistory.push(appendWithStoreCode('/menu'));
        this.lastSeenMenu = 0;
    }

    onOrderTrackerButtonClick() {
        const { pathname } = location;

        if (pathname !== appendWithStoreCode('/track-order')) {
            browserHistory.push(appendWithStoreCode('/track-order'));
        }
    }

    containerProps = () => {
        const {
            isSignedIn
        } = this.props;

        return {
            isSignedIn
        };
    };

    render() {
        return (
            <NavigationTabs
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);
