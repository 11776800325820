import {
    ConfigQuery as SourceConfigQuery
} from 'SourceQuery/Config.query';
import { Field } from 'Util/Query';

/** @namespace AdmScandipwa/Query/Config/Query/ConfigQuery */
export class ConfigQuery extends SourceConfigQuery {
    __getFlashSaleConfigFields() {
        return [
            'flashsale_show_sale_block_plp',
            'flashsale_show_sale_block_pdp',
            'flashsale_show_sale_block_home_page',
            'flashsale_show_sale_block_cart',
            'flashsale_limit_each_product_to_each_customer',
            'flashsale_pwa_upcoming_deal_category',
            'flashsale_pwa_running_deal_category',
            'flashsale_active'
        ];
    }

    _getFindStoreConfigFields() {
        return [
            'findshare_general_enabled',
            'findshare_general_merchant_key'
        ];
    }

    _getRecipeFields() {
        return [
            'recipe_general_enabled'
        ];
    }

    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            ...this.__getFlashSaleConfigFields(),
            ...this._getFindStoreConfigFields(),
            ...this._getRecipeFields(),
            'all_reward_enabled',
            'minimum_order_enabled',
            'minimum_order_amount',
            'minimum_order_msg',
            'sales_intermediate_threshold',
            'sales_intermediate_message',
            'checkout_sidebar_max_items_display_count',
            'sales_intermediate_message',
            'store_code',
            'store_name',
            'is_default_store',
            'store_group_code',
            'is_default_store_group',
            'locale',
            'base_currency_code',
            'default_display_currency_code',
            'timezone',
            'weight_unit',
            'base_url',
            'base_link_url',
            'base_static_url',
            'base_media_url',
            'secure_base_url',
            'secure_base_link_url',
            'secure_base_static_url',
            'secure_base_media_url',
            'grid_per_page_values',
            'grid_per_page',
            'prerenderer_default_key',
            'grid_per_page',
            'blog_seo_meta_title',
            'blog_seo_meta_description',
            'blog_seo_meta_keywords',
            'blog_seo_meta_robots',
            'mageworx_seo_base_robots_noindex_follow_pages',
            'mageworx_seo_base_robots_noindex_follow_user_pages',
            'mageworx_seo_base_robots_noindex_nofollow_user_pages',
            'mageworx_seo_base_robots_attribute_settings',
            'mageworx_seo_base_robots_category_ln_pages_robots',
            'mageworx_seo_base_robots_count_filters_for_noindex',
            'payment_alleasy_customer_message'
        ];
    }

    getLabelInnerConfigField() {
        return new Field('configs')
            .addFieldList([
                'displayPosition',
                'displayStyle',
                'maxLabelsPdp',
                'maxLabelsListing',
                'saleLabelBasedOnSpecPrice',
                'showSaleLabelDescPerc',
                'showSaleDiscAmnt',
                'saleLabelText',
                'saleLabelPosition',
                'salesCSS'
            ]);
    }

    getLabelConfig() {
        return new Field('km_getLabels')
            .addArgument('website_code', 'String', '1')
            .addFieldList([
                this.getLabelInnerConfigField()
            ]);
    }
}

export default new ConfigQuery();
