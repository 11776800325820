import attributesToProps from 'html-react-parser/lib/attributes-to-props';

import FlashSaleHomeWidget from 'Component/FlashSaleHomeWidget';
import { FLASH_SALE_HOME_BANNER_CLASSNAME } from 'Component/FlashSaleHomeWidget/FlashSaleHomeWidget.config';
import Image from 'Component/Image';
import WidgetFactory from 'Component/WidgetFactory';
import { Html as SourceHtmlComponent } from 'SourceComponent/Html/Html.component';
import media from 'Util/Media';

/** @namespace AdmScandipwa/Component/Html/Component/HtmlComponent */
export class HtmlComponent extends SourceHtmlComponent {
    parserOptions = {
        // eslint-disable-next-line consistent-return
        replace: (domNode) => {
            const { attribs: { class: className } = {} } = domNode;
            if (className === FLASH_SALE_HOME_BANNER_CLASSNAME) {
                return this.renderFlashSaleHomeWidgetList();
            }

            const { data, name: domName, attribs: domAttrs } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                } if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;
                return replace.call(this, domNode);
            }
        }
    };

    renderFlashSaleHomeWidgetList() {
        return <FlashSaleHomeWidget />;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceWidget(elem) {
        const { attribs } = elem;

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        return <WidgetFactory { ...this.attributesToProps(attribs) } elem={ elem } />;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        // If image is from the blog, render it differently
        if (attribs.src.indexOf('{{media url="') !== -1) {
            attributes.src = media(attribs.src.replace('{{media url="', '').replace('"}}', ''));
            attributes.style = attributes.style || {};
            attributes.style.height = attributes.height ? `${attributes.height}px` : '100%';
            attributes.style.width = attributes.width ? `${attributes.width}px` : 'auto';
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction,jsx-a11y/alt-text
            return <img { ...attributes } />;
        }

        if (attribs.src) {
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            return <Image { ...attributes } />;
        }

        return null;
    }
}

export default HtmlComponent;
