/* eslint-disable max-lines */
import AddToCart from 'Component/AddToCart';
import FlashSaleTimerComponent from 'Component/FlashSaleTimer';
import { FLASH_TIMER_MODES } from 'Component/FlashSaleTimer/FlashSaleTimer.config';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import ProductPrice from 'Component/ProductPrice';
import ProductSaleLabel from 'Component/ProductSaleLabel';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import ProductCompareButton from 'SourceComponent/ProductCompareButton';
import { BUNDLE, CONFIGURABLE, productHasStock } from 'Util/Product';

/** @namespace AdmScandipwa/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends SourceProductCard {
    renderAddToCartButton() {
        const { product, product: { is_salable, id, stock_item: { min_sale_qty = 1 } = {} } } = this.props;
        if (!id) {
            return null;
        }

        return is_salable ? <AddToCart product={ product } quantity={ min_sale_qty } /> : (
            <button
              block="ProductCard"
              elem="SoldOutButton"
              mix={ { block: 'Button' } }
              mods={ { isSoldOut: !is_salable } }
            >
                { __('Sold Out') }
            </button>
        );
    }

    renderProductCardWishlistButton() {
        const {
            product, hideWishlistButton,
            product: { stock_item: { min_sale_qty = 1 } = {} }
        } = this.props;

        if (hideWishlistButton) {
            return null;
        }

        return (
            <ProductWishlistButton
              quantity={ min_sale_qty }
              product={ product }
              mix={ { block: 'ProductCard', elem: 'WishListButton' } }
            />
        );
    }

    renderProductCompareButton() {
        const {
            product: { id },
            hideCompareButton
        } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return (
            <ProductCompareButton
              productId={ id }
              product={ this.props.product }
              mix={ {
                  block: 'ProductCompareButton',
                  mods: { isGrey: true }
              } }
            />
        );
    }

    renderProductSaleLabel() {
        const { product } = this.props;
        return <ProductSaleLabel product={ product } isPLP />;
    }

    renderFlashSale() {
        const { product: { id: productId, flash_sale } } = this.props;

        return (
            <FlashSaleTimerComponent
              mods={ FLASH_TIMER_MODES.PLP }
              flashSale={ flash_sale }
              productId={ productId }
            />
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                        { this.renderProductSaleLabel() }
                        { this.renderReviews() }
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderAdditionalProductDetails() }
                        { this.renderMainDetails() }
                        { this.renderProductPrice() }
                        { this.renderFlashSale() }
                        { this.renderVisualConfigurableOptions() }
                        { this.renderAddToCartButton() }
                    </div>
                </>
            ))
        );
    }

    /**
     * Product name text case set to lowercase
     * @extends
     */

    renderMainDetails() {
        const { product: { name, product_seo_name } } = this.props;

        return this.renderCardLinkWrapper(
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ product_seo_name || name } length="medium" />
            </p>
        );
    }

    /**
     * @extends to pass @var has_options to <ProductPrice/>
     */

    renderProductPrice() {
        const {
            product,
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock,
            productCardProps
        } = this.props;

        const { price_range, type_id, has_options } = product || {};

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
        case CONFIGURABLE:
            if (isConfigurableProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        case BUNDLE:
            if (isBundleProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        default:
            break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                { this.renderTierPrice() }
                { this.renderConfigurablePriceBadge() }
                <ProductPrice
                  product={ product }
                  hasOptions={ has_options }
                  price={ price_range }
                  isPLP={ !!productCardProps }
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                />
            </div>
        );
    }

    /**
     * @override to remove <Link> if the product is out of stock
     */

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo, product, product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return productHasStock({ item: { product } }) ? (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ mix }
            >
              { children }
            </Link>
        ) : (
            <span block="ProductCard" elem="OutOfStockProduct">
            { children }
            </span>
        );
    }

    renderContent() {
        const { children, isLoading } = this.props;
        return (
            <>
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                { this.renderProductActions() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </>
        );
    }

    render() {
        const {
            isCarousel,
            mix,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            layout
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        };

        if (layout === 'list') {
            return super.render();
        }

        if (isCarousel) {
            return (
                <>
                    { this.renderContent() }
                </>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ mods }
              mix={ mix }
            >
                { this.renderContent() }
            </li>
        );
    }
}

export default ProductCardComponent;
