import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';
import { onSubscribeNewsLatterGTMEvent } from 'Util/GTM/GTM';

/** @namespace AdmScandipwa/Component/NewsletterSubscription/Container/NewsletterSubscriptionContainer */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    onFormSubmit(fields) {
        const { subscribeToNewsletter } = this.props;
        const { newsletterEmail: email } = fields;

        this.setState({ isLoading: true });

        subscribeToNewsletter(email)
            .then(
                /** @namespace AdmScandipwa/Component/NewsletterSubscription/Container/subscribeToNewsletter/then */
                () => this.onFormSubmitDone(email)
            )
            .catch(this.onFormSubmitDone);
    }

    onFormSubmitDone(email) {
        onSubscribeNewsLatterGTMEvent({ email });
        super.onFormSubmitDone();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterSubscriptionContainer);
