import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { COMING_SOON_TAB, ON_GOING_TAB } from 'Component/FlashSaleHomeWidget/FlashSaleHomeWidget.config';
import Loader from 'Component/Loader';
import ProductListWidget from 'Component/ProductListWidget';
import { flashSaleHomePageDataType } from 'Type/FlashSale';
import { ProductType } from 'Type/ProductList';

import './FlashSaleHomeWidget.style';

/** @namespace AdmScandipwa/Component/FlashSaleHomeWidget/Component/FlashSaleHomeWidgetComponent */
export class FlashSaleHomeWidgetComponent extends PureComponent {
    static propTypes = {
        flashSaleData: flashSaleHomePageDataType,
        runningFlashSaleProducts: PropTypes.arrayOf(ProductType).isRequired,
        upcomingFlashSaleProducts: PropTypes.arrayOf(ProductType).isRequired,
        productData: PropTypes.shape({
            products: { items: ProductType },
            total_count: PropTypes.number.isRequired,
            page_info: { current_page: PropTypes.number.isRequired }
        }).isRequired,
        activeTab: PropTypes.string.isRequired,
        setActiveTab: PropTypes.func.isRequired,
        runningFlashSalePath: PropTypes.string.isRequired,
        upcomingFlashSalePath: PropTypes.string.isRequired,
        isFlashSaleWidgetLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        flashSaleData: {}
    };

    renderFlashSaleTitle() {
        return (
            <h2 block="FlashSaleHomeWidget" elem="Title">
                { __('Flash Sale') }
            </h2>
        );
    }

    renderFlashSaleProducts() {
        const { productData, activeTab } = this.props;
        const totalProducts = productData.products.items.length;
        if (!totalProducts) {
            return null;
        }

        return (
            <ProductListWidget
              conditionsEncoded={ activeTab }
              productData={ productData }
              productsCount={ totalProducts }
              productsPerPage={ totalProducts }
            />
        );
    }

    renderOnGoingTab() {
        const { activeTab, setActiveTab, runningFlashSaleProducts } = this.props;
        if (!runningFlashSaleProducts.length) {
            return null;
        }

        return (
            <button
              block="FlashSaleHomeWidget"
              elem="Tab"
              mods={ { isActive: activeTab === ON_GOING_TAB } }
                // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => setActiveTab(ON_GOING_TAB) }
            >
                { __('On-going') }
            </button>
        );
    }

    renderComingSoonTab() {
        const { activeTab, setActiveTab, upcomingFlashSaleProducts } = this.props;
        if (!upcomingFlashSaleProducts.length) {
            return null;
        }

        return (
            <button
              block="FlashSaleHomeWidget"
              elem="Tab"
              mods={ { isActive: activeTab === COMING_SOON_TAB } }
                // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => setActiveTab(COMING_SOON_TAB) }
            >
                { __('Coming Soon') }
            </button>
        );
    }

    renderFLashSaleTabs() {
        return (
            <div block="FlashSaleHomeWidget" elem="TabContainer">
                { this.renderOnGoingTab() }
                { this.renderComingSoonTab() }
            </div>
        );
    }

    renderSeeAllButton() {
        const { activeTab, runningFlashSalePath, upcomingFlashSalePath } = this.props;
        const redirectionURL = activeTab === ON_GOING_TAB ? runningFlashSalePath : upcomingFlashSalePath;
        return (
            <a href={ redirectionURL } block="Button">
                { __('See All Products') }
            </a>
        );
    }

    renderProductsWithLoading() {
        const { isFlashSaleWidgetLoading } = this.props;
        if (isFlashSaleWidgetLoading) {
            return (
                <div block="FlashSaleHomeWidget" elem="LoadingContainer">
                    <Loader isLoading />
                </div>
            );
        }

        return (
            <>
                { this.renderFlashSaleProducts() }
                { this.renderSeeAllButton() }
            </>
        );
    }

    render() {
        const {
            flashSaleData, upcomingFlashSaleProducts, runningFlashSaleProducts
        } = this.props;

        if (!flashSaleData.items.length || (!runningFlashSaleProducts.length && !upcomingFlashSaleProducts.length)) {
            return null;
        }

        return (
            <div block="FlashSaleHomeWidget">
                { this.renderFlashSaleTitle() }
                { this.renderFLashSaleTabs() }
                { this.renderProductsWithLoading() }
            </div>
        );
    }
}

export default FlashSaleHomeWidgetComponent;
