import { DROPDOWN } from 'Component/ProductCustomizableOption/ProductCustomizableOption.config';
import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX,
    PRICE_TYPES
} from 'Component/ProductPrice/ProductPrice.config';
import {
    calculateFinalPrice, formatCurrency, formatPrice as sourceFormatPrice, roundPrice
} from 'SourceUtil/Price/Price';
import { getFlashSalePrice } from 'Util/FlashSale';
import { getProductTaxConfig } from 'Util/Product/Product';

export * from 'SourceUtil/Price/Price';

/** @namespace AdmScandipwa/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const exportPrice = sourceFormatPrice(price, currency);
    return exportPrice.replace(currency, formatCurrency(currency)).replace(/\s+/g, '');
};

/** @namespace AdmScandipwa/Util/Price/getPriceWithOptions */
export const getPriceWithOptions = ({ defaultPrice, priceType, productProps }) => {
    const { product } = productProps;
    if (!product) {
        return defaultPrice;
    }

    const {
        productOptionsData: { productOptions = [] } = {},
        product: { options = [] },
        price: {
            minimum_price: {
                option_prices: optionPrices = []
            } = {}
        } = {}
    } = productProps;

    const [DROPDOWN_DATA] = (options || []).filter(({ type }) => type === DROPDOWN);

    if (DROPDOWN_DATA) {
        if (productOptions.length) {
            const selectedOption = DROPDOWN_DATA.data
                .find(({ option_type_id }) => option_type_id === +productOptions[0].option_value) || {};

            const { title: selectedOptionTitle } = selectedOption;
            const selectedOptionPrice = optionPrices.find(({ text }) => text === selectedOptionTitle);

            // If option is found show the price for the option
            if (selectedOptionPrice) {
                return priceType === PRICE_TYPES.FINAL_PRICE
                    ? selectedOptionPrice.discounted_price : selectedOptionPrice.regular_price;
            }
        } else {
            // If option is not found show the price for the minimum option
            return Math.max(...optionPrices.map(
                (optionPrice) => (priceType === PRICE_TYPES.FINAL_PRICE
                    ? optionPrice.discounted_price : optionPrice.regular_price)
            ));
        }
    }

    return defaultPrice;
};

/** @namespace AdmScandipwa/Util/Price/getFinalPrice */
export const getFinalPrice = ({ productProps }) => {
    const showOptionMinValue = productProps.product?.attributes?.price_show_min?.attribute_value === '1';
    const {
        price: {
            minimum_price: {
                discount: {
                    percent_off: discountPercentage
                } = {},
                final_price: {
                    value: minimalPriceValue
                } = {},
                final_price_excl_tax: {
                    value: minimalPriceExclTaxValue
                } = {},
                regular_price: {
                    value: regularPriceValue
                } = {},
                regular_price_excl_tax: {
                    value: regularPriceExclTaxValue
                } = {}
            } = {}
        } = {},
        product
    } = productProps;

    const displayTaxInPrice = getProductTaxConfig();
    const flashSalePrice = getFlashSalePrice(product);
    if (flashSalePrice) {
        return getPriceWithOptions({
            defaultPrice: flashSalePrice, priceType: PRICE_TYPES.FINAL_PRICE, productProps, showOptionMinValue
        });
    }

    if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
        const finalPrice = calculateFinalPrice(
            discountPercentage,
            minimalPriceExclTaxValue,
            regularPriceExclTaxValue
        );

        return getPriceWithOptions({ defaultPrice: finalPrice, priceType: PRICE_TYPES.FINAL_PRICE, productProps });
    }

    const finalPrice = calculateFinalPrice(discountPercentage, minimalPriceValue, regularPriceValue);

    return getPriceWithOptions({ defaultPrice: finalPrice, priceType: PRICE_TYPES.FINAL_PRICE, productProps });
};

/** @namespace AdmScandipwa/Util/Price/getFormattedFinalPrice */
export const getFormattedFinalPrice = ({ productProps }) => {
    const {
        price: priceFromProps,
        product: {
            price_range: priceFromProduct
        } = {}
    } = productProps;
    const price = priceFromProps || priceFromProduct;
    const {
        minimum_price: {
            final_price: {
                currency: priceCurrency
            }
        }
    } = price;
    const finalPrice = getFinalPrice({ productProps });
    return formatPrice(finalPrice, priceCurrency);
};

/** @namespace AdmScandipwa/Util/Price/getRoundedRegularPrice */
export const getRoundedRegularPrice = ({ productProps }) => {
    const {
        price: {
            minimum_price: {
                regular_price: {
                    value: regularPriceValue
                } = {},
                regular_price_excl_tax: {
                    value: regularPriceExclTaxValue
                } = {}
            } = {}
        } = {}
    } = productProps;

    const displayTaxInPrice = getProductTaxConfig();

    if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
        return roundPrice(getPriceWithOptions({
            defaultPrice: regularPriceExclTaxValue,
            priceType: PRICE_TYPES.REGULAR_PRICE,
            productProps
        }));
    }

    return roundPrice(getPriceWithOptions({
        defaultPrice: regularPriceValue,
        priceType: PRICE_TYPES.REGULAR_PRICE,
        productProps
    }));
};

/** @namespace AdmScandipwa/Util/Price/getProductDiscountPercentage */
export const getProductDiscountPercentage = ({ product: inputProduct, productProps: inputProductProps }) => {
    const product = inputProduct || inputProductProps?.product || inputProductProps;
    const price_range = inputProductProps?.price || product.price_range || product.price || {};
    const { minimum_price: { discount: { percent_off: productDiscount } = {} } = {} } = price_range;

    return productDiscount || 0;
};
