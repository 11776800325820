export * from 'SourceComponent/Header/Header.config';

export const ORDER_TRACK = 'order_track';
export const CHECKOUT_PROCESSING = 'checkout_processing';
export const CHECKOUT_INVALID = 'checkout_invalid';
export const CHECKOUT_FAIL = 'checkout_invalid';
export const CREATE_PASSWORD = 'create_password';
export const STORE_PREFIX = 'AD';
export const BLOG_LIST = 'blog_list';
export const BLOG = 'blog';
export const ALL_BANK_QR_PAYMENT = 'all_bank_qr_pay';
export const SITEMAP = 'sitemap';

export const RECIPES = 'RECIPES';
export const RECIPE = 'RECIPE';
