import { InstallPromptIOS as SourceInstallPromptIOS }
    from 'SourceComponent/InstallPromptIOS/InstallPromptIOS.component';

/** @namespace AdmScandipwa/Component/InstallPromptIOS/Component/InstallPromptIOSComponent */
export class InstallPromptIOSComponent extends SourceInstallPromptIOS {
    renderCloseButton() {
        const { handleBannerClose } = this.props;

        return (
            <button
              block="InstallPromptIOS"
              elem="Close"
              onClick={ handleBannerClose }
            >
                <span>{ __('Maybe later') }</span>
            </button>
        );
    }

    renderContent() {
        return (
            <p block="InstallPromptIOS" elem="Content">
                <span>{ __('Tap') }</span>
                <span block="InstallPromptIOS" elem="Share" />
                <span>{ __(', then') }</span>
                <span block="InstallPromptIOS" elem="Plus" />
                <span>{ __('for adding to home screen') }</span>
            </p>
        );
    }

    render() {
        return (
            <div block="InstallPromptIOS">
                <div block="InstallPromptIOS" elem="Left">
                    { this.renderHeading() }
                    { this.renderContent() }
                </div>
                { this.renderCloseButton() }
            </div>
        );
    }
}

export default InstallPromptIOSComponent;
