import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import Link from 'SourceComponent/Link/Link.component';

import { PRODUCT_COMPARE_ADD_SUCCESS } from './ProductCompareAddSuccessPopup.config';

import './ProductCompareAddSuccessPopup.style.scss';

/** @namespace AdmScandipwa/Component/ProductCompareAddSuccessPopup/Component/ProductCompareAddSuccessPopupComponent */
export class ProductCompareAddSuccessPopupComponent extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        small_image: PropTypes.shape({
            url: PropTypes.string.isRequired
        }).isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    closeComparePopup = () => {
        const { hideActiveOverlay } = this.props;
        hideActiveOverlay();
    };

    renderContent() {
        const { name, small_image } = this.props;
        return (
            <div block="ProductCompareAddSuccessPopup" elem="wrapper">
                <div block="ProductCompareAddSuccessPopup" elem="productImageContainer">
                    <div block="ProductCompareAddSuccessPopup" elem="productImage">
                        <img src={ small_image?.url } alt={ name } />
                    </div>
                    <div block="ProductCompareAddSuccessPopup" elem="productNameContainer">
                        <div block="ProductCompareAddSuccessPopup" elem="productName">
                            { name?.toLowerCase() }
                        </div>
                        { ' ' }
                        { __('has been added to comparison list') }
                    </div>
                </div>
                <Link
                  block="ProductCompareAddSuccessPopup"
                  elem="viewComparisonButtonLink"
                  to="/compare"
                  onClick={ this.closeComparePopup }
                  mix={ { block: 'Button' } }
                >
                    { __('View Comparison List') }
                </Link>
            </div>
        );
    }

    render() {
        return (
            <Popup
              mix={ { block: 'ProductCompareAddSuccessPopup' } }
              id={ PRODUCT_COMPARE_ADD_SUCCESS }
              clickOutside
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default ProductCompareAddSuccessPopupComponent;
