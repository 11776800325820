import PropTypes from 'prop-types';

import { AddToCart as SourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';
import { MixType } from 'Type/Common';
import { ProductType } from 'Type/ProductList';

/** @namespace AdmScandipwa/Component/AddToCart/Component/AddToCartComponent */
export class AddToCartComponent extends SourceAddToCart {
    static propTypes = {
        isLoading: PropTypes.bool,
        product: ProductType,
        mix: MixType,
        buttonClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        product: {},
        mix: {},
        isLoading: false
    };

    renderPlaceholder() {
        const { isLoading, mix } = this.props;

        return (
            <div
              block="AddToCart"
              mods={ { isLoading, isPlaceholder: true } }
              mix={ mix }
            />
        );
    }

    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        return (
            <button
              onClick={ buttonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { isLoading } }
              disabled={ isLoading }
            >
                <span>{ __('Add to Cart') }</span>
                <span>{ __('Adding...') }</span>
            </button>
        );
    }
}

export default AddToCartComponent;
