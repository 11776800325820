import { GET_RECIPE_DETAIL_LOADING, UPDATE_RECIPE_DETAILS } from 'Store/Recipe/Recipe.action';

/** @namespace AdmScandipwa/Store/Recipe/Reducer/getInitialState */
export const getInitialState = () => ({
    recipeDetails: {},
    isGetRecipeLoading: true
});

/** @namespace AdmScandipwa/Store/Recipe/Reducer/RecipeReducer */
export const RecipeReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_RECIPE_DETAILS:
        const { recipeDetails } = action;
        return { ...state, recipeDetails };
    case GET_RECIPE_DETAIL_LOADING:
        const { isGetRecipeLoading } = action;
        return { ...state, isGetRecipeLoading };
    default:
        return state;
    }
};

export default RecipeReducer;
