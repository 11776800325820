import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from './Notification.action';

/** @namespace AdmScandipwa/Store/Notification/Reducer/getInitialState */
export const getInitialState = () => ({
    notifications: {}
});
export const messageState = { isMessageDisplayedOnce: false, isNotificationVisible: false };
export const TIMEOUT = 2500;

export const AUTHORIZATION_ERROR = 'The current customer isn\'t authorized.';
export const CART_ERROR = 'No such entity with cartId';
export const CART_EXPIRY_MESSAGE = 'Your shopping cart is expired';
export const INVALID_CART_MESSAGE = 'Could not find a cart with ID';

/** @namespace AdmScandipwa/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (
    state = getInitialState(),
    action
) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
    case SHOW_NOTIFICATION:
        const { msgType, msgText: oldMsgText, msgDebug } = action;
        const msgText = oldMsgText.indexOf(CART_ERROR) > -1
        || oldMsgText.indexOf(INVALID_CART_MESSAGE) > -1 ? CART_EXPIRY_MESSAGE : oldMsgText;

        notifications[Date.now()] = { msgType, msgText, msgDebug };

        // Do not show the duplicate message
        if (msgText.indexOf(AUTHORIZATION_ERROR) > -1
            || msgText.indexOf(CART_EXPIRY_MESSAGE) > -1) {
            if (!messageState.isMessageDisplayedOnce) {
                messageState.isMessageDisplayedOnce = true;
                setTimeout(() => {
                    messageState.isMessageDisplayedOnce = false;
                    messageState.isNotificationVisible = false;
                }, TIMEOUT);
            }
        }
        if (!messageState.isNotificationVisible && messageState.isMessageDisplayedOnce) {
            messageState.isNotificationVisible = true;
            return {
                ...state,
                notifications
            };
        }
        if (messageState.isMessageDisplayedOnce) {
            return {
                ...state
            };
        }

        return {
            ...state,
            notifications
        };

    case HIDE_NOTIFICATION:
        // eslint-disable-next-line no-unused-vars
        const { [action.id]: id, ...shownNotifications } = notifications;

        return {
            ...state,
            notifications: shownNotifications
        };

    default:
        return state;
    }
};

export default NotificationReducer;
