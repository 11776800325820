import MyAccountQuery from 'Query/MyAccount.query';
import {
    CartDispatcher, CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher, ONE_MONTH_IN_SECONDS,
    ProductCompareDispatcher,
    WishlistDispatcher
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    updateCustomerDetails,
    updateCustomerIsAuthTokenExpired,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import {
    deleteAuthorizationToken,
    setAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import {
    getGuestQuoteId
} from 'Util/Cart';
import { onLoginUserGTMEvent, onLogoutGTMEvent, onRegisterUserGTMEvent } from 'Util/GTM/GTM';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation } from 'Util/Request';

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

export const UpdateOnlineCustomerDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/updateOnlineCustomer/updateOnlineCustomer.dispatcher'
);

/**
 * @override to trigger UpdateOnlineCustomerDispatcher on singIn
 * @namespace AdmScandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher
 */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    /**
     * @extends to return error from the response
     */

    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace AdmScandipwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            ({ s_resetPassword: { status } }) => dispatch(updateCustomerPasswordResetStatus(status)),
            /** @namespace AdmScandipwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (errors) => {
                const [{ message }] = errors;
                dispatch(updateCustomerPasswordResetStatus(message || 'error'));
            }
        );
    }

    async requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();
        const customerIdQuery = MyAccountQuery.getCustomerIdQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        try {
            const { customer } = await executePost(prepareQuery([query]));
            const { km_fetchCustomer: { customer_id } } = await executePost(prepareQuery([customerIdQuery]));
            customer.customer_id = customer_id;
            dispatch(updateCustomerDetails(customer));
            BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
            return customer;
        } catch (error) {
            dispatch(showNotification('error', error[0].message));
            return { };
        }
    }

    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);
        dispatch(updateCustomerSignInStatus(true));
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
        );
        UpdateOnlineCustomerDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateOnlineCustomer({
                customer_token: token,
                cart_id: getGuestQuoteId()
            })
        );

        const customerData = await this.requestCustomerData(dispatch);
        onLoginUserGTMEvent(customerData);
        dispatch(updateIsLoading(false));

        return true;
    }

    logout(authTokenExpired = false, dispatch) {
        onLogoutGTMEvent(BrowserDatabase.getItem(CUSTOMER));

        if (authTokenExpired) {
            dispatch(updateCustomerIsAuthTokenExpired(true));
        }
        deleteAuthorizationToken();
        dispatch(updateCustomerSignInStatus(false));
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.createGuestEmptyCart(dispatch);
                dispatcher.updateInitialCartData(dispatch);
            }
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
        );
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        dispatch(updateCustomerDetails({}));
    }

    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));
        return fetchMutation(mutation).then(
            /** @namespace AdmScandipwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                onRegisterUserGTMEvent(options);

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));
                    return 2;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace AdmScandipwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(showNotification('error', error[0].message));
                Promise.reject();
                dispatch(updateIsLoading(false));

                return false;
            }
        );
    }

    deleteAccount(dispatch) {
        const mutation = MyAccountQuery.getDeleteAccountMutation();
        return new Promise((resolve, reject) => {
            fetchMutation(mutation)
                .then(
                    /** @namespace AdmScandipwa/Store/MyAccount/Dispatcher/fetchMutation/then */
                    (data) => {
                        const { deleteCustomer } = data;
                        if (!deleteCustomer) {
                            dispatch(showNotification('error', 'Error in deleting user.'));
                            reject();
                        } else {
                            resolve();
                            this.logout(false, dispatch);
                        }
                    },
                    /** @namespace AdmScandipwa/Store/MyAccount/Dispatcher/fetchMutation/then */
                    (error) => {
                        dispatch(showNotification('error', error[0].message));
                        reject();
                    }
                );
        });
    }
}

export default new MyAccountDispatcher();
