/** @namespace AdmScandipwa/Util/GTM/FindShare/sendFindShareEvent */
import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';
import BrowserDatabase from 'Util/BrowserDatabase';

export const FIND_SHARE_EVENT_TYPES = {
    OPEN_PRODUCT_PAGE: 'OPEN_PRODUCT_PAGE',
    ORDER_SUCCESS: 'ORDER_SUCCESS'
};

/** @namespace AdmScandipwa/Util/GTM/FindShare/_getFindShareConfig */
export const _getFindShareConfig = () => {
    const {
        storeConfig: { findshare_general_enabled, findshare_general_merchant_key }
    } = BrowserDatabase.getItem(STORE_CONFIG_KEY) || { storeConfig: { findshare_general_enabled: false } };

    return { findshare_general_enabled: findshare_general_enabled === '1', findshare_general_merchant_key };
};

/** @namespace AdmScandipwa/Util/GTM/FindShare/sendOpenProductPageEvent */
export const sendOpenProductPageEvent = (productId) => {
    const { findshare_general_enabled, findshare_general_merchant_key } = _getFindShareConfig();
    if (window.addVisit && findshare_general_enabled) {
        window.addVisit(findshare_general_merchant_key, { productId });
    }
};

/** @namespace AdmScandipwa/Util/GTM/FindShare/getOrderDataCod */
export const getOrderDataCod = ({ orderDetails, orderId }, sku, price) => {
    const { quote_currency_code = '' } = orderDetails || {};
    // If the order is created with the order method like cash on delivery then need to get the order data from the order detail object
    return {
        productIds: sku,
        transactionId: String(orderId),
        totalOrder: String(price),
        currency: quote_currency_code
    };
};
    // If the order is created with the order method like credit card then need to get the order data from the gtm object
/** @namespace AdmScandipwa/Util/GTM/FindShare/getOrderDataPayment */
export const getOrderDataPayment = (currency, transactionId, sku, price) => ({
    productIds: sku,
    transactionId: String(transactionId),
    totalOrder: String(price),
    currency
});
/** @namespace AdmScandipwa/Util/GTM/FindShare/sendOrderSuccessEvent */
export const sendOrderSuccessEvent = (eventData) => {
    const { gtmConversionFromBEJson } = eventData;
    const { findshare_general_enabled, findshare_general_merchant_key } = _getFindShareConfig();
    if (window.addOrder && findshare_general_enabled) {
        if (gtmConversionFromBEJson) {
            const {
                ecommerce: {
                    currencyCode: currency,
                    purchase: { actionField: { order_id: transactionId } = {}, products = [] } = {}
                } = {}
            } = gtmConversionFromBEJson;

            products.forEach(
                ({
                    sku,
                    price
                }) => window.addOrder(findshare_general_merchant_key, getOrderDataPayment(
                    currency,
                    transactionId,
                    sku,
                    price
                ))
            );
        }
        eventData.orderDetails.items.forEach(
            ({ sku, price }) => window.addOrder(findshare_general_merchant_key, getOrderDataCod(eventData, sku, price))
        );
    }
};

/** @namespace AdmScandipwa/Util/GTM/FindShare/sendFindShareEvent */
export const sendFindShareEvent = ({ event, eventData }) => {
    switch (event) {
    case FIND_SHARE_EVENT_TYPES.OPEN_PRODUCT_PAGE:
        sendOpenProductPageEvent(eventData.sku);
        break;
    case FIND_SHARE_EVENT_TYPES.ORDER_SUCCESS:
        sendOrderSuccessEvent(eventData);
        break;
    default:
            // Do nothing;
    }
};
