/** @namespace AdmScandipwa/Util/Text/toCapitalize */
export const toCapitalize = (text) => (typeof text === 'string' ? text
    .toLowerCase().replace(/\b[a-z]/g, (match) => match.toUpperCase()) : text);

/** @namespace AdmScandipwa/Util/Text/stripTags */
export const stripTags = (text) => (typeof text === 'string' ? text.replace(/<[^>]*>?/gm, '') : text);

/** @namespace AdmScandipwa/Util/Text/ucFirst */
export const ucFirst = (str) => (typeof str === 'string'
    ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '');
