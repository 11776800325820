import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace AdmScandipwa/Component/FieldFile/Component/FieldFileComponent */
export class FieldFileComponent extends PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        formRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    };

    static defaultProps = {
        formRef: () => {},
        value: ''
    };

    render() {
        const {
            formRef,
            // eslint-disable-next-line no-unused-vars
            value,
            ...validProps
        } = this.props;

        return (
            <input
              ref={ formRef }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...validProps }
            />
        );
    }
}

export default FieldFileComponent;
