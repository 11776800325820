import { getInitialState, SearchBarReducer as sourceSearchBarReducer } from 'SourceStore/SearchBar/SearchBar.reducer';
import { UPDATE_SEARCH_BAR } from 'Store/SearchBar/SearchBar.action';

/** @namespace AdmScandipwa/Store/SearchBar/Reducer/processSuggessions */
export const processSuggessions = (suggessions) => suggessions.map(
    (suggession) => ({ ...suggession, name: suggession.title, product: { name: suggession.title } })
);

/** @namespace AdmScandipwa/Store/SearchBar/Reducer/SearchBarReducer */
export const SearchBarReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_SEARCH_BAR:
        if (action.result && action.result.suggest) {
            return {
                ...state,
                productsInSearch: processSuggessions(action.result.suggest)
            };
        }

        return {
            ...state,
            productsInSearch: []
        };

    default:
        return sourceSearchBarReducer(state, action);
    }
};

export default SearchBarReducer;
