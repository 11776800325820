import Carousel from 'react-multi-carousel';
import { withRouter } from 'react-router-dom';

import CarouselButtonGroup from 'Component/CarouselButtonGroup';
import ProductCard from 'Component/ProductCard';
import { CAROUSEL_BREAKPOINTS } from 'Component/ProductListPage/ProductListPage.config';
import { ProductListPage as SourceProductListPage } from 'SourceComponent/ProductListPage/ProductListPage.component';

import 'react-multi-carousel/lib/styles.css';

/** @namespace AdmScandipwa/Component/ProductListPage/Component/ProductListPageComponent */
export class ProductListPageComponent extends SourceProductListPage {
    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = 'grid'
                } = {}
            }
        } = this.props;

        const isCarousel = this.isCarousal();

        return items.map((product, i) => (
            <ProductCard
              product={ product }
                // eslint-disable-next-line react/no-array-index-key
              key={ i }
              selectedFilters={ selectedFilters }
              layout={ layout }
              isCarousel={ isCarousel }
              { ...this.containerProps() }
            />
        ));
    }

    renderWithCarousel() {
        const {
            pageNumber,
            wrapperRef,
            isMobile
        } = this.props;

        return (
            <div block="ProductListPage" elem="Carousel" mix={ { block: 'ProductListWidget', elem: 'Carousel' } }>
                <Carousel
                  responsive={ CAROUSEL_BREAKPOINTS }
                  infinite
                  arrows={ false }
                  partialVisible
                  showDots={ !isMobile }
                  key={ pageNumber }
                  ref={ wrapperRef }
                  customButtonGroup={ <CarouselButtonGroup block="ProductListPage" /> }
                  renderButtonGroupOutside
                  autoPlay={ !isMobile }
                  shouldResetAutoplay={ false }
                  itemClass="ProductCard"
                >
                    { this.renderItems() }
                </Carousel>
            </div>
        );
    }

    // Here there is no container file so, add the logical function here.
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    isCarousal() {
        const { isCarousel } = this.props;

        return isCarousel;
    }

    render() {
        const {
            items,
            isLoading
        } = this.props;

        if (!this.isCarousal() || !items.length || isLoading) {
            return super.render();
        }

        return this.renderWithCarousel();
    }
}

export default withRouter(ProductListPageComponent);
