/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    CHECKOUT_URL,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY
} from 'SourceRoute/Checkout/Checkout.config';

export const SHIPPING_STEP = 'isShipingStep';
export const BILLING_STEP = 'isBillingStep';
export const DETAILS_STEP = 'isSuccessStep';
export const CALLBACK_STEP = 'isCallbackStep';

export const LAST_REDIRECT_ORDER = 'lastRedirectedOrderId';
export const ORDER_TYPE_PLACED = 'orderPlaced';
export const ORDER_TYPE_PENDING = 'orderPending';

export const ALL_BANK_QR_DATA = 'all_bank_qr_data';

export {
    CHECKOUT_URL,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY
};

export const OOS_TRIGGER_TEXT = 'is out of stock';

export const GUEST_QUOTE_id = 'guest_quote_id';
