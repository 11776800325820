import { connect } from 'react-redux';

import ProductAttributeValue from './ProductAttributeValue.component';

/** @namespace AdmScandipwa/Component/ProductAttributeValue/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    weightUnit: state.ConfigReducer.weight_unit
});

/** @namespace AdmScandipwa/Component/ProductAttributeValue/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributeValue);
