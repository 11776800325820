/* eslint-disable no-unused-vars */
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import FieldFile from './FieldFile.component';

/** @namespace AdmScandipwa/Component/FieldFile/Container/FieldFileContainer */
export class FieldFileContainer extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ])
    };

    static defaultProps = {
        value: '',
        isDisabled: false
    };

    containerProps = () => {
        const {
            /* eslint-disable react/prop-types */
            dispatch,
            selectOptions,
            isControlled,
            handleChange,
            onChangeCheckbox,
            onKeyEnterDown,
            formRefMap,
            validationStatus,
            /* eslint-enable react/prop-types */

            // Props to be transformed
            isDisabled: disabled,

            // Props that are passed correctly from the beginning
            ...validProps
        } = this.props;

        return {
            ...validProps,
            disabled
        };
    };

    render() {
        return (
            <FieldFile { ...this.containerProps() } />
        );
    }
}

export default FieldFileContainer;
