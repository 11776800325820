import { TOGGLE_BREADCRUMBS, TOGGLE_HOME_BREADCRUMB, UPDATE_BREADCRUMBS } from './Breadcrumbs.action';

/** @namespace AdmScandipwa/Store/Breadcrumbs/Reducer/getInitialState */
export const getInitialState = () => ({
    breadcrumbs: [],
    areBreadcrumbsVisible: true,
    isHomeVisible: true
});

/**
 *
 * @override to add @var TOGGLE_HOME_BREADCRUMB
 * @namespace AdmScandipwa/Store/Breadcrumbs/Reducer/BreadcrumbsReducer
 */
export const BreadcrumbsReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_BREADCRUMBS:
        const { breadcrumbs } = action;

        return {
            ...state,
            breadcrumbs
        };

    case TOGGLE_BREADCRUMBS:
        const { areBreadcrumbsVisible } = action;

        return {
            ...state,
            areBreadcrumbsVisible
        };

    case TOGGLE_HOME_BREADCRUMB:
        const { isHomeVisible } = action;

        return {
            ...state,
            isHomeVisible
        };

    default:
        return state;
    }
};

export default BreadcrumbsReducer;
