import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

/** @namespace AdmScandipwa/Component/JsonLd/Component/JsonLdComponent */
export class JsonLdComponent extends PureComponent {
    static propTypes = {
        jsonLdItems: PropTypes.string
    };

    static defaultProps = {
        jsonLdItems: []
    };

    renderItem(item) {
        try {
            const strippedTags = item.replace(/(<([^>]+)>)/gi, '');
            return createPortal(
                <script type="application/ld+json">{ strippedTags }</script>, document.head
            );
        } catch (error) {
            return false;
        }
    }

    render() {
        const { jsonLdItems = [] } = this.props;
        return jsonLdItems ? jsonLdItems.map(this.renderItem) : false;
    }
}

export default JsonLdComponent;
