export const SELECT_LOCATION_POPUP_ID = 'SelectLocationPopup';

export const COUNTRY_ID = 'PH';

export const FORM_IDS = {
    REGION: 'SelectionLocationRegion',
    CITY: 'SelectionLocationCity',
    DISTRICT: 'SelectionLocationDistrict'
};

export const LOCATION_DATA = 'locationData';
export const CHANGED_LOCATION = 'changedLocation';

/**
 * number of milliseconds to wait once the page is loaded
 * This delay is implemented to make sure location popup will not be closed by any other initial popups
 */
export const ON_LOAD_DELAY = 1500;
