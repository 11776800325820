import { CmsBlockContainer as SourceCmsBlock } from 'SourceComponent/CmsBlock/CmsBlock.container';

/** @namespace AdmScandipwa/Component/CmsBlock/Container/CmsBlockContainer */
export class CmsBlockContainer extends SourceCmsBlock {
    /**
     * Overridden to return Children to the component
     * @override
     */

    containerProps = () => {
        const { cmsBlock } = this.state;
        const { children } = this.props;
        return { cmsBlock, children };
    };
}

export default CmsBlockContainer;
