import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.container';

import Breadcrumbs from './Breadcrumbs.component';

/** @namespace AdmScandipwa/Component/Breadcrumbs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isHomeBreadcrumbVisible: state.BreadcrumbsReducer.isHomeVisible,
    ...sourceMapStateToProps(state)
});

/** @namespace AdmScandipwa/Component/Breadcrumbs/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
