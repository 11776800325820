import { ProductWishlistButton as SourceProductWishlistButtonComponent }
    from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

/** @namespace AdmScandipwa/Component/ProductWishlistButton/Component/ProductWishlistButtonComponent */
export class ProductWishlistButtonComponent extends SourceProductWishlistButtonComponent {
    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mods: { isHollow: !isInWishlist }, mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <div
                  block="ProductWishlistButton"
                  elem="Heart"
                />
                <span>Wishlist</span>
            </button>
        );
    }
}

export default ProductWishlistButtonComponent;
