import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import media, { PRODUCT_MEDIA } from 'Util/Media';

import { IN_STOCK } from './ProductCard.config';

/** @namespace AdmScandipwa/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    _getThumbnail() {
        const product = this._getProductOrVariant();
        const { small_image: { url, path } = {} } = product;
        if (this._isThumbnailAvailable(url)) {
            return path ? media(path, PRODUCT_MEDIA) : url;
        }

        // If thumbnail is, missing we try to get image from parent
        const { product: { small_image: { url: parentUrl, path: parentPath } = {} } } = this.props;
        if (this._isThumbnailAvailable(parentUrl)) {
            return parentPath ? media(parentPath, PRODUCT_MEDIA) : parentUrl;
        }

        return '';
    }

    isConfigurableProductOutOfStock() {
        const { product: { stock_status } } = this.props;
        return stock_status !== IN_STOCK;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
