/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    CART_REFRESH_FOR_FLASH_SALE_WAIT_TIME,
    FLASH_SALE_PROGRESS_COLORS, FLASH_SALE_STATUSES,
    FLASH_TIMER_MODES,
    FLASH_UPDATE_TIME_INTERVAL,
    PERCENTAGE_33, PERCENTAGE_66
} from 'Component/FlashSaleTimer/FlashSaleTimer.config';
import { removeProductFlashSaleFromCartItem } from 'Store/Cart/Cart.action';
import { setFlashSaleWidgetLoadingState } from 'Store/FlashSale/FlashSale.action';
import { removeProductFlashSale } from 'Store/Product/Product.action';
import { removeFlashSaleFromProductListItem } from 'Store/ProductList/ProductList.action';
import { flashSaleType } from 'Type/FlashSale';
import { debounce } from 'Util/Debounce';
import { getFlashSaleTimeDiffWithCurrentTime } from 'Util/FlashSale';

import FlashSaleTimer from './FlashSaleTimer.component';

export const FlashSaleProductsDispatcher = import(
    'Store/FlashSale/FlashSale.dispatcher'
);

export const CartDispatcher = import(
    'Store/Cart/Cart.dispatcher.extended'
);

/** @namespace AdmScandipwa/Component/FlashSaleTimer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    timeZone: state.ConfigReducer.timezone,
    isFlashSaleBlockVisibleOnPLP: state.ConfigReducer.flashsale_show_sale_block_plp,
    isFlashSaleBlockVisibleOnPDP: state.ConfigReducer.flashsale_show_sale_block_pdp,
    isFlashSaleBlockVisibleOnCart: state.ConfigReducer.flashsale_show_sale_block_cart,
    isFlashSaleBlockVisibleOnHomePage: state.ConfigReducer.flashsale_show_sale_block_home_page
});

/** @namespace AdmScandipwa/Component/FlashSaleTimer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    removeProductFlashSale: (product) => dispatch(removeProductFlashSale(product)),
    removeFlashSaleFromProductListItem: (productId) => dispatch(removeFlashSaleFromProductListItem(productId)),
    updateInitialCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    removeFlashSaleFromCartItem: (productId) => dispatch(removeProductFlashSaleFromCartItem(productId)),
    getFlashSaleHomePageData: () => FlashSaleProductsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getFlashSaleHomePageData(dispatch)
    ),
    setFlashSaleWidgetLoadingState: (loadingState) => dispatch(setFlashSaleWidgetLoadingState(loadingState))
});

/** @namespace AdmScandipwa/Component/FlashSaleTimer/Container/FlashSaleTimerContainer */
export class FlashSaleTimerContainer extends PureComponent {
    static propTypes = {
        flashSale: flashSaleType,
        mods: PropTypes.string.isRequired,
        productId: PropTypes.number.isRequired,
        removeProductFlashSale: PropTypes.func.isRequired,
        removeFlashSaleFromProductListItem: PropTypes.func.isRequired,
        removeFlashSaleFromCartItem: PropTypes.func.isRequired,
        updateInitialCartData: PropTypes.func.isRequired,
        getFlashSaleHomePageData: PropTypes.func.isRequired,
        setFlashSaleWidgetLoadingState: PropTypes.func.isRequired,
        isFlashSaleBlockVisibleOnPLP: PropTypes.bool.isRequired,
        isFlashSaleBlockVisibleOnPDP: PropTypes.bool.isRequired,
        isFlashSaleBlockVisibleOnCart: PropTypes.bool.isRequired,
        isFlashSaleBlockVisibleOnHomePage: PropTypes.bool.isRequired,
        timeZone: PropTypes.string.isRequired
    };

    static defaultProps = {
        flashSale: {}
    };

    state = {
        flashSaleRemainingTime: {
            seconds: 0,
            minutes: 0,
            days: 0,
            hours: 0,
            totalSecondDiff: 0
        }
    };

    containerFunctions = {
        getClassBasedOnPercentage: this.getClassBasedOnPercentage.bind(this),
        isValidFlashSale: this.isValidFlashSale.bind(this)
    };

    componentDidMount() {
        const { updateInitialCartData } = this.props;
        this.updateCartDebounced = debounce(updateInitialCartData, CART_REFRESH_FOR_FLASH_SALE_WAIT_TIME);
        this.calcTimeDiff();
        this.setTimer();
    }

    componentDidUpdate(prevProps) {
        const { flashSale } = this.props;
        const { flashSale: prevFlashSale } = prevProps;

        if (!this.isValidFlashSale()) {
            return;
        }

        if (JSON.stringify(flashSale) !== JSON.stringify(prevFlashSale)) {
            this.clearTimer();
            this.calcTimeDiff();
            this.setTimer();
        } else {
            this.setTimer();
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    clearTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    isValidFlashSale() {
        const { flashSale, mods, timeZone } = this.props;
        if (flashSale.deal_status !== FLASH_SALE_STATUSES.RUNNING
            && flashSale.deal_status !== FLASH_SALE_STATUSES.UPCOMING) {
            return false;
        }

        if (flashSale.deal_status === FLASH_SALE_STATUSES.UPCOMING
            && mods !== FLASH_TIMER_MODES.PLP) {
            return false;
        }

        const { totalSecondDiff } = getFlashSaleTimeDiffWithCurrentTime({ flashSale, timeZone });

        return totalSecondDiff;
    }

    setTimer() {
        const {
            removeProductFlashSale,
            removeFlashSaleFromProductListItem,
            productId,
            removeFlashSaleFromCartItem,
            getFlashSaleHomePageData,
            setFlashSaleWidgetLoadingState,
            mods,
            flashSale: { isHomePageWidget },
            flashSale,
            timeZone
        } = this.props;

        if (!this.isValidFlashSale()) {
            return;
        }

        const { totalSecondDiff } = getFlashSaleTimeDiffWithCurrentTime({ flashSale, timeZone });

        if (totalSecondDiff > 1) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                this.calcTimeDiff();
            }, FLASH_UPDATE_TIME_INTERVAL);
        } else if (totalSecondDiff < 1) {
            switch (mods) {
            case FLASH_TIMER_MODES.PDP:
                removeProductFlashSale();
                break;
            case FLASH_TIMER_MODES.PLP:
                if (isHomePageWidget) {
                    setFlashSaleWidgetLoadingState(true);
                    getFlashSaleHomePageData();
                } else if (productId) {
                    removeFlashSaleFromProductListItem(productId);
                }
                break;
            case FLASH_TIMER_MODES.CART_PAGE:
            case FLASH_TIMER_MODES.CHECKOUT_CART_ITEM:
                removeFlashSaleFromCartItem(productId);
                this.updateCartDebounced();
                break;
            default:
                break;
            }
        }
    }

    getClassBasedOnPercentage(percentageLeft) {
        if (percentageLeft < PERCENTAGE_33) {
            return FLASH_SALE_PROGRESS_COLORS.RED;
        } if (percentageLeft > PERCENTAGE_33 && percentageLeft < PERCENTAGE_66) {
            return FLASH_SALE_PROGRESS_COLORS.ORANGE;
        }

        return FLASH_SALE_PROGRESS_COLORS.GREEN;
    }

    calcTimeDiff() {
        const { flashSale, timeZone } = this.props;
        const flashSaleRemainingTime = getFlashSaleTimeDiffWithCurrentTime({ flashSale, timeZone });
        this.setState({ flashSaleRemainingTime });
    }

    render() {
        const {
            mods,
            isFlashSaleBlockVisibleOnPLP,
            isFlashSaleBlockVisibleOnPDP,
            isFlashSaleBlockVisibleOnCart,
            isFlashSaleBlockVisibleOnHomePage,
            flashSale: { isHomePageWidget }
        } = this.props;

        if (mods === FLASH_TIMER_MODES.PDP && !isFlashSaleBlockVisibleOnPDP) {
            return null;
        }

        if (mods === FLASH_TIMER_MODES.PLP && !isFlashSaleBlockVisibleOnPLP) {
            return null;
        }

        if ((mods === FLASH_TIMER_MODES.CART_PAGE || mods === FLASH_TIMER_MODES.CHECKOUT_CART_ITEM)
            && !isFlashSaleBlockVisibleOnCart) {
            return null;
        }

        if (isHomePageWidget && !isFlashSaleBlockVisibleOnHomePage) {
            return null;
        }

        return (
            <FlashSaleTimer
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.containerFunctions }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashSaleTimerContainer);
