import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    FLASH_SALE_STATUSES,
    TIME_UNIT_DISPLAY_DATA
} from 'Component/FlashSaleTimer/FlashSaleTimer.config';
import { flashSaleRemainingTimeType, flashSaleType } from 'Type/FlashSale';

import './FlashSaleTimer.style';

/** @namespace AdmScandipwa/Component/FlashSaleTimer/Component/FlashSaleTimerComponent */
export class FlashSaleTimerComponent extends PureComponent {
    static propTypes = {
        flashSaleRemainingTime: flashSaleRemainingTimeType,
        mods: PropTypes.string.isRequired,
        flashSale: flashSaleType,
        isValidFlashSale: PropTypes.func.isRequired
    };

    static defaultProps = {
        flashSale: {},
        flashSaleRemainingTime: {}
    };

    renderFlashSaleIcon() {
        return (
            <div block="FlashSaleTimer" elem="Icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24.02 26.322">
                    <g id="Clock_Icon" data-name="Clock Icon" transform="translate(-150 -1454)">
                        { /* eslint-disable-next-line max-len */ }
                        <path id="Path_195" data-name="Path 195" d="M12.01,0A12.01,12.01,0,1,1,0,12.01,12.01,12.01,0,0,1,12.01,0Z" transform="translate(150 1456.302)" fill="#e92020" opacity="0.21" />
                        { /* eslint-disable-next-line max-len */ }
                        <path id="Path_196" data-name="Path 196" d="M7516.7,316.7v10.949l8.28,8.382,2.085-2.567,1.4-5.013-.8-5.013-2.968-4.211-4.451-2.527Z" transform="translate(-7355.05 1140.443)" fill="#f2f2f2" />
                        <g id="Group_2528" data-name="Group 2528" transform="translate(150 1454.5)">
                            { /* eslint-disable-next-line max-len */ }
                            <g id="Ellipse_22" data-name="Ellipse 22" transform="translate(0.01 1.661)" fill="none" stroke="#e92020" strokeWidth="1">
                                <circle cx="12" cy="12" r="12" stroke="none" />
                                <circle cx="12" cy="12" r="11.5" fill="none" />
                            </g>
                            { /* eslint-disable-next-line max-len */ }
                            <path id="Path_194" data-name="Path 194" d="M7512.561,318v8.021l4.915,4.915" transform="translate(-7500.91 -312.368)" fill="none" stroke="#e92020" strokeWidth="1" />
                            { /* eslint-disable-next-line max-len */ }
                            <line id="Line_174" data-name="Line 174" x2="6.005" transform="translate(9.008)" fill="none" stroke="#e92020" strokeWidth="1" />
                        </g>
                    </g>
                </svg>
            </div>
        );
    }

    renderFlashSaleTitle() {
        const { flashSale: { deal_status } } = this.props;
        const startEndText = deal_status === FLASH_SALE_STATUSES.RUNNING ? __('will end in') : __('starts in');
        return (
            <div block="FlashSaleTimer" elem="Title">
                <span>{ __('Flash Sale ') }</span>
                <span>{ startEndText }</span>
            </div>
        );
    }

    renderFlashSaleTimeUnits() {
        const { flashSaleRemainingTime } = this.props;
        return (
            <div block="FlashSaleTimer" elem="Units">
                { TIME_UNIT_DISPLAY_DATA.map(({ type, title, showColon }) => (
                    this.renderTimeUnit({ leftUnit: flashSaleRemainingTime[type], unitText: title, showColon })
                )) }
            </div>
        );
    }

    renderTimeUnit({ leftUnit, unitText, showColon }) {
        return (
            <div block="FlashSaleTimer" elem="Unit">
                <div>
                    <span>{ leftUnit }</span>
                    { unitText }
                </div>
                <p>{ showColon && ':' }</p>
            </div>
        );
    }

    render() {
        const { mods, isValidFlashSale } = this.props;
        if (!isValidFlashSale()) {
            return null;
        }

        return (
            <div block="FlashSaleTimer" mods={ { [mods]: true } }>
                <div block="FlashSaleTimer" elem="Inner">
                    { this.renderFlashSaleIcon() }
                    { this.renderFlashSaleTitle() }
                    { this.renderFlashSaleTimeUnits() }
                </div>
            </div>
        );
    }
}

export default FlashSaleTimerComponent;
