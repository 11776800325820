export const UPDATE_BLOG_DETAILS = 'UPDATE_BLOG_DETAILS';
export const UPDATE_BLOG_CATEGORY_LIST = 'UPDATE_BLOG_CATEGORY_LIST';
export const UPDATE_RELATED_BLOG_STATE = 'UPDATE_RELATED_BLOG_STATE';
export const CLEAR_CURRENT_BLOG = 'CLEAR_CURRENT_BLOG';

/** @namespace AdmScandipwa/Store/Blog/Action/clearCurrentBlog */
export const clearCurrentBlog = () => ({ type: CLEAR_CURRENT_BLOG });

/** @namespace AdmScandipwa/Store/Blog/Action/updateBlogDetails */
export const updateBlogDetails = (blog) => ({
    type: UPDATE_BLOG_DETAILS,
    blog
});
/** @namespace AdmScandipwa/Store/Blog/Action/setRelatedBlogState */
export const setRelatedBlogState = (newState) => ({
    type: UPDATE_RELATED_BLOG_STATE,
    newState
});
/** @namespace AdmScandipwa/Store/Blog/Action/updateBlogCategoryList */
export const updateBlogCategoryList = (categoryList) => ({
    type: UPDATE_BLOG_CATEGORY_LIST,
    categoryList
});
