import { SET_FLASH_SALE_WIDGET_DATA, SET_FLASH_SALE_WIDGET_LOADING_STATE } from './FlashSale.action';

/** @namespace AdmScandipwa/Store/FlashSale/Reducer/getInitialState */
export const getInitialState = () => ({
    flashSaleWidgetData: { items: [] },
    isFlashSaleWidgetLoading: false
});

/** @namespace AdmScandipwa/Store/FlashSale/Reducer/FlashSaleReducer */
export const FlashSaleReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_FLASH_SALE_WIDGET_DATA:
        const { flashSaleWidgetData } = action;
        return {
            ...state,
            flashSaleWidgetData
        };

    case SET_FLASH_SALE_WIDGET_LOADING_STATE:
        const { loadingState } = action;
        return {
            ...state,
            isFlashSaleWidgetLoading: loadingState
        };

    default:
        return state;
    }
};

export default FlashSaleReducer;
