export const UPDATE_LINK_NEXT = 'UPDATE_LINK_NEXT';
export const UPDATE_LINK_PRV = 'UPDATE_LINK_PRV';

/** @namespace AdmScandipwa/Store/LinkHeader/Action/updateLinkPrv */
export const updateLinkPrv = (linkDataPrv) => ({
    type: UPDATE_LINK_PRV,
    linkDataPrv
});

/** @namespace AdmScandipwa/Store/LinkHeader/Action/updateLinkNext */
export const updateLinkNext = (linkDataNext) => ({
    type: UPDATE_LINK_NEXT,
    linkDataNext
});
