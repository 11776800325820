import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ProductSaleLabel.style';

/** @namespace AdmScandipwa/Component/ProductSaleLabel/Component/ProductSaleLabelComponent */
export class ProductSaleLabelComponent extends PureComponent {
    static propTypes = {
        labelValue: PropTypes.string.isRequired,
        displayPosition: PropTypes.string.isRequired
    };

    render() {
        const { labelValue, displayPosition } = this.props;
        return (
            <div
              block="ProductSale"
              elem="Label"
              mods={ { displayPosition } }
            >
                { labelValue }
                { ' ' }
                %
            </div>
        );
    }
}

export default ProductSaleLabelComponent;
