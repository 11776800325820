/* eslint-disable max-lines */
/* eslint-disable max-len */
import Link from 'Component/Link';
import OfflineNotice from 'Component/OfflineNotice';
import SelectLocationPopup from 'Component/SelectLocationPopup';
import Topbar from 'Component/TopBar';
import {
    BILLING_STEP,
    DETAILS_STEP,
    SHIPPING_STEP
} from 'Route/Checkout/Checkout.config';
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';

import {
    ALL_BANK_QR_PAYMENT,
    BLOG,
    BLOG_LIST,
    CART_OVERLAY,
    CHECKOUT_FAIL,
    CHECKOUT_INVALID, CHECKOUT_PROCESSING, CREATE_PASSWORD,
    CUSTOMER_WISHLIST, ORDER_TRACK, RECIPE, RECIPES, SITEMAP
} from './Header.config';

/** @namespace AdmScandipwa/Component/Header/Component/HeaderComponent */
export class HeaderComponent extends SourceHeader {
    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        renderShipTo: this.renderShipTo.bind(this),
        virtualStore: this.renderVirtualStore,
        wishlist: this.renderWishlist.bind(this),
        account: this.renderAccount.bind(this),
        minicart: this.renderMinicart.bind(this),
        search: this.renderSearchField.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this),
        checkoutStep: this.renderCheckoutStep.bind(this)
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct() {
        super.__construct();
        this.stateMap = {
            ...this.stateMap,
            [ORDER_TRACK]: {
                title: true,
                back: true
            },
            [CHECKOUT_PROCESSING]: {
                title: true,
                back: false
            },
            [CHECKOUT_INVALID]: {
                title: true,
                back: false
            },
            [CHECKOUT_FAIL]: {
                title: true,
                back: false
            },
            [CREATE_PASSWORD]: {
                title: true,
                back: true
            },
            [BLOG_LIST]: {
                title: true,
                back: true
            },
            [BLOG]: {
                title: true,
                back: true
            },
            [SITEMAP]: {
                title: true,
                back: true
            },
            [ALL_BANK_QR_PAYMENT]: {
                title: true,
                back: false
            },
            [RECIPES]: {
                title: true,
                back: true
            },
            [RECIPE]: {
                title: true,
                back: true
            }
        };
    }

    renderCheckoutStep() {
        const { isCheckout, currentStep } = this.props;

        return (
            isCheckout && currentStep !== DETAILS_STEP
                ? (
                    <ul block="Header" elem="CheckoutStep" key="CheckoutStep">
                        <li
                          block="Header"
                          elem="ShippingStep"
                          mods={ { isActive: currentStep === SHIPPING_STEP } }
                        >
                            <span>{ __('Shipping') }</span>
                        </li>

                        <li
                          block="Header"
                          elem="BillingStep"
                          mods={ { isActive: currentStep === BILLING_STEP } }
                        >
                            <span>{ __('Payment') }</span>
                        </li>
                    </ul>
                ) : false
        );
    }

    renderTopMenu() {
        return <Topbar />;
    }

    renderShipTo() {
        const {
            showLocationPopup,
            selectedLocation: { location: { inText } = false } = {}
        } = this.props;

        return (
            <div block="Header" elem="ShipToWrapper" key="ShipTo">
                <SelectLocationPopup />
                <button block="Header" elem="ShipToInner" onClick={ showLocationPopup }>
                    <span block="Header" elem="ShipToSubTitle">{ __('Ship to') }</span>
                    { this.renderLocationDetails(inText) }
                </button>
            </div>
        );
    }

    renderLogo(isVisible = false) {
        return (
            <Link
              to="/"
              aria-label="Go to homepage by clicking on ScandiPWA logo"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible } }
              key="logo"
            >
                { this.renderLogoImage() }
            </Link>
        );
    }

    renderLocationDetails(inText) {
        return (

            inText
                ? <span block="Header" elem="ShipToTitle">{ inText }</span>
                : <span block="Header" elem="Placeholder">{ __('Choose location') }</span>

        );
    }

    renderMinicartButton() {
        const {
            onMinicartButtonClick
        } = this.props;

        return (
            <button
              block="Header"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              onClick={ onMinicartButtonClick }
            >
                <span
                  aria-label="Minicart"
                  block="Header"
                  elem="MinicartIcon"
                />
                { this.renderMinicartItemsQty() }
                <span
                  block="Header"
                  elem="MinicartTitle"
                >
                    { __('Cart') }
                </span>
            </button>
        );
    }

    renderOkButton(isVisible = false) {
        const { onOkButtonClick } = this.props;

        return (
            <button
              key="ok"
              block="Header"
              elem="Button"
              mods={ { type: 'ok', isVisible } }
              onClick={ onOkButtonClick }
              aria-label="Save changes"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                { __('SAVE') }
            </button>
        );
    }

    renderVirtualStore() {
        return (
            <Link to="/virtual-store" block="Header" elem="VirtualStoreTLink" key="VirtualStore">
                <span>{ __('Virtual Store') }</span>
            </Link>
        );
    }

    renderWishlist() {
        const { isSignedIn } = this.props;

        if (!isSignedIn) {
            return null;
        }

        return (
            <Link to="/my-account/my-wishlist" block="Header" elem="WishList" key="Wishlist">
                <span>{ __('Wishlist') }</span>
            </Link>
        );
    }

    /**
     * ADM-9 - As we are not showing the loading popup for the account overlay,
     * the overlay is removed from here.
     */
    renderAccountOverlayFallback() {
        return null;
    }

    /**
     * Overridden to add active overlay as a mod
     * @override
     */

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            activeOverlay,
            isCheckout,
            device,
            isSignedIn,
            currentStep
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <section
              block="Header"
              elem="Wrapper"
              mods={ {
                  isCheckout,
                  [currentStep]: !!currentStep
              } }
            >
                <header
                  block="Header"
                  mods={ {
                      activeOverlay: activeOverlay || false, name, isHiddenOnMobile, isCheckout, hasWishlist: isSignedIn
                  } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    { this.renderTopMenu() }
                    <nav block="Header" elem="Nav">
                        { this.renderNavigationState() }
                    </nav>
                    { this.renderMenu() }
                </header>
                    <OfflineNotice />
            </section>
        );
    }
}

export default HeaderComponent;
