export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const MIN_DATE_OF_BIRTH_YEAR = 1960;
export const MAX_YEAR_DIFF_FOR_DATE_OF_BIRTH = 18;
export const NINE = 9;

/** @namespace AdmScandipwa/Util/Date/Index/getDateFromStringYYYYMMDD */
export const getDateFromStringYYYYMMDD = (dateString) => {
    if (!dateString) {
        return null;
    }
    if (dateString.getYear) { // if datestring is already a date obj
        return dateString;
    }
    const dateArray = dateString.split('-');
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];
    return new Date(year, (month - 1), day);
};

/** @namespace AdmScandipwa/Util/Date/Index/getFormattedDateStringYYYYMMDD */
export const getFormattedDateStringYYYYMMDD = (date) => {
    if (!date) {
        return '';
    }
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    return `${year}-${month > NINE ? month : `0${month}`}-${day > NINE ? day : `0${day}`}`;
};

/** @namespace AdmScandipwa/Util/Date/Index/getTodayYYYYMMDD */
export const getTodayYYYYMMDD = (separator = '-') => {
    const today = new Date();
    const DD = String(today.getDate()).padStart(2, '0');
    const MM = String(today.getMonth() + 1).padStart(2, '0');
    const YYYY = today.getFullYear();
    return `${YYYY}${separator}${MM}${separator}${DD}`;
};

export const __monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

/** @namespace AdmScandipwa/Util/Date/Index/getDateMMMDDYYYYFormat */
export const getDateMMMDDYYYYFormat = (date) => {
    const dateObj = new Date(date.replace(/-/g, '/'));
    const MMM = __monthNames[dateObj.getMonth()];
    const DD = dateObj.getDate();
    const YYYY = dateObj.getFullYear();
    return `${MMM} ${DD}, ${YYYY}`;
};
export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const SECONDS_IN_DAY = HOURS_IN_DAY * SECONDS_IN_HOUR;

/** @namespace AdmScandipwa/Util/Date/Index/getSafariCompatibleDateString */
export const getSafariCompatibleDateString = (dateString) => {
    if (typeof dateString !== 'string') {
        return '';
    }

    return dateString.replace(new RegExp('-', 'g'), '/');
};

/** @namespace AdmScandipwa/Util/Date/Index/getDateDifferenceInSeconds */
export const getDateDifferenceInSeconds = ({ fromDate, toDate }) => {
    if (!fromDate || !toDate) {
        return {};
    }

    const fromDateLocal = typeof fromDate === 'string'
        ? new Date(getSafariCompatibleDateString(fromDate)) : fromDate;
    const toDateLocal = typeof toDate === 'string'
        ? new Date(getSafariCompatibleDateString(toDate)) : toDate;

    const totalSecondDiff = Math.max((toDateLocal.getTime() - fromDateLocal.getTime()) / MILLISECONDS_IN_SECOND, 0);
    const days = Math.floor(totalSecondDiff / SECONDS_IN_DAY);
    const hours = Math.floor((totalSecondDiff % SECONDS_IN_DAY) / SECONDS_IN_HOUR);
    const minutes = Math.floor((totalSecondDiff % SECONDS_IN_HOUR) / MINUTES_IN_HOUR);
    const seconds = Math.floor(totalSecondDiff % SECONDS_IN_MINUTE);
    return {
        seconds, minutes, hours, days, totalSecondDiff
    };
};

export const timeZoneDiffCache = {};

/** @namespace AdmScandipwa/Util/Date/Index/getTimeZoneDiff */
export const getTimeZoneDiff = (timeZone) => {
    if (timeZoneDiffCache[timeZone]) {
        return timeZoneDiffCache[timeZone];
    }
    const dateWithTimeZone = new Date(new Date().toLocaleString('en-US', { timeZone }));
    const diff = dateWithTimeZone.getTime() - new Date().getTime();
    timeZoneDiffCache[timeZone] = diff;
    return diff;
};

/** @namespace AdmScandipwa/Util/Date/Index/getDateWithTimeZone */
export const getDateWithTimeZone = ({ date, timeZone }) => {
    const dateObj = typeof date === 'string' ? new Date(getSafariCompatibleDateString(date)) : date;
    return new Date(dateObj.getTime() - getTimeZoneDiff(timeZone));
};
