/* eslint-disable no-unused-vars */
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import FieldDateComponent from './FieldDate.component';

/** @namespace AdmScandipwa/Component/FieldDate/Container/FieldDateContainer */
export class FieldDateContainer extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ])
    };

    containerFunctions = {
        onCalendarOpen: this.onCalendarOpen.bind(this),
        onCalendarClose: this.onCalendarClose.bind(this)
    };

    static defaultProps = {
        value: '',
        isDisabled: false
    };

    static state = {
        isCalendarOpen: false
    };

    onCalendarOpen() {
        this.setState({ isCalendarOpen: true });
    }

    onCalendarClose() {
        this.setState({ isCalendarOpen: false });
    }

    containerProps = () => {
        const {
            /* eslint-disable react/prop-types */
            dispatch,
            selectOptions,
            isControlled,
            handleChange,
            onChangeCheckbox,
            onKeyEnterDown,
            formRefMap,
            validationStatus,
            /* eslint-enable react/prop-types */

            // Props to be transformed
            isDisabled: disabled,

            // Props that are passed correctly from the beginning
            ...validProps
        } = this.props;

        return {
            ...validProps,
            disabled
        };
    };

    render() {
        return (
            <FieldDateComponent
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.containerProps() }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.state }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldDateContainer;
