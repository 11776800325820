import { connect } from 'react-redux';

import { PRODUCT_COMPARE_ADD_SUCCESS }
    from 'Component/ProductCompareAddSuccessPopup/ProductCompareAddSuccessPopup.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCompareButtonContainer as SourceProductCompareButtonContainer
} from 'SourceComponent/ProductCompareButton/ProductCompareButton.container';
import { showPopup } from 'Store/Popup/Popup.action';
import dispatcher from 'Store/ProductCompare/ProductCompare.dispatcher.extended';

/** @namespace AdmScandipwa/Component/ProductCompareButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    addProductToCompare: (productId) => dispatcher.addProductToCompare(productId, dispatch),
    showProductCompareAddSuccessPopup: (payload) => dispatch(showPopup(PRODUCT_COMPARE_ADD_SUCCESS, payload))
});

/** @namespace AdmScandipwa/Component/ProductCompareButton/Container/ProductCompareButtonContainer */
export class ProductCompareButtonContainer extends SourceProductCompareButtonContainer {
    /**
     * Here overriding the whole function is not a right way,
     * But As function is a async function we are not able to override using super.handleClick
     * For overriding this we need to change the babel settings.
     * @returns {Promise<void>}
     */
    async handleClick() {
        const {
            productId,
            addProductToCompare,
            removeComparedProduct,
            showProductCompareAddSuccessPopup,
            product: { name, small_image }
        } = this.props;

        this.setState({ isLoading: true });

        if (this.isActive()) {
            await removeComparedProduct(productId);
        } else {
            await addProductToCompare(productId);
            showProductCompareAddSuccessPopup({ name, small_image, title: __('Added To Comparison list') });
        }

        this.setState({ isLoading: false });
    }
}

export default connect(sourceMapStateToProps, mapDispatchToProps)(ProductCompareButtonContainer);
