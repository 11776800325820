import { Meta as SourceMeta } from 'SourceComponent/Meta/Meta.component';

/** @namespace AdmScandipwa/Component/Meta/Component/MetaComponent */
export class MetaComponent extends SourceMeta {
    renderHreflangs() {
        const { getCurrentHrefLangs } = this.props;

        return getCurrentHrefLangs().map(({ url, code }) => (
        <link
          rel="alternate"
          hrefLang={ code }
          href={ `${location.origin}/${url}` }
        />
        ));
    }

    renderMeta() {
        const { metadata } = this.props;
        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { this.renderHreflangs() }

                { metadata.map((tag) => (
                <meta
                  key={ tag.name || tag.property }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...tag }
                />
                )) }
            </>
        );
    }
}

export default MetaComponent;
