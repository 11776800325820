import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

import {
    REMOVE_PRODUCT_FLASH_SALE_FROM_CART_ITEM,
    SET_CART_LOADING_STATE,
    UPDATE_SHIPPING_PRICE, UPDATE_TOTALS
} from './Cart.action';

export const CART_TOTALS = 'cart_totals';

/** @namespace AdmScandipwa/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action, state) => {
    const { cartData: cartTotals } = action;
    const { isLoading } = state;

    if (Object.hasOwnProperty.call(cartTotals, 'items')) {
        const normalizedItemsProduct = cartTotals.items.map((item) => {
            const normalizedItem = item;
            normalizedItem.product = getIndexedProduct(item.product);

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
    }

    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS
    );

    return { cartTotals, isLoading };
};

/** @namespace AdmScandipwa/Store/Cart/Reducer/updateShippingPrice */
export const updateShippingPrice = (action, state) => {
    const {
        data: {
            // eslint-disable-next-line no-unused-vars
            items,
            ...rest
        } = {}
    } = action;

    return {
        cartTotals: {
            ...state.cartTotals,
            ...rest
        }
    };
};

/** @namespace AdmScandipwa/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    cartTotals: BrowserDatabase.getItem(CART_TOTALS) || {},
    isLoading: true
});

/** @namespace AdmScandipwa/Store/Cart/Reducer/CartReducer */
export const CartReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_TOTALS:
        return updateCartTotals(action, state);
    case UPDATE_SHIPPING_PRICE:
        return updateShippingPrice(action, state);
    case REMOVE_PRODUCT_FLASH_SALE_FROM_CART_ITEM:
        const { cartTotals = { items: [] } } = state;
        const updatedCartTotals = { ...cartTotals };
        // Deep clone the cart items
        const updatedCartItems = JSON.parse(JSON.stringify(updatedCartTotals.items));
        const { productId } = action;

        if (!Object.keys(updatedCartItems).length) {
            return state;
        }

        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const index in updatedCartItems) {
            const { product } = updatedCartItems[index];
            const { id } = product;
            const { flash_sale, ...updatedProduct } = product; // Removing the flash sale object here
            if (id === productId) {
                // if flash sale is already removed then return the current state only
                if (!flash_sale) {
                    return state;
                }
                updatedCartItems[index].product = { ...updatedProduct };
            }
        }

        updatedCartTotals.items = updatedCartItems;

        return {
            ...state,
            cartTotals: updatedCartTotals
        };

    case SET_CART_LOADING_STATE:
        const { isLoading = true } = action;
        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default CartReducer;
