import React, { lazy } from 'react';
import { Router as ReactRouter } from 'react-router';
import { Route } from 'react-router-dom';

import InstallPrompt from 'Component/InstallPrompt';
import JsonLd from 'Component/JsonLd';
import LinkHeader from 'Component/LinkHeader';
import Meta from 'Component/Meta';
import MyAccountCompleteProfilePopupComponent
    from 'Component/MyAccountCompleteProfilePopup';
import MyAccountDeleteAccountPopup
    from 'Component/MyAccountDeleteAccountPopup';
import { RECIPES_PATH } from 'Route/Recipes/recipes.config';
import SourceRouterComponent, { withStoreRegex } from 'SourceComponent/Router/Router.component';
import { AFTER_ITEMS_TYPE, BEFORE_ITEMS_TYPE, SWITCH_ITEMS_TYPE } from 'SourceComponent/Router/Router.config';
import history from 'Util/History';

import MyAccountLogoutNotifier from '../MyAccountLogoutNotifier';
import ProductCompareAddSuccessPopup from '../ProductCompareAddSuccessPopup';

// eslint-disable-next-line max-len
export const PaymentCallback = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/PaymentCallback'));
export const TrackPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/TrackPage'));
export const BlogPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "blogPage" */ 'Route/BlogPage'));
export const BlogsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "blogsPage" */ 'Route/BlogsPage'));
// eslint-disable-next-line max-len
export const SitemapPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/SitemapPage'));
export const AllBankQrPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "blogsPage" */ 'Route/AllBankQrPage'
));
export const Recipes = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "recipesPage" */ 'Route/Recipes'));
export const Recipe = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "recipeItem" */ 'Route/Recipe'));

/** @namespace AdmScandipwa/Component/Router/Component/RouterComponent */
export class RouterComponent extends SourceRouterComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [BEFORE_ITEMS_TYPE] = [
        ...this[BEFORE_ITEMS_TYPE],
        { component: <InstallPrompt />, position: 1 }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [AFTER_ITEMS_TYPE] = [
        ...this[AFTER_ITEMS_TYPE],
        {
            component: <ProductCompareAddSuccessPopup />,
            position: 25
        },
        {
            component: <MyAccountLogoutNotifier />,
            position: 25
        },
        {
            component: <MyAccountCompleteProfilePopupComponent />,
            position: 25
        },
        {
            component: <MyAccountDeleteAccountPopup />,
            position: 28
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
            component:
                <Route
                  path={ [withStoreRegex('/payments/:callback'), withStoreRegex('/payments/:callback/index')] }
                  exact
                    // eslint-disable-next-line react/jsx-no-bind
                  render={ (props) => (
                        <PaymentCallback
                            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                          { ...props }
                        />
                  ) }
                />,
            position: 55
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex('/track-order') } render={ (props) => <TrackPage { ...props } key={ Date.now() } /> } />,
            position: 84
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex('/blog/category/:categoryUrlKey') } render={ (props) => <BlogsPage { ...props } /> } />,
            position: 85
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex('/blog/:blogUrlKey') } render={ (props) => <BlogPage { ...props } /> } />,
            position: 86
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex('/blog') } render={ (props) => <BlogsPage { ...props } /> } />,
            position: 87
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route exact path={ withStoreRegex('/qr-payments/all-bank') } render={ (props) => <AllBankQrPage { ...props } /> } />,
            position: 88
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex('/sitemap') } render={ (props) => <SitemapPage { ...props } /> } />,
            position: 90
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex(`/${RECIPES_PATH}/:recipeId/:urlKey`) } render={ (props) => <Recipe { ...props } /> } />,
            position: 91
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex(`/${RECIPES_PATH}`) } render={ (props) => <Recipes { ...props } /> } />,
            position: 92
        }
    ];

    /**
     * @override to add JsonLD tags
     */
    render() {
        return (
            <>
                    <Meta />
                    <LinkHeader />
                    <JsonLd />
                    <ReactRouter history={ history }>
                        { this.renderRouterContent() }
                    </ReactRouter>
            </>
        );
    }
}

export default RouterComponent;
