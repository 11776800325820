import ProductCompareQuery from 'Query/ProductCompare.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { addComparedProductIds } from 'Store/ProductCompare/ProductCompare.action';
import { ProductCompareDispatcher as SourceProductCompareDispatcher }
    from 'Store/ProductCompare/ProductCompare.dispatcher';
import { fetchMutation } from 'Util/Request';

/**
 * Here file name is set like this, because if not set like that, it will throw the following error.
 * Unhandled Rejection (ReferenceError): Cannot access 'ProductCompareDispatcher' before initialization
 */
/** @namespace AdmScandipwa/Store/ProductCompare/Dispatcher/ProductCompareDispatcher */
export class ProductCompareDispatcher extends SourceProductCompareDispatcher {
    async addProductToCompare(productId, dispatch) {
        const guestCartId = await this._getGuestQuoteId(dispatch);

        try {
            const result = await fetchMutation(
                ProductCompareQuery.getAddProductToCompareMutation(
                    productId,
                    guestCartId
                )
            );

            dispatch(addComparedProductIds(productId));
            // Removed notification from here.
            return result;
        } catch (error) {
            dispatch(showNotification('error', __('Unable to add product to the compare list'), error));
            return false;
        }
    }
}

export default new ProductCompareDispatcher();
