import {
    getStaticReducers as sourceGetStaticReducers
} from 'SourceStore/index';
import BlogReducer from 'Store/Blog/Blog.reducer';
import CurrentStepReducer from 'Store/CurrentStep/CurrentStep.reducer';
import FlashSaleReducer from 'Store/FlashSale/FlashSale.reducer';
import JsonLdReducer from 'Store/JsonLd/JsonLd.reducer';
import LinkHeaderReducer from 'Store/LinkHeader/LinkHeader.reducer';
import LocationChangerReducer from 'Store/LocationChanger/LocationChanger.reducer';
import OutOfStockMessageReducer from 'Store/OutOfStockMessage/OutOfStockMessage.reducer';
import RecipeReducer from 'Store/Recipe/Recipe.reducer';

/**
 * Extended to add LocationChangerReducer
 * @extends
 * @namespace AdmScandipwa/Store/Index/getStaticReducers
 */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    LocationChangerReducer,
    CurrentStepReducer,
    OutOfStockMessageReducer,
    BlogReducer,
    JsonLdReducer,
    FlashSaleReducer,
    RecipeReducer,
    LinkHeaderReducer
});

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
