import { Component } from 'react';

import { TOPBAR_ID } from 'Component/TopBar/Topbar.config';
import CmsBlock from 'SourceComponent/CmsBlock';

import './Topbar.style';

/** @namespace AdmScandipwa/Component/TopBar/Topbar/Component/TopbarComponent */
export class TopbarComponent extends Component {
    render() {
        return (
            <div block="Topbar">
                <div block="Topbar" elem="Wrapper">
                    <CmsBlock identifier={ TOPBAR_ID } />
                    <div block="Topbar" elem="OrderTracker">
                        <CmsBlock identifier="kemanapwa_order_tracker" />
                    </div>
                </div>
            </div>
        );
    }
}

export default TopbarComponent;
