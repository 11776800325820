import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import { formatPrice } from 'Util/Price';

/** @namespace AdmScandipwa/Component/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends SourceProductPrice {
    /**
     * Price formatted to add the currency
     * @extends
     */

    renderOldPrice() {
        const {
            roundedRegularPrice,
            discountPercentage,
            priceCurrency
        } = this.props;

        if (discountPercentage === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
            >
                { formatPrice(roundedRegularPrice, priceCurrency) }
            </del>
        );
    }

    /**
     * @extends to calculate special price for customizable option enable products
     */

    renderCurrentPrice() {
        const {
            discountPercentage,
            formattedFinalPrice
        } = this.props;

        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';
        // eslint-disable-next-line eqeqeq
        if (formattedFinalPrice == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName>
                <span>
                    { formattedFinalPrice }
                </span>
            </PriceSemanticElementName>
        );
    }

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {}
            } = {},
            discountPercentage,
            formattedFinalPrice,
            mix
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0 } }
              mix={ mix }
              aria-label={ `Product price: ${formattedFinalPrice}` }
            >
                { this.renderCurrentPrice() }
                { this.renderOldPrice() }
                { this.renderSubPrice() }
            </p>
        );
    }
}

export default ProductPriceComponent;
