import {
    CUSTOMER_POPUP_ID,
    EDIT_CUSTOMER
} from '@scandipwa/scandipwa/src/component/MyAccountCustomerPopup/MyAccountCustomerPopup.config';
import { showPopup } from '@scandipwa/scandipwa/src/store/Popup/Popup.action';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { POPUP } from 'Component/Header/Header.config';
import MyAccountCompleteProfilePopupComponent
    from 'Component/MyAccountCompleteProfilePopup/MyAccountCompleteProfilePopup.component';
import { MY_ACCOUNT_COMPLETE_PROFILE_POPUP_ID, MY_ACCOUNT_COMPLETE_PROFILE_POPUP_TITLE }
    from 'Component/MyAccountCompleteProfilePopup/MyAccountCompleteProfilePopup.config';
import { changeNavigationHeaderTitle, changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { customerType } from 'Type/Account';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace AdmScandipwa/Component/MyAccountCompleteProfilePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    topNavigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState.name,
    isMobile: state.ConfigReducer.device.isMobile,
    customer: state.MyAccountReducer.customer,
    isPopupActive: state.PopupReducer.popupPayload
            && state.PopupReducer.popupPayload[MY_ACCOUNT_COMPLETE_PROFILE_POPUP_ID]
            && state.PopupReducer.popupPayload[MY_ACCOUNT_COMPLETE_PROFILE_POPUP_ID].title
});

/** @namespace AdmScandipwa/Component/MyAccountCompleteProfilePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    changeNavigationHeaderTitle: (title) => dispatch(changeNavigationHeaderTitle(title)),
    showEditUserPopup: (payload) => dispatch(showPopup(CUSTOMER_POPUP_ID, {
        title: MY_ACCOUNT_COMPLETE_PROFILE_POPUP_TITLE,
        ...payload
    })),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName))
});

/** @namespace AdmScandipwa/Component/MyAccountCompleteProfilePopup/Container/MyAccountCompleteProfilePopupContainer */
export class MyAccountCompleteProfilePopupContainer extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        isPopupActive: PropTypes.bool.isRequired,
        changeNavigationHeaderTitle: PropTypes.func.isRequired,
        showEditUserPopup: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        isMobile: PropTypes.bool.isRequired,
        setHeaderState: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleUpdateAction: this.handleUpdateAction.bind(this)
    };

    componentDidUpdate() {
        const { changeNavigationHeaderTitle, isPopupActive, setHeaderState } = this.props;
        if (isPopupActive) {
            setHeaderState({
                title: MY_ACCOUNT_COMPLETE_PROFILE_POPUP_TITLE,
                name: POPUP
            });
            changeNavigationHeaderTitle(MY_ACCOUNT_COMPLETE_PROFILE_POPUP_TITLE);
        }
    }

    handleUpdateAction() {
        const {
            hideActiveOverlay, showEditUserPopup, customer, isMobile
        } = this.props;

        if (isMobile) {
            hideActiveOverlay();
            showEditUserPopup({
                action: EDIT_CUSTOMER,
                customer,
                title: __('Edit customer details')
            });
        } else {
            history.push({ pathname: appendWithStoreCode('/my-account/dashboard') });
        }
    }

    render() {
        return (
            <MyAccountCompleteProfilePopupComponent
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCompleteProfilePopupContainer);
