// Header ignoreOosCheck will be true for any partial post query string matches to below array

export const ignoreOosCheckList = [
    's_wishlist',
    's_saveWishlistItem',
    's_removeProductFromWishlist',
    's_moveWishlistToCart',
    's_clearWishlist',
    's_shareWishlist',
    'compareProducts',
    'addProductToCompare',
    'removeComparedProduct',
    'clearComparedProducts',
    'getOrderDataById'
];
export const GRAPHQL_AUTHORIZATION_ERROR = 'graphql-authorization';
export const AUTH_ERROR_MESSAGE_SHOW_TIMEOUT = 2000;
