import { getMonth, getYear, subYears } from 'date-fns';
import PropTypes from 'prop-types';
import { forwardRef, PureComponent } from 'react';
import DatePicker from 'react-datepicker';

import {
    getDateFromStringYYYYMMDD,
    MAX_YEAR_DIFF_FOR_DATE_OF_BIRTH, MIN_DATE_OF_BIRTH_YEAR, MONTHS
} from 'Util/Date';

import 'react-datepicker/dist/react-datepicker.css';
import './FieldDate.style.scss';

export const ReadOnlyInput = forwardRef((props, ref) => {
    const { value, onClick, placeholder } = props;
    return (
        <input
          value={ value }
          readOnly
          onClick={ onClick }
          ref={ ref }
          placeholder={ placeholder }
        />
    );
});

ReadOnlyInput.propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

/** @namespace AdmScandipwa/Component/FieldDate/Component/FieldDateComponent */
export class FieldDateComponent extends PureComponent {
    static propTypes = {
        value: PropTypes.string,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onCalendarClose: PropTypes.func.isRequired,
        onCalendarOpen: PropTypes.func.isRequired,
        isCalendarOpen: PropTypes.bool.isRequired,
        formRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    };

    static defaultProps = {
        formRef: () => {},
        value: ''
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const minYear = MIN_DATE_OF_BIRTH_YEAR;
        const maxYear = getYear(subYears(new Date(), MAX_YEAR_DIFF_FOR_DATE_OF_BIRTH));
        const years = [];
        for (let i = minYear; i <= maxYear; i++) {
            years.push(i);
        }
        this.setState({ years });
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    setDateRef = (datePickerRef) => {
        if (!datePickerRef) {
            return;
        }
        const { formRef, name, value } = this.props;
        const { props: { selected } } = datePickerRef;
        formRef.current = datePickerRef;
        formRef.current.value = selected || value;
        formRef.current.name = name;
        formRef.current.id = name;
        formRef.current.dataset = {};
    };

    renderCustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => (
        <div block="FieldDate" elem="HeaderWrapper">
            <div
              block="FieldDate"
              elem="HeaderWrapperTop"
            >
            <div
              block="FieldDate"
              elem="HeaderWrapperButtons"
            >
                <button
                  block="FieldDate"
                  elem="BackwardButton"
                  type="button"
                  onClick={ decreaseMonth }
                  disabled={ prevMonthButtonDisabled }
                >
                    <span>{ '<' }</span>
                </button>
                <div
                  block="FieldDate"
                  elem="MonthandYear"
                >
                    { MONTHS[getMonth(date)] }
                    { ' ' }
                    { getYear(date) }
                </div>
                <button
                  block="FieldDate"
                  elem="ForwardButton"
                  type="button"
                  onClick={ increaseMonth }
                  disabled={ nextMonthButtonDisabled }
                >
                    <span>{ '<' }</span>
                </button>
            </div>
            <div
              block="FieldDate"
              elem="Bottom"
            >
                <div
                  block="FieldDate"
                  elem="Month"
                >
                    <select
                      value={ MONTHS[getMonth(date)] }
                        /* eslint-disable-next-line react/jsx-no-bind */
                      onChange={ ({ target: { value } }) => changeMonth(MONTHS.indexOf(value)) }
                    >
                        { MONTHS.map((option) => (
                            <option
                              block="FieldDate"
                              elem="MonthOptions"
                              key={ option }
                              value={ option }
                            >
                                { option }
                            </option>
                        )) }
                    </select>
                </div>
                <div
                  block="FieldDate"
                  elem="Year"
                >
                    <select
                      value={ getYear(date) }
                    /* eslint-disable-next-line react/jsx-no-bind */
                      onChange={ ({ target: { value } }) => changeYear(value) }
                    >
                        { /* eslint-disable-next-line react/destructuring-assignment */ }
                        { this.state.years.map((option) => (
                            <option
                              key={ option }
                              block="FieldDate"
                              elem="YearOptions"
                              value={ option }
                            >
                                { option }
                            </option>
                        )) }
                    </select>
                </div>
            </div>
            </div>
        </div>
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    onDateChange = (newDate) => {
        const {
            onChange, formRef
        } = this.props;

        if (formRef && formRef.current) {
            formRef.current.value = newDate;
        }

        onChange(newDate);
    };

    render() {
        const {
            value,
            placeholder,
            isCalendarOpen,
            onCalendarOpen,
            onCalendarClose
        } = this.props;

        return (
            <div block="FieldDate" elem="Container" mods={ { isCalendarOpen } }>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  customInput={ <ReadOnlyInput /> }
                  ref={ (dateRef) => this.setDateRef(dateRef) }
                  selected={ getDateFromStringYYYYMMDD(value) }
                  onChange={ this.onDateChange }
                  onCalendarOpen={ onCalendarOpen }
                  onCalendarClose={ onCalendarClose }
                  placeholderText={ placeholder }
                  minDate={ new Date(MIN_DATE_OF_BIRTH_YEAR, 0, 1) }
                  maxDate={ subYears(new Date(), MAX_YEAR_DIFF_FOR_DATE_OF_BIRTH) }
                  renderCustomHeader={ this.renderCustomHeader }
                />
            </div>
        );
    }
}

export default FieldDateComponent;
