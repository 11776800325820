import { LOCATION_DATA } from 'Component/SelectLocationPopup/SelectLocationPopup.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import { SET_HEADER_LOCATION } from './LocationChanger.action';
/** @namespace AdmScandipwa/Store/LocationChanger/Reducer/getInitialState */
export const getInitialState = () => ({
    location: BrowserDatabase.getItem(LOCATION_DATA) || {}
});

/** @namespace AdmScandipwa/Store/LocationChanger/Reducer/LocationChangerReducer */
export const LocationChangerReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_HEADER_LOCATION:
        const { location } = action;
        return {
            ...state,
            location
        };

    default:
        return state;
    }
};

export default LocationChangerReducer;
