export const UPDATE_RECIPE_DETAILS = 'UPDATE_RECIPE_DETAILS';
export const GET_RECIPE_DETAIL_LOADING = 'GET_RECIPE_DETAIL_LOADING';

/** @namespace AdmScandipwa/Store/Recipe/Action/updateRecipeDetails */
export const updateRecipeDetails = (recipeDetails) => ({
    type: UPDATE_RECIPE_DETAILS,
    recipeDetails
});

/** @namespace AdmScandipwa/Store/Recipe/Action/getRecipeDetailLoading */
export const getRecipeDetailLoading = (isGetRecipeLoading) => ({
    type: GET_RECIPE_DETAIL_LOADING,
    isGetRecipeLoading
});
