import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import { MY_ACCOUNT_COMPLETE_PROFILE_POPUP_ID } from './MyAccountCompleteProfilePopup.config';

import './MyAccountCompleteProfilePopup.style.scss';

/** @namespace AdmScandipwa/Component/MyAccountCompleteProfilePopup/Component/MyAccountCompleteProfilePopupComponent */
export class MyAccountCompleteProfilePopupComponent extends PureComponent {
    static propTypes = {
        handleUpdateAction: PropTypes.func.isRequired
    };

    render() {
        const { handleUpdateAction } = this.props;
        return (
            <Popup
              id={ MY_ACCOUNT_COMPLETE_PROFILE_POPUP_ID }
              mix={ { block: 'MyAccountCompleteProfilePopup' } }
            >
                <p block="MyAccountCompleteProfilePopup" elem="Content">
                    { /* eslint-disable-next-line max-len */ }
                    { __('Keep your contact details with us up-to-date to receive latest updates, important advisories and to make the most out of ') }
                    <a href="www.allday.com.ph">www.allday.com.ph</a>
                </p>
                <button
                  type="button"
                  block="MyAccountCompleteProfilePopup"
                  elem="Button"
                  className="Button"
                  onClick={ handleUpdateAction }
                >
                    { __('Click here to update the profile') }
                </button>
            </Popup>
        );
    }
}
export default MyAccountCompleteProfilePopupComponent;
