export * from 'SourceStore/Checkout/Checkout.action';

export const UPDATE_PICKUP_STORE_LIST = 'UPDATE_PICKUP_STORE_LIST';
export const UPDATE_SELECTED_PICKUP_STORE_ID = 'UPDATE_SELECTED_PICKUP_STORE_ID';
export const UPDATE_DELIVERY_ESTIMATION = 'UPDATE_DELIVERY_ESTIMATION';
export const UPDATE_SHIPPING_ADDRESS_INFO = 'UPDATE_SHIPPING_ADDRESS_INFO';
export const UPDATE_CHECKOUT_ALL_REWARD_CARD_NUMBER = 'UPDATE_CHECKOUT_ALL_REWARD_CARD_NUMBER';

/** @namespace AdmScandipwa/Store/Checkout/Action/updatePickupStoreList */
export const updatePickupStoreList = (storeList) => ({
    type: UPDATE_PICKUP_STORE_LIST,
    storeList
});

/** @namespace AdmScandipwa/Store/Checkout/Action/updateSelectedPickupStoreId */
export const updateSelectedPickupStoreId = (selectedPickupStoreId) => ({
    type: UPDATE_SELECTED_PICKUP_STORE_ID,
    selectedPickupStoreId
});
/** @namespace AdmScandipwa/Store/Checkout/Action/updateAllRewardCardNumber */
export const updateAllRewardCardNumber = (allRewardCardNumber) => ({
    type: UPDATE_CHECKOUT_ALL_REWARD_CARD_NUMBER,
    allRewardCardNumber
});

/** @namespace AdmScandipwa/Store/Checkout/Action/updateDeliveryEstimation */
export const updateDeliveryEstimation = (deliveryEstimation) => ({
    type: UPDATE_DELIVERY_ESTIMATION,
    deliveryEstimation
});

/** @namespace AdmScandipwa/Store/Checkout/Action/updateAddressInformation */
export const updateAddressInformation = (shippingAddressData) => ({
    type: UPDATE_SHIPPING_ADDRESS_INFO,
    shippingAddressData
});
