import { OUT_OF_STOCK_ERROR } from 'Store/Cart/Cart.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_OOS_MESSAGE } from './OutOfStockMessage.action';

/** @namespace AdmScandipwa/Store/OutOfStockMessage/Reducer/getInitialState */
export const getInitialState = () => {
    const { districtId = false, message = false, active = false } = BrowserDatabase.getItem(OUT_OF_STOCK_ERROR) || {};
    return { districtId, message, active };
};

/** @namespace AdmScandipwa/Store/OutOfStockMessage/Reducer/OutOfStockMessageReducer */
export const OutOfStockMessageReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_OOS_MESSAGE:
        const { districtId, message, active } = action;

        BrowserDatabase.setItem({ districtId, message, active }, OUT_OF_STOCK_ERROR);

        return {
            ...state,
            districtId,
            message,
            active
        };

    default:
        return state;
    }
};

export default OutOfStockMessageReducer;
