/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    CLEAR_CURRENT_BLOG,
    UPDATE_BLOG_CATEGORY_LIST,
    UPDATE_BLOG_DETAILS,
    UPDATE_RELATED_BLOG_STATE
} from 'Store/Blog/Blog.action';

/** @namespace AdmScandipwa/Store/Blog/Reducer/updateBlogDetails */
export const updateBlogDetails = (action, state) => {
    const { blog = {} } = action;

    return { ...state, blog };
};

/** @namespace AdmScandipwa/Store/Blog/Reducer/getInitialState */
export const getInitialState = () => ({
    blog: {},
    isRelatedBlogsLoaded: false,
    categoryList: []
});

/** @namespace AdmScandipwa/Store/Blog/Reducer/BlogReducer */
export const BlogReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case CLEAR_CURRENT_BLOG:
        return { ...state, blog: {}, isRelatedBlogsLoaded: false };
    case UPDATE_BLOG_DETAILS:
        return updateBlogDetails(action, state);
    case UPDATE_RELATED_BLOG_STATE:
        const { newState: isRelatedBlogsLoaded } = action;
        return { ...state, isRelatedBlogsLoaded };
    case UPDATE_BLOG_CATEGORY_LIST:
        const { categoryList } = action;
        return { ...state, categoryList };
    default:
        return state;
    }
};

export default BlogReducer;
