import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

/** @namespace AdmScandipwa/Component/LinkHeader/Component/LinkHeaderComponent */
export class LinkHeaderComponent extends PureComponent {
    static propTypes = {
        headerLinkNext: PropTypes.string,
        headerLinkPrv: PropTypes.string
    };

    static defaultProps = {
        headerLinkNext: '',
        headerLinkPrv: ''
    };

    renderLink() {
        const { headerLinkNext, headerLinkPrv } = this.props;

        if (headerLinkNext === '' && headerLinkPrv) {
            return <link rel="prev" href={ headerLinkPrv } />;
        }

        if (headerLinkPrv === '' && headerLinkNext) {
            return <link rel="next" href={ headerLinkNext } />;
        }

        return (
            <>
           <link rel="next" href={ headerLinkNext } />
            <link rel="prev" href={ headerLinkPrv } />
            </>
        );
    }

    render() {
        const { headerLinkNext, headerLinkPrv } = this.props;
        if (headerLinkPrv === '' && headerLinkNext === '') {
            return false;
        }

        return createPortal(
            { ...this.renderLink() },
            document.head
        );
    }
}

export default LinkHeaderComponent;
