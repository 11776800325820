import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductListWidgetContainer as SourceProductListWidgetContainer
} from 'SourceComponent/ProductListWidget/ProductListWidget.container';

import './ProductListWidget.style';

/** @namespace AdmScandipwa/Component/ProductListWidget/Container/ProductListWidgetContainer */
export class ProductListWidgetContainer extends SourceProductListWidgetContainer {
    requestProductList(options) {
        const { productData } = this.props;
        if (productData) { // if there is product data from the props use that instead of calling API
            this.updateProductListItems(productData);
        } else {
            super.requestProductList(options);
        }
    }

    adaptProps() {
        const {
            showPager,
            productsCount,
            productsPerPage,
            filter = {},
            conditionsEncoded: conditions
        } = this.props;

        return {
            filter: { conditions, ...filter },
            pageSize: showPager ? productsPerPage : productsCount,
            isPaginationEnabled: !!showPager
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListWidgetContainer);
