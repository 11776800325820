import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import LinkHeader from './LinkHeader.component';

/** @namespace AdmScandipwa/Component/LinkHeader/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    headerLinkPrv: state.LinkHeaderReducer.pageLinkPrv?.headerLinkPrv,
    headerLinkNext: state.LinkHeaderReducer.pageLinkNext?.headerLinkNext
});

/** @namespace AdmScandipwa/Component/LinkHeader/Container/LinkHeaderContainer */
export class LinkHeaderContainer extends PureComponent {
    static propTypes = {
        headerLinkNext: PropTypes.string,
        headerLinkPrv: PropTypes.string
    };

    static defaultProps = {
        headerLinkNext: '',
        headerLinkPrv: ''
    };

    render() {
        return (
            <LinkHeader
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
            />
        );
    }
}

/** @namespace AdmScandipwa/Component/LinkHeader/Container/mapDispatchToProps */

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LinkHeaderContainer);
