import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import { MY_ACCOUNT_DELETE_PROFILE_POPUP_ID } from './MyAccountDeleteAccountPopup.config';

import './MyAccountDeleteAccountPopup.style.scss';

/** @namespace AdmScandipwa/Component/MyAccountDeleteAccountPopup/Component/MyAccountDeleteAccountPopupComponent */
export class MyAccountDeleteAccountPopupComponent extends PureComponent {
    static propTypes = {
        lastName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        closePopup: PropTypes.func.isRequired,
        deleteAccount: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    render() {
        const {
            deleteAccount, firstName, lastName, closePopup, isLoading
        } = this.props;

        return (
            <Popup
              id={ MY_ACCOUNT_DELETE_PROFILE_POPUP_ID }
              mix={ { block: 'MyAccountDeleteAccountPopup' } }
            >
                <p block="MyAccountDeleteAccountPopup" elem="Content">
                    <b>{ __('WARNING') }</b>
                    { __(': You are about to delete the user ') }
                    <b>{ `${ firstName } ${ lastName}. ` }</b>
                    { __('There is no way to recover this.') }
                </p>
                <div block="MyAccountDeleteAccountPopup" elem="ActionButtons">
                <button
                  type="button"
                  block="MyAccountDeleteAccountPopup"
                  elem="Cancel"
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  onClick={ closePopup }
                  disabled={ isLoading }
                >
                        { __('Cancel') }
                </button>
                    <button
                      type="button"
                      block="MyAccountDeleteAccountPopup"
                      elem="Delete"
                      mix={ { block: 'Button' } }
                      onClick={ deleteAccount }
                      disabled={ isLoading }
                    >
                            { __('Delete Account') }
                    </button>
                </div>
            </Popup>
        );
    }
}
export default MyAccountDeleteAccountPopupComponent;
