import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { cityListType, districtListType, regionListType } from 'Type/SelectLocation';
import BrowserDatabase from 'Util/BrowserDatabase';

import { FORM_IDS, LOCATION_DATA, SELECT_LOCATION_POPUP_ID } from './SelectLocationPopup.config';

import './SelectLocationPopup.style';

/** @namespace AdmScandipwa/Component/SelectLocationPopup/Component/SelectLocationPopupComponent */
export class SelectLocationPopupComponent extends PureComponent {
    static propTypes = {
        regionList: regionListType.isRequired,
        cityList: cityListType.isRequired,
        districtList: districtListType.isRequired,
        handleRegionChange: PropTypes.func.isRequired,
        handleCityChange: PropTypes.func.isRequired,
        onSubmitSuccess: PropTypes.func.isRequired,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false
    };

    renderHeader() {
        return <h3 block="SelectLocationPopup" elem="title">{ __('Choose Your Location') }</h3>;
    }

    renderButton() {
        return (
            <button
              block="SelectLocationPopup"
              elem="Submit"
              type="submit"
              mix={ { block: 'Button' } }
            >
                { __('START SHOPPING') }
            </button>
        );
    }

    renderForm() {
        const {
            regionList, cityList, districtList, handleCityChange, handleRegionChange, onSubmitSuccess
        } = this.props;

        const hasLocationData = BrowserDatabase.getItem(LOCATION_DATA);
        return (
            <Form
              onSubmitSuccess={ onSubmitSuccess }
              block="SelectLocationPopup"
              elem="Form"
            >
                <Field
                  id={ FORM_IDS.REGION }
                  block="SelectLocationPopup"
                  elem="Region"
                  name={ FORM_IDS.REGION }
                  type="select"
                  label={ __('Province') }
                  value={ hasLocationData
                      ? hasLocationData.SelectionLocationRegion.value
                      : null }
                  validation={ ['notEmpty'] }
                  selectOptions={ regionList }
                  onChange={ handleRegionChange }
                  isDisabled={ !regionList?.length }
                  placeholder={ __('-- Please select a region, state or province --') }
                />

                <Field
                  id={ FORM_IDS.CITY }
                  block="SelectLocationPopup"
                  elem="City"
                  name={ FORM_IDS.CITY }
                  type="select"
                  label={ __('City') }
                  value={ hasLocationData
                      ? hasLocationData.SelectionLocationCity.value
                      : null }
                  validation={ ['notEmpty'] }
                  selectOptions={ cityList }
                  onChange={ handleCityChange }
                  isDisabled={ !cityList?.length }
                  placeholder={ __('-- Please select city --') }
                />

                <Field
                  id={ FORM_IDS.DISTRICT }
                  block="SelectLocationPopup"
                  elem="District"
                  name={ FORM_IDS.DISTRICT }
                  type="select"
                  label={ __('Barangay') }
                  value={ hasLocationData
                      ? hasLocationData.SelectionLocationDistrict.value
                      : [] }
                  validation={ ['notEmpty'] }
                  selectOptions={ districtList }
                  isDisabled={ !districtList?.length }
                  placeholder={ __('-- Please select barangay --') }
                />
                { this.renderButton() }

            </Form>
        );
    }

    renderContent() {
        const { isLoading } = this.props;

        return (
            <div block="SelectLocationPopup" elem="wrapper">
                <Loader isLoading={ isLoading } />
                { this.renderHeader() }
                { this.renderForm() }
            </div>
        );
    }

    render() {
        return (
            <Popup
              id={ SELECT_LOCATION_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'SelectLocationPopup' } }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default SelectLocationPopupComponent;
