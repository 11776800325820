import PropTypes from 'prop-types';

export const flashSaleType = PropTypes.shape({
    deal_status: PropTypes.string.isRequired,
    deal_start_at: PropTypes.string.isRequired,
    deal_end_at: PropTypes.string.isRequired,
    deal_qty_left: PropTypes.string.isRequired,
    isHomePageWidget: PropTypes.bool.isRequired
});

export const flashSaleRemainingTimeType = PropTypes.shape({
    totalSecondDiff: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    days: PropTypes.number.isRequired
});

export const flashSaleProductFields = PropTypes.shape({
    product_sku: PropTypes.string,
    product_name: PropTypes.string,
    product_price: PropTypes.number,
    product_url: PropTypes.string,
    product_image_url: PropTypes.string,
    deal_percentage: PropTypes.number
});

export const flashSaleData = PropTypes.shape({
    product_deal_qty: PropTypes.number,
    deal_qty_left: PropTypes.number,
    product_flash_sale_price: PropTypes.number,
    product_price: PropTypes.number
});

export const flashSaleHomePageDataType = PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
        deal_title: PropTypes.string,
        deal_status: PropTypes.string,
        deal_start_at: PropTypes.string,
        deal_end_at: PropTypes.string,
        deal_discount_type: PropTypes.string,
        deal_discount_amount: PropTypes.number,
        created_at: PropTypes.string,
        total_deal_qty: PropTypes.string,
        total_sale_qty: PropTypes.string,
        deal_products: PropTypes.arrayOf(PropTypes.shape({
            flash_sale: PropTypes.shape({
                ...flashSaleData
            }),
            name: PropTypes.string,
            sku: PropTypes.string,
            price_range: PropTypes.shape({
                minimum_price: PropTypes.shape({
                    regular_price: PropTypes.shape({
                        value: PropTypes.number,
                        currency: PropTypes.string
                    })
                })
            })
        }))
    }))
});
