import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';
import history from 'Util/History';

import { LOCATION_PATH } from './Router.config';

/** @namespace AdmScandipwa/Component/Router/Container/RouterContainer */
export class RouterContainer extends SourceRouterContainer {
    /**
     * @extends to update current path as a body class
     */

    componentDidMount() {
        super.componentDidMount();
        this.updateCurrentPathAsBodyClass(history.location.pathname);
        history.listen(({ pathname = '' }) => {
            this.updateCurrentPathAsBodyClass(pathname);
        });
    }

    updateCurrentPathAsBodyClass(pathname) {
        const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        const currentPathAsAClassName = pathname.split('').map((char, key) => {
            if (!key && char === '/') {
                return '';
            } if (char === '/' || char === '.' || char === ' ' || format.test(char)) {
                return '-';
            }

            return char;
        }).join('');

        const sameAsCurrentBodyClass = document.querySelector(`body.${LOCATION_PATH}${currentPathAsAClassName}`);

        if (!sameAsCurrentBodyClass) {
            const locationSelector = `body[class*="${LOCATION_PATH}"]`;
            const matchingClassList = document.querySelector(locationSelector)?.classList;
            matchingClassList?.forEach((className) => {
                if (className.startsWith(LOCATION_PATH)) {
                    document.body.classList.remove(className);
                }
            });
            if (currentPathAsAClassName) {
                document.body.classList.add(`${LOCATION_PATH}${currentPathAsAClassName}`);
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
