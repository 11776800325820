import {
    AREA_FIELD, CHECKBOX, DROPDOWN, TEXT_FIELD
} from 'SourceComponent/ProductCustomizableOption/ProductCustomizableOption.config';

export const INPUT_FILE = 'file';
export const PRESCRIPTION_TITLE = 'Upload Doctor\'s Prescription';
export const ENABLE_DOCTOR_PRESCRIPTION = 'enable_doctor_prescription';

export {
    AREA_FIELD, CHECKBOX, DROPDOWN, TEXT_FIELD
};
