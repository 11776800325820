import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { OUT_OF_STOCK_ERROR } from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSeoCrawler } from 'Util/Bots';
import BrowserDatabase from 'Util/BrowserDatabase';
import { toCapitalize } from 'Util/Text/Text';

import SelectLocationPopup from './SelectLocationPopup.component';
import { LOCATION_DATA, SELECT_LOCATION_POPUP_ID } from './SelectLocationPopup.config';

export const LocationChangerDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "locationChanger" */ 'Store/LocationChanger/LocationChanger.dispatcher'
);

export const LogisticDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "logistic" */ 'Store/Logistic/Logistic.dispatcher'
);

/** @namespace AdmScandipwa/Component/SelectLocationPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace AdmScandipwa/Component/SelectLocationPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getRegions: () => LogisticDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getRegions(dispatch)
    ),
    getCities: (regionId) => LogisticDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getCities(regionId, dispatch)
    ),
    getDistricts: (cityId) => LogisticDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getDistricts(cityId, dispatch)
    ),
    hidePopup: () => dispatch(showPopup('', {})),
    locationChanger: (options) => LocationChangerDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setSelectedLocation(dispatch, options)
    ),
    showLocationPopup: (payload) => dispatch(showPopup(SELECT_LOCATION_POPUP_ID, payload)),
    showErrorNotification: (message) => dispatch(showNotification('error', message))

});

/** @namespace AdmScandipwa/Component/SelectLocationPopup/Container/SelectLocationPopupContainer */
export class SelectLocationPopupContainer extends PureComponent {
    static propTypes = {
        getRegions: PropTypes.func.isRequired,
        getCities: PropTypes.func.isRequired,
        getDistricts: PropTypes.func.isRequired,
        hidePopup: PropTypes.func.isRequired,
        showLocationPopup: PropTypes.func.isRequired,
        locationChanger: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired
    };

    state = {
        regionList: [],
        cityList: [],
        districtList: [],
        isLoading: true
    };

    containerFunctions = {
        handleRegionChange: this.handleRegionChange.bind(this),
        handleCityChange: this.handleCityChange.bind(this),
        onSubmitSuccess: this.onSubmitSuccess.bind(this)
    };

    componentDidMount() {
        const hasLocationData = BrowserDatabase.getItem(LOCATION_DATA);

        const { showLocationPopup } = this.props;

        if (!hasLocationData) {
            if (!isSeoCrawler()) {
                showLocationPopup();
            }
            this.getRegionsData();
        } else {
            const {
                selectedAllFields: {
                    regionList = [],
                    cityList = [],
                    districtList = []
                } = {}
            } = hasLocationData;

            this.setState({
                regionList,
                cityList,
                districtList,
                isLoading: false
            });
        }
    }

    handleCurrentLocation(hasLocationData) {
        this.handleRegionChange(hasLocationData.SelectionLocationRegion.value);
        this.handleCityChange(hasLocationData.SelectionLocationCity.value);
    }

    getRegionsData() {
        const { getRegions, showErrorNotification } = this.props;
        getRegions().then(
            /** @namespace AdmScandipwa/Component/SelectLocationPopup/Container/getRegions/then */
            ({ region = [] } = {}) => {
                const regionList = region.map(({ value, title }) => (
                    {
                        id: toCapitalize(value),
                        name: toCapitalize(title),
                        value: toCapitalize(value),
                        label: toCapitalize(title)
                    }
                ));

                if (!regionList.length) {
                    showErrorNotification(__('Unable to fetch location data'));
                }

                this.setState({
                    regionList,
                    isLoading: false
                });
            }
        );
    }

    handleCityChange(cityId) {
        if (cityId) {
            this.setState({ isLoading: true });
            const { getDistricts } = this.props;
            getDistricts(cityId).then(
                /** @namespace AdmScandipwa/Component/SelectLocationPopup/Container/getDistricts/then */
                ({ district }) => {
                    const districtList = district.map(({ value, title }) => (
                        {
                            id: toCapitalize(value),
                            name: toCapitalize(title),
                            value: toCapitalize(value),
                            label: toCapitalize(title)
                        }
                    ));

                    this.setState({
                        districtList
                    });
                }
            ).finally(() => {
                this.setState({
                    isLoading: false
                });
            });
        } else {
            this.setState({
                districtList: []
            });
        }
    }

    handleRegionChange(regionId) {
        if (regionId) {
            this.setState({ isLoading: true });
            const { getCities } = this.props;
            getCities(regionId).then(
                /** @namespace AdmScandipwa/Component/SelectLocationPopup/Container/getCities/then */
                ({ city }) => {
                    const cityList = city.map(({ id, name }) => (
                        {
                            id: toCapitalize(id),
                            name: toCapitalize(name),
                            value: toCapitalize(id),
                            label: toCapitalize(name)
                        }
                    ));

                    this.setState({
                        cityList,
                        districtList: []
                    });
                }
            ).finally(() => {
                this.setState({
                    isLoading: false
                });
            });
        } else {
            this.setState({
                cityList: []
            });
        }
    }

    onSubmitSuccess(fields) {
        const { regionList, cityList, districtList } = this.state;
        const selectedAllFields = { regionList, cityList, districtList };
        const enhancedFields = { ...fields, inText: '', selectedAllFields };
        const isLocationChanged = (districtList?.length <= 1 && cityList?.length <= 1);
        if (!isLocationChanged) {
            Object.keys(fields).forEach((field) => {
                const select = document.querySelector(`#${field}`);
                const { text } = select.options[select.selectedIndex];
                enhancedFields[field] = {
                    text,
                    value: enhancedFields[field]
                };
                enhancedFields.inText += `${text} `;
            });
            const { locationChanger } = this.props;
            BrowserDatabase.deleteItem(OUT_OF_STOCK_ERROR);
            BrowserDatabase.setItem(enhancedFields, LOCATION_DATA);
            locationChanger({ location: enhancedFields });
            this.setState({ isLoading: true });
            caches.delete('graphql');
        }
        window.location.reload();
    }

    render() {
        const {
            regionList, cityList, districtList, isLoading
        } = this.state;

        return (
            <SelectLocationPopup
              regionList={ regionList }
              cityList={ cityList }
              districtList={ districtList }
              isLoading={ isLoading }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocationPopupContainer);
