import '/home/magento/codepool/public_html/all-value-pwa-fe/node_modules/@scandipwa/scandipwa-extensibility/runtime-helpers';
import PropTypes from 'prop-types';
import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Style/main';

// noinspection JSConstantReassignment
PropTypes.string = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
]);

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

try {
    if (!window.sessionStorage.getItem('isAndroidApp')) {
        window.sessionStorage.setItem('isAndroidApp', String(window.location.href.includes('utm-android-app=true')));
    }
} catch (e) {
    // Do nothing
}

render(<App />, document.getElementById('root'));
