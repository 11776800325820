import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';

import './Menu.override.style.scss';

/** @namespace AdmScandipwa/Component/Menu/Component/MenuComponent */
export class MenuComponent extends SourceMenu {
    renderAdditionalInformation() {
        return null;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        this.scrollToTop();
    }
}

export default MenuComponent;
