import CmsBlock from 'SourceComponent/CmsBlock';
import { NoMatch as SourceNoMatch } from 'SourceRoute/NoMatch/NoMatch.component';

/** @namespace AdmScandipwa/Route/NoMatch/Component/NoMatchComponent */
export class NoMatchComponent extends SourceNoMatch {
    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <CmsBlock identifier="kemanapwa_no_match" />
            </main>
        );
    }
}

export default NoMatchComponent;
