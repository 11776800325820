import CategoryPaginationLink from 'Component/CategoryPaginationLink';
import { CategoryPagination as SourceCategoryPagination }
    from 'SourceComponent/CategoryPagination/CategoryPagination.component.js';

/** @namespace AdmScandipwa/Component/CategoryPagination/Component/CategoryPaginationComponent */
export class CategoryPaginationComponent extends SourceCategoryPagination {
    renderPageLink(
        pageNumber,
        label,
        children,
        isCurrent = false
    ) {
        const {
            pathname,
            onPageSelect,
            getSearchQuery,
            isTrackingQueryParam = true
        } = this.props;

        return (
            <li
              key={ pageNumber }
              block="CategoryPagination"
              elem="ListItem"
            >
                <CategoryPaginationLink
                  isTrackingQueryParam={ isTrackingQueryParam }
                  label={ label }
                  url_path={ pathname }
                  getPage={ onPageSelect }
                  isCurrent={ isCurrent }
                  pageNumber={ pageNumber }
                  getSearchQueryForPage={ getSearchQuery }
                >
                    { children }
                </CategoryPaginationLink>
            </li>
        );
    }

    renderPageLinks() {
        const {
            totalPages,
            paginationFrame,
            paginationFrameSkip,
            currentPage
        } = this.props;

        let pages = [];
        let i;

        // Render next pagination links
        for (i = currentPage; i <= (currentPage + paginationFrame - 1); i++) {
            if (i <= totalPages && pages.length <= paginationFrameSkip) {
                pages.push(this.renderPageLink(
                    i,
                    __('Page %s', i),
                    i.toString(),
                    i === currentPage
                ));
            }
        }

        // Render previous pagination links if necessary
        for (i = 1; i < currentPage; i++) {
            if (pages.length < paginationFrame) {
                const id = currentPage - i;
                const pageData = this.renderPageLink(
                    id,
                    __('Page %s', id),
                    id.toString()
                );

                pages = [pageData, ...pages];
            }
        }

        // Edge case for rendering correct count of next links when current page is 1
        if (currentPage === 1 && pages.length < totalPages) {
            for (i = pages.length + 1; i <= paginationFrame; i++) {
                pages.push(this.renderPageLink(
                    i,
                    __('Page %s', i),
                    i.toString()
                ));
            }
        }

        return pages;
    }
}

export default CategoryPaginationComponent;
