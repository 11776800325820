import SourceCategoryPaginationLink from 'SourceComponent/CategoryPaginationLink/CategoryPaginationLink.component';

/** @namespace AdmScandipwa/Component/CategoryPaginationLink/Component/CategoryPaginationLinkComponent */
export class CategoryPaginationLinkComponent extends SourceCategoryPaginationLink {
    render() {
        const {
            isTrackingQueryParam, children, label, isCurrent
        } = this.props;

        if (!isTrackingQueryParam) {
            return (
                <button
                  aria-label={ label }
                  block="CategoryPaginationLink"
                  mods={ { isCurrent } }
                  aria-current={ isCurrent ? 'page' : 'false' }
                  onClick={ this.getPage }
                >
                    { children }
                </button>
            );
        }

        return super.render();
    }
}

export default CategoryPaginationLinkComponent;
