import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace AdmScandipwa/Component/FieldSelect/Container/FieldSelectContainer */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    sortSelectOptions() {
        const { selectOptions, sortField, skipSorting } = this.props;
        const hasSortField = sortField && selectOptions[0] && selectOptions[0][sortField] !== undefined;
        if (skipSorting) {
            return selectOptions;
        }
        const sortedOptions = selectOptions.reduce(
            (acc, a) => (a.label ? [...acc, a] : acc), []
        ).sort((a, b) => {
            const valueA = hasSortField ? a[sortField] : a.label.toUpperCase();
            const valueB = hasSortField ? a[sortField] : b.label.toUpperCase();
            // eslint-disable-next-line no-nested-ternary
            return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
        });

        return sortedOptions;
    }
}

export default FieldSelectContainer;
