/* eslint-disable max-lines */
import { FORM_IDS, LOCATION_DATA } from 'Component/SelectLocationPopup/SelectLocationPopup.config';
import CartQuery from 'Query/Cart.query';
import { CartDispatcher as SourceCartDispatcher } from 'SourceStore/Cart/Cart.dispatcher';
import { setCartLoadingState, updateTotals } from 'Store/Cart/Cart.action';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateOutOfStockMessageAction } from 'Store/OutOfStockMessage/OutOfStockMessage.action';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import {
    getFlashSaleCustomerCartQuantityLimit,
    isRunningFlashSale
} from 'Util/FlashSale';
import { addToCartGTMEvent, removeFromCartGTMEvent } from 'Util/GTM/GTM';
import { getExtensionAttributes } from 'Util/Product';
import { fetchMutation, fetchQuery } from 'Util/Request';

export const OUT_OF_STOCK_ERROR = 'outOfStockError';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

export const UpdateOnlineCustomerDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/updateOnlineCustomer/updateOnlineCustomer.dispatcher'
);

/** @namespace AdmScandipwa/Store/Cart/Dispatcher/CartDispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    getLatestCart(dispatch) {
        const query = CartQuery.getCartQuery(getGuestQuoteId());
        return fetchQuery(query).then(
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchQuery/then */
            (cart) => {
                const { cartData } = cart;
                this._updateOutOfStockData(cartData, dispatch);
                return cart;
            },
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchQuery/then */
            () => this.onError(dispatch)
        );
    }

    getExistingQuantityFromCart(sku) {
        const { items: cartItems = [] } = BrowserDatabase.getItem(CART_TOTALS) || { items: [] };

        const { qty: productQuantityInCart } = cartItems
            .find(({ sku: cartItemSku }) => cartItemSku === sku) || { qty: 0 };

        return productQuantityInCart;
    }

    verifyFlashSaleQuantity({
        product, quantity, dispatch, sku, isUpdate
    }) {
        const isFlashSaleRunning = isRunningFlashSale(product);
        if (isFlashSaleRunning) {
            const flashSaleCustomerCartQuantityLimit = getFlashSaleCustomerCartQuantityLimit();
            const existingProductQuantity = isUpdate ? 0 : this.getExistingQuantityFromCart(product.sku || sku);
            const totalQuantity = quantity + existingProductQuantity;

            if (totalQuantity > flashSaleCustomerCartQuantityLimit) {
                dispatch(showNotification('error',
                    __('Can not add quantity more than user flash sale limit.')));

                return false;
            }
        }

        return true;
    }

    async addProductToCart(dispatch, options) {
        const guestQuoteId = getGuestQuoteId();
        const {
            product,
            quantity,
            currencyCode,
            productOptionsData
        } = options;
        const flashSaleQuantityVerificationState = this.verifyFlashSaleQuantity({ ...options, dispatch });

        if (!flashSaleQuantityVerificationState) {
            return Promise.reject();
        }

        const {
            sku,
            type_id: product_type
        } = product;

        const {
            productOptions,
            productOptionsMulti,
            downloadableLinks
        } = productOptionsData || {};

        const productToAdd = {
            sku,
            product_type,
            quantity,
            product_option: {
                extension_attributes: getExtensionAttributes(
                    {
                        ...product,
                        productOptions,
                        productOptionsMulti,
                        downloadableLinks
                    }
                )
            }
        };

        if (!guestQuoteId) {
            await this.createGuestEmptyCart(dispatch);
        }

        if (this._canBeAdded(options)) {
            try {
                const { saveCartItem: { cartData } } = await fetchMutation(CartQuery.getSaveCartItemMutation(
                    productToAdd, !isSignedIn() && getGuestQuoteId()
                ));

                addToCartGTMEvent({
                    product,
                    quantity,
                    currencyCode,
                    sku,
                    price: (cartData.items.find((record) => record.sku === sku) || {}).price
                });

                const AuthToken = getAuthorizationToken();

                const userOnlineData = {
                    customer_token: AuthToken,
                    cart_id: guestQuoteId
                };

                UpdateOnlineCustomerDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.updateOnlineCustomer(userOnlineData)
                );

                return this._updateCartData(cartData, dispatch);
            } catch ([{ message }]) {
                dispatch(showNotification('error', message));
                return Promise.reject();
            }
        }

        return Promise.reject();
    }

    changeItemQty(dispatch, options, tries = 0) {
        const {
            item_id, quantity, sku, product, currencyCode, oldQuantity = 0
        } = options;

        if (oldQuantity < quantity) {
            const flashSaleQuantityVerificationState = this.verifyFlashSaleQuantity({
                ...options, dispatch, isUpdate: true
            });

            if (!flashSaleQuantityVerificationState) {
                return Promise.reject();
            }
        }
        if (tries > 2) {
            dispatch(showNotification('error', __('Internal server error. Can not add to cart.')));
            return Promise.reject();
        }

        return fetchMutation(CartQuery.getSaveCartItemMutation(
            { sku, item_id, quantity },
            !isSignedIn() && getGuestQuoteId()
        )).then(
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchMutation/then */
            ({ saveCartItem: { cartData } }) => {
                this._updateCartData(cartData, dispatch);
                if (quantity > oldQuantity) {
                    addToCartGTMEvent({
                        product,
                        quantity,
                        currencyCode,
                        sku,
                        price: (cartData.items.find((record) => record.sku === sku) || {}).price
                    });
                } else {
                    removeFromCartGTMEvent({
                        product,
                        quantity,
                        currencyCode,
                        sku,
                        price: (cartData.items.find((record) => record.sku === sku) || {}).price
                    });
                }
            },
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchMutation/then */
            (error) => {
                const [{ debugMessage = '' }] = error || [{}];

                if (debugMessage.match('No such entity with cartId ')) {
                    return this._createEmptyCart(dispatch).then(
                        /** @namespace AdmScandipwa/Store/Cart/Dispatcher/_createEmptyCart/then */
                        (data) => {
                            setGuestQuoteId(data);
                            this._updateCartData({}, dispatch);
                            return this.changeItemQty(dispatch, options, tries + 1);
                        }
                    );
                }

                dispatch(showNotification('error', error[0].message));
                return Promise.reject();
            }
        );
    }

    removeProductFromCart(dispatch, options) {
        const {
            item_id, product, currencyCode, quantity, sku, price
        } = options;

        return fetchMutation(CartQuery.getRemoveCartItemMutation(
            item_id,
            !isSignedIn() && getGuestQuoteId()
        )).then(
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchMutation/then */
            ({ removeCartItem: { cartData } }) => {
                this._updateCartData(cartData, dispatch);
                removeFromCartGTMEvent({
                    product,
                    quantity,
                    currencyCode,
                    sku,
                    price
                });

                return cartData;
            },
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(showNotification('error', error[0].message));
                return null;
            }
        );
    }

    _updateCartData(cartData, dispatch) {
        dispatch(updateTotals(cartData));
        this._updateOutOfStockData(cartData, dispatch);
    }

    _updateOutOfStockData(cartData, dispatch) {
        const { [FORM_IDS.DISTRICT]: { value: districtId = false } = {} } = BrowserDatabase
            .getItem(LOCATION_DATA) || {};
        const { oos_error_message } = cartData;

        const {
            districtId: outOfStockDistrictId = false,
            active: outOfStockActive = false,
            message: outOfStockMessage = false
        } = BrowserDatabase
            .getItem(OUT_OF_STOCK_ERROR) || {};

        const active = outOfStockMessage !== oos_error_message
            || !(!outOfStockActive && outOfStockDistrictId === districtId);

        if (oos_error_message) {
            const data = { districtId, message: oos_error_message, active };
            dispatch(updateOutOfStockMessageAction(data));
        }
    }

    async fetchAndUpdateCart(dispatch) {
        await fetchQuery(CartQuery.getCartQuery(
            !isSignedIn() && getGuestQuoteId()
        )).then(
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchQuery/then */
            ({ cartData }) => {
                this._updateCartData(cartData, dispatch);
                return cartData;
            },
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchQuery/then */
            () => {
                this.onError(dispatch);
                this._updateCartData({}, dispatch);
                return {};
            }
        );
    }

    _syncCartWithBE(dispatch) {
        dispatch(setCartLoadingState(true));

        // Need to get current cart from BE, update cart
        fetchQuery(CartQuery.getCartQuery(
            !isSignedIn() && getGuestQuoteId()
        )).then(
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchQuery/then */
            (result) => this.handle_syncCartWithBESuccess(dispatch, result),
            /** @namespace AdmScandipwa/Store/Cart/Dispatcher/fetchQuery/then */
            (error) => this.handle_syncCartWithBEError(dispatch, error)
        ).finally(() => dispatch(setCartLoadingState(false)));
    }

    onError(dispatch, errorMessage) {
        dispatch(showNotification('error', errorMessage || __('Unable get latest cart data from Backend !')));
    }

    async addMultipleItemsToCart({ items, dispatch }) {
        const query = CartQuery.addItemsToCart(items);

        try {
            const { kmRecipeAddToCart: { messageFail, messageSuccess } } = await fetchMutation(query);
            const { cartData } = await this.getLatestCart(dispatch);
            this._updateCartData(cartData, dispatch);
            return { messageSuccess, messageFail };
        } catch (error) {
            const [{ message } = {}] = Array.isArray(error) ? error : [];
            this.onError(dispatch, message);
            throw error;
        }
    }
}

export default new CartDispatcher();
