import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    CategoryPaginationContainer as SourceCategoryPaginationContainer,
    mapDispatchToProps as sourceMapDispatchToProps, mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CategoryPagination/CategoryPagination.container';

export const LinkHeaderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkHeader/LinkHeader.dispatcher'
);
/** @namespace AdmScandipwa/Component/CategoryPagination/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setLinkHeaderPrv: (headerLinkNext) => LinkHeaderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setLinkHeaderPrv(dispatch, headerLinkNext)
    ),
    setLinkHeaderNext: (headerLinkPrv) => LinkHeaderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setLinkHeaderNext(dispatch, headerLinkPrv)
    )
});

/** @namespace AdmScandipwa/Component/CategoryPagination/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    baseUrl: state.ConfigReducer.base_url
});
/** @namespace AdmScandipwa/Component/CategoryPagination/Container/CategoryPaginationContainer */
export class CategoryPaginationContainer extends SourceCategoryPaginationContainer {
    static propTypes = {
        ...this.propTypes,
        pageLinkNext: PropTypes.func.isRequired,
        pageLinkPrv: PropTypes.func.isRequired,
        baseUrl: PropTypes.string.isRequired

    };

    static defaultProps = {
        isLoading: false,
        isTrackingQueryParam: true,
        onPageSelect: () => {}
    };

    componentWillUnmount() {
        const { setLinkHeaderNext, setLinkHeaderPrv } = this.props;
        setLinkHeaderPrv('');
        setLinkHeaderNext('');
    }

    _getCurrentPage() {
        const {
            isTrackingQueryParam,
            currentPage,
            location: { pathname },
            setLinkHeaderNext,
            setLinkHeaderPrv,
            totalPages,
            baseUrl
        } = this.props;

        if (!isTrackingQueryParam) {
            return currentPage;
        }
        const linkPage = super._getCurrentPage();
        const path = pathname;
        if (path.charAt(0) === '/') {
            const modifiedPath = path.substr(1);

            if (linkPage < totalPages) {
                const headerLinkNext = `${baseUrl}${modifiedPath}?page=${linkPage + 1}`;
                setLinkHeaderNext(headerLinkNext);
            } if (linkPage !== 1) {
                const headerLinkPrv = `${baseUrl}${modifiedPath}?page=${linkPage - 1}`;
                setLinkHeaderPrv(headerLinkPrv);
            }
            if (linkPage === 1) {
                setLinkHeaderPrv('');
            }
            if (linkPage === totalPages) {
                setLinkHeaderNext('');
            }
        }

        return linkPage;
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CategoryPaginationContainer)
);
