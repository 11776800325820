import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { OVERLAY_PLACEHOLDER } from 'Component/PopupSuspense/PopupSuspense.config';
import {
    CHANGED_LOCATION,
    SELECT_LOCATION_POPUP_ID
} from 'Component/SelectLocationPopup/SelectLocationPopup.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Header/Header.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import {
    CART,
    CART_OVERLAY
} from './Header.config';

/** @namespace AdmScandipwa/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
    selectedLocation: state.LocationChangerReducer,
    currentStep: state.CurrentStepReducer.step
});

/** @namespace AdmScandipwa/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showLocationPopup: (payload) => dispatch(showPopup(SELECT_LOCATION_POPUP_ID, payload)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace AdmScandipwa/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends SourceHeaderContainer {
    componentDidMount() {
        const { showNotification } = this.props;
        const location = BrowserDatabase.getItem(CHANGED_LOCATION);
        history.listen((history) => this.setState(this.onRouteChanged(history)));
        if (location) {
            BrowserDatabase.deleteItem(CHANGED_LOCATION);
            showNotification('info', __('Location has been updated to %s', location));
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        showLocationPopup: this.props.showLocationPopup
    };

    onClearButtonClick(e) {
        const { navigationState: { onCloseClick } } = this.props;

        if (onCloseClick) {
            onCloseClick(e);
        }

        super.onClearButtonClick(e);
    }

    onMyAccountOutsideClick() {
        const { activeOverlay } = this.props;
        if (activeOverlay === OVERLAY_PLACEHOLDER) {
            return;
        }
        super.onMyAccountOutsideClick();
    }

    onMinicartButtonClick() {
        const {
            showOverlay,
            device
        } = this.props;

        if (!device.isMobile) {
            this.setState({ shouldRenderCartOverlay: true });

            showOverlay(CART_OVERLAY);
            return;
        }

        history.push(appendWithStoreCode(`/${ CART }`));
    }

    containerProps = () => {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            isLoading,
            isSignedIn,
            device,
            CheckoutStatus,
            PromptInstall,
            selectedLocation,
            currentStep
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);
        const isSuccess = pathname.includes('/checkout/success');

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            isSignedIn,
            header_logo_src,
            logo_alt,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            isSuccess,
            showMyAccountLogin,
            device,
            CheckoutStatus,
            PromptInstall,
            selectedLocation,
            currentStep
        };
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
