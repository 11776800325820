import { InstallPromptAndroid as SourceInstallPromptAndroid }
    from 'SourceComponent/InstallPromptAndroid/InstallPromptAndroid.component';

/** @namespace AdmScandipwa/Component/InstallPromptAndroid/Component/InstallPromptAndroidComponent */
export class InstallPromptAndroidComponent extends SourceInstallPromptAndroid {
    renderHeading() {
        return (
            <p block="InstallPromptAndroid" elem="Heading">
                { __('Full-Screen Mode') }
            </p>
        );
    }

    renderContent() {
        return (
            <p block="InstallPromptAndroid" elem="Content">
                { __('Add full-screen website in your phone') }
            </p>
        );
    }

    renderInstallButton() {
        const { handleAppInstall } = this.props;

        return (
            <button
              block="InstallPromptAndroid"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ handleAppInstall }
            >
                { __('Add Now') }
            </button>
        );
    }

    render() {
        return (
            <div block="InstallPromptAndroid">
                { this.renderCloseButton() }
                <div block="InstallPromptAndroid" elem="Left">
                    { this.renderHeading() }
                    { this.renderContent() }
                </div>
                { this.renderInstallButton() }
            </div>
        );
    }
}

export default InstallPromptAndroidComponent;
