import ProductListQuery from 'Query/ProductList.query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { isFlashSaleEnabled } from 'Util/FlashSale';
import { Field } from 'Util/Query';
/** @namespace AdmScandipwa/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    /**
     * @extends to change getCartForCustomer into km_getCartForCustomer
     */

    getCartQuery(quoteId) {
        const query = new Field('km_getCartForCustomer')
            .addFieldList(this._getCartTotalsFields())
            .setAlias('cartData');

        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', quoteId);
        }

        return query;
    }

    _getCouponCodesField() {
        return new Field('coupon_codes')
            .addFieldList(['key', 'code']);
    }

    _getCartTotalsFields() {
        return [
            ...super._getCartTotalsFields(),
            this._getCouponCodesField(),
            this._getCheckBrandPromotionsField(),
            'additional_fee',
            'additional_fee_title',
            'oos_error_message',
            'shipping_amount_discount_label',
            'shipping_amount_discount_amount'
        ];
    }

    _getCheckBrandPromotionsField() {
        return new Field('check_brand_promotions')
            .addFieldList(this._getCheckBrandPromotionsFields());
    }

    _getCheckBrandPromotionsFields() {
        return ['message_with_data'];
    }

    getRemoveCouponMutation(quoteId, couponCode) {
        const mutation = new Field('removeCoupon')
            .addField(this.getCartQuery(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }
        mutation.addArgument('couponCode', 'String', couponCode);

        return mutation;
    }

    getUpdateAllRewardCardQuery({ cardNumber, guestCardId }) {
        return new Field('km_saveAllRewardsNo')
            .addArgument('cardNo', 'String!', cardNumber)
            .addArgument('guestCartId', 'String!', !isSignedIn() ? guestCardId : '')
            .addFieldList(['card_no', 'is_valid']);
    }

    _getProductField() {
        ProductListQuery.options.isForLinkedProducts = true;

        const productQuery = new Field('product')
            .addFieldList(ProductListQuery._getProductInterfaceFields(false, true, true));

        ProductListQuery.options.isForLinkedProducts = false;

        if (isFlashSaleEnabled()) {
            productQuery.addField(this._getFlashSaleField());
        }

        return productQuery;
    }

    _getFlashSaleField() {
        return new Field('flash_sale')
            .addFieldList([
                'deal_status',
                'deal_start_at',
                'deal_end_at',
                'deal_qty_left'
            ]);
    }

    addItemsToCart(items) {
        const guestCartId = !isSignedIn() ? getGuestQuoteId() : '';
        return new Field('kmRecipeAddToCart')
            .addArgument('product_sku', '[ItemsSku!]!', items)
            .addArgument('guestCartId', 'String!', guestCartId)
            .addFieldList(['messageSuccess', 'messageFail']);
    }

    getSaveCartItemMutation(product, quoteId) {
        const mutation = new Field('saveCartItem')
            .addArgument('cartItem', 'CartItemInput!', product)
            .addFieldList(this._getSaveCartItemFields(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }
}

export default new CartQuery();
