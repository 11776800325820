import { PRODUCT_OUT_OF_STOCK } from 'Component/CartItem/CartItem.config';
import {
    INPUT_FILE
} from 'Component/ProductCustomizableOption/ProductCustomizableOption.config';
import { DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX, PERCENT_STATIC } from 'Component/ProductPrice/ProductPrice.config';
import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';
import {
    checkEveryOption,
    getExtensionAttributes,
    getIndexedAttributeOption,
    getIndexedAttributes,
    getIndexedReviews,
    getVariantIndex,
    getVariantsIndexes
} from 'SourceUtil/Product/Product';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getFlashSalePrice } from 'Util/FlashSale';
import { getFinalPrice } from 'Util/Price/Price';
import { CONFIGURABLE } from 'Util/Product';

export * from 'SourceUtil/Product/Product';

/** @namespace AdmScandipwa/Util/Product/_getAttributeSortOrder */
export const _getAttributeSortOrder = (attribute_code, initialAttributes) => {
    const initialAttribute = initialAttributes.find((record) => attribute_code === record.attribute_code);
    if (!initialAttribute) {
        return null;
    }

    return initialAttribute.attribute_options.map((record) => record.value);
};

/** @namespace AdmScandipwa/Util/Product/getIndexedConfigurableOptions */
export const getIndexedConfigurableOptions = (configurableOptions, indexedAttributes, initialAttributes) => (
    configurableOptions.reduce((indexedConfigurableOptions, configurableOption) => {
        const { values, attribute_code } = configurableOption;

        return {
            ...indexedConfigurableOptions,
            [attribute_code]: {
                ...configurableOption,
                attribute_sort_order: _getAttributeSortOrder(attribute_code, initialAttributes),
                ...indexedAttributes[attribute_code],
                attribute_values: values.map(({ value_index }) => `${ value_index }`)
            }
        };
    }, {})
);

/** @namespace AdmScandipwa/Util/Product/getIndexedCustomOption */
export const getIndexedCustomOption = (option) => {
    const {
        checkboxValues,
        dropdownValues,
        fieldValues,
        areaValues,
        type,
        ...otherFields
    } = option;

    if (checkboxValues) {
        return { type: 'checkbox', data: checkboxValues, ...otherFields };
    }

    if (dropdownValues) {
        return { type: 'dropdown', data: dropdownValues, ...otherFields };
    }

    if (fieldValues) {
        return { type: 'field', data: fieldValues, ...otherFields };
    }

    if (areaValues) {
        return { type: 'area', data: areaValues, ...otherFields };
    }

    if (type === INPUT_FILE) {
        return { type: 'file', data: fieldValues, ...otherFields };
    }

    // skip unsupported types
    return null;
};

/** @namespace AdmScandipwa/Util/Product/getIndexedCustomOptions */
export const getIndexedCustomOptions = (options) => options.reduce(
    (acc, option) => {
        const indexedOption = getIndexedCustomOption(option);

        if (indexedOption) {
            acc.push(indexedOption);
        }

        return acc;
    },
    []
);

/** @namespace AdmScandipwa/Util/Product/getProductTaxConfig */
export const getProductTaxConfig = () => {
    const {
        storeConfig: {
            priceTaxDisplay: { product_price_display_type: productTaxConfig }
        }
    } = BrowserDatabase.getItem(STORE_CONFIG_KEY) || {
        storeConfig: { priceTaxDisplay: { product_price_display_type: DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX } }
    };

    return productTaxConfig;
};

/** @namespace AdmScandipwa/Util/Product/getPriceRangeWithDiscount */
export const getPriceRangeWithDiscount = (product) => {
    const { price_range } = product;

    if (!price_range || !price_range.minimum_price) {
        return price_range;
    }
    const updatedPriceRange = { ...price_range };
    const keyPrefix = getProductTaxConfig() === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX ? '_excl_tax' : '';

    const {
        minimum_price: {
            [`regular_price${keyPrefix}`]: { value: regularPrice }
        }
    } = price_range;

    const flashSalePrice = getFlashSalePrice(product);

    if (flashSalePrice) {
        updatedPriceRange.minimum_price.hasPriceDifference = true;
        updatedPriceRange.minimum_price.discount.percent_off = PERCENT_STATIC - (flashSalePrice * PERCENT_STATIC)
            / regularPrice;
    }

    return updatedPriceRange;
};

/** @namespace AdmScandipwa/Util/Product/getIndexedVariants */
export const getIndexedVariants = (variants) => variants.map(({ product }) => {
    const { attributes } = product;
    return {
        ...product,
        price_range: getPriceRangeWithDiscount(product),
        attributes: getIndexedAttributes(attributes || [])
    };
});

/** @namespace AdmScandipwa/Util/Product/getProductWithMinimumProductPrice */
export const getProductWithMinimumProductPrice = (variants = []) => variants
    .reduce((reducedValue, variant, index) => {
        const { product: productVariant } = variant;
        const variantPrice = getFinalPrice(({
            productProps: {
                displayTaxInPrice: getProductTaxConfig(),
                product: productVariant,
                price: productVariant.price_range
            }
        }));

        if (reducedValue.minimumPrice > variantPrice
            && productVariant.stock_status !== PRODUCT_OUT_OF_STOCK) {
            return { minimumIndex: index, minimumPrice: variantPrice };
        }

        return reducedValue;
    }, { minimumPrice: Infinity, minimumIndex: -1 }).minimumIndex;

/** @namespace AdmScandipwa/Util/Product/getIndexedProduct */
export const getIndexedProduct = (product) => {
    const {
        variants: initialVariants = [],
        configurable_options: initialConfigurableOptions = [],
        attributes: initialAttributes = [],
        options: initialOptions = [],
        rating_summary,
        review_count,
        reviews: initialReviews
    } = product;

    const attributes = getIndexedAttributes(initialAttributes || []);
    const reviews = getIndexedReviews(initialReviews);

    return {
        ...product,
        price_range: getPriceRangeWithDiscount(product),
        configurable_options: getIndexedConfigurableOptions(initialConfigurableOptions, attributes, initialAttributes),
        variants: getIndexedVariants(initialVariants || []),
        options: getIndexedCustomOptions(initialOptions || []),
        attributes,
        // Set the index for the variant with the minimum available price to use in future
        minimumVariantValueIndex: getProductWithMinimumProductPrice(initialVariants || []),
        // Magento 2.4.1 review endpoint compatibility
        reviews,
        review_summary: {
            rating_summary,
            review_count
        }
    };
};

/** @namespace AdmScandipwa/Util/Product/getIndexedProducts */
export const getIndexedProducts = (products) => products.map(getIndexedProduct);

/** @namespace AdmScandipwa/Util/Product/productHasStock */
export const productHasStock = (product) => {
    const {
        item: {
            sku,
            product: {
                is_salable, variants = [], type_id
            }
        }
    } = product;

    const { is_salable: variantIsSalable = false } = variants
        .find(({ sku: vSku }) => vSku === sku) || {};

    return !!(type_id === CONFIGURABLE && sku ? variantIsSalable : is_salable);
};

/** @namespace AdmScandipwa/Util/Product/getIndexedParameteredProducts */
export const getIndexedParameteredProducts = (products) => Object.entries(products)
    .reduce((products, [id, product]) => ({
        ...products,
        [id]: getIndexedProduct(product)
    }), {});

export {
    checkEveryOption,
    getIndexedAttributeOption,
    getIndexedAttributes,
    getVariantIndex,
    getVariantsIndexes,
    getIndexedReviews,
    getExtensionAttributes
};
