import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { FLASH_SALE_STATUSES } from 'Component/FlashSaleTimer/FlashSaleTimer.config';
import { ZERO_PERCENT } from 'Component/ProductSaleLabel/ProductSalesLable.config';
import { ProductType } from 'Type/ProductList';
import { getProductDiscountPercentage } from 'Util/Price';

import ProductSaleLabel from './ProductSaleLabel.component';

/** @namespace AdmScandipwa/Component/ProductSaleLabel/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isShowSaleLabel: state.ConfigReducer?.labelConfig?.saleLabelBasedOnSpecPrice === 'true'
        && state.ConfigReducer?.labelConfig?.showSaleLabelDescPerc === 'true',
    displayPosition: state.ConfigReducer?.labelConfig?.displayPosition
});

/** @namespace AdmScandipwa/Component/ProductSaleLabel/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace AdmScandipwa/Component/ProductSaleLabel/Container/ProductSaleLabelContainer */
export class ProductSaleLabelContainer extends PureComponent {
    static propTypes = {
        isShowSaleLabel: PropTypes.bool.isRequired,
        displayPosition: PropTypes.string.isRequired,
        productOptionsData: PropTypes.string.isRequired,
        product: ProductType.isRequired,
        isPLP: PropTypes.bool.isRequired,
        configurableVariantIndex: PropTypes.number.isRequired
    };

    __isUpcomingDeal() {
        const {
            product: {
                flash_sale: { deal_status } = {}
            }
        } = this.props;

        return deal_status === FLASH_SALE_STATUSES.UPCOMING;
    }

    __isPDPBlock() {
        const { configurableVariantIndex } = this.props;

        return typeof configurableVariantIndex !== 'undefined';
    }

    getSaleLabelValue() {
        const {
            // eslint-disable-next-line no-unused-vars
            product: { variants, ...productWithoutVariant },
            product: productWithVariant,
            isPLP,
            configurableVariantIndex,
            productOptionsData
        } = this.props;

        // Ignore the variants if product is from the PLP
        const productFromProps = isPLP ? productWithoutVariant : productWithVariant;

        const product = (productFromProps.variants && (productFromProps.variants[configurableVariantIndex]
        )) || productFromProps;

        const discountPercentage = this.__isPDPBlock() && this.__isUpcomingDeal() ? 0
            : getProductDiscountPercentage({ product, productOptionsData });

        return typeof discountPercentage === 'string'
            ? parseFloat(discountPercentage).toFixed() : discountPercentage.toFixed();
    }

    render() {
        const { product, isShowSaleLabel, displayPosition } = this.props;
        if (!product || !product.id || !isShowSaleLabel) {
            return null;
        }

        const labelValue = this.getSaleLabelValue();
        if (!labelValue || labelValue === ZERO_PERCENT) {
            return null;
        }

        return (
            <ProductSaleLabel
              labelValue={ labelValue }
              displayPosition={ displayPosition }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSaleLabelContainer);
