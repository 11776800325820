import { connect } from 'react-redux';

import { CATEGORY_STEP } from 'Route/CategoryPage/CategoryPage.config';
import { PRODUCT_STEP } from 'Route/ProductPage/ProductPage.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';
import media from 'Util/Media';

import Meta from './Meta.component';
import { CURRENT_STEP_MAP, NOINDEX_FOLLOW, NOINDEX_NO_FOLLOW } from './Meta.config';

/** @namespace AdmScandipwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    metaHreflangs: state.MetaReducer.hreflangs,
    productHreflangs: state.ProductReducer?.product?.mw_hreflangs?.items,
    currentStep: state.CurrentStepReducer.step,
    categoryHreflangs: state.CategoryReducer?.category?.mw_hreflangs?.items || [],
    noIndexFollowPagesWithoutContent: state.ConfigReducer.mageworx_seo_base_robots_noindex_follow_pages,
    noIndexFollowUserPages: state.ConfigReducer.mageworx_seo_base_robots_noindex_follow_user_pages,
    noIndexNoFollowUserPages: state.ConfigReducer.mageworx_seo_base_robots_noindex_nofollow_user_pages,
    robotsAttributeSettings: state.ConfigReducer.mageworx_seo_base_robots_attribute_settings,
    customFilters: state.ProductListReducer.currentArgs?.filter?.customFilters,
    maxFiltersForNoIndexNoFollow: +state.ConfigReducer.mageworx_seo_base_robots_count_filters_for_noindex,
    layeredNavDefaultRobots: state.ConfigReducer.mageworx_seo_base_robots_category_ln_pages_robots,
    appLogoUrl: state.ConfigReducer.header_logo_src
});

/** @namespace AdmScandipwa/Component/Meta/Container/MetaContainer */
export class MetaContainer extends SourceMetaContainer {
    containerFunctions = {
        getCurrentHrefLangs: this.getCurrentHrefLangs.bind(this)
    };

    _getLogoUrl() {
        const { appLogoUrl } = this.props;
        return media(appLogoUrl, 'logo/');
    }

    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
            'og:title': 'AllDay Supermarket',
            'og:image': this._getLogoUrl()
        };

        return this._generateMetaFromMetadata(meta);
    }

    getCurrentHrefLangs() {
        const {
            metaHreflangs = [], productHreflangs = [], categoryHreflangs = [], currentStep
        } = this.props;

        if (currentStep === PRODUCT_STEP) {
            return productHreflangs || [];
        } if (currentStep === CATEGORY_STEP) {
            return categoryHreflangs || [];
        }

        return metaHreflangs || [];
    }

    checkForMatchingData(currentStepString, dataStr) {
        return dataStr.toLowerCase().split(/\r?\n/)
            .some((str) => (str.includes('*') ? currentStepString
                .includes(str.replace('*', '')) : currentStepString === str));
    }

    checkForMatchingAttributes(maxLimit, currentStep, robotsAttributeSettings, customFilters, layeredNavDefaultRobots) {
        const customFilterNames = Object.keys(customFilters);

        if (currentStep === CATEGORY_STEP && customFilterNames.length >= 1) {
            if (customFilterNames.length) {
                if (customFilterNames.length >= maxLimit) {
                    return NOINDEX_FOLLOW;
                }

                const settings = robotsAttributeSettings.split(/\r?\n/);
                const rule = settings.find((setting) => {
                    const [rule = '', attributes = ''] = setting.split(':');
                    if (attributes.includes('+')) {
                        const ruleAttributes = attributes.split('+');

                        const matchesAll = ruleAttributes.length === customFilterNames.length && ruleAttributes
                            .every((attribute) => customFilters[attribute]);

                        return matchesAll && rule;
                    } if (attributes) {
                        const ruleAttributes = attributes.split(',');

                        if (customFilterNames.length === 1) {
                            const matchAtLeastOne = customFilterNames.length && ruleAttributes
                                .some((attribute) => customFilters[attribute]);

                            return matchAtLeastOne && rule;
                        }

                        return false;
                    }

                    return false;
                });

                return rule || layeredNavDefaultRobots;
            }

            return layeredNavDefaultRobots;
        }

        return false;
    }

    _getRobots() {
        const {
            robots,
            currentStep,
            noIndexFollowPagesWithoutContent = '',
            noIndexFollowUserPages = '',
            noIndexNoFollowUserPages = '',
            customFilters = {},
            robotsAttributeSettings = '',
            maxFiltersForNoIndexNoFollow: maxLimit = 0,
            layeredNavDefaultRobots = null
        } = this.props;

        const currentStepString = CURRENT_STEP_MAP[currentStep] || currentStep;

        const ruleBasedOnAttributes = this
            .checkForMatchingAttributes(
                maxLimit, currentStep, robotsAttributeSettings, customFilters, layeredNavDefaultRobots
            );

        if (currentStepString) {
            // PLP Match attributes
            if (ruleBasedOnAttributes) {
                const [rule = false] = ruleBasedOnAttributes.split(':');
                return rule;
            }

            // noIndexNoFollowUserPages
            if (this.checkForMatchingData(currentStepString, noIndexNoFollowUserPages)) {
                return NOINDEX_NO_FOLLOW;
            }

            // noIndexFollowUserPages
            if (this.checkForMatchingData(currentStepString, noIndexFollowUserPages)) {
                return NOINDEX_FOLLOW;
            }

            // noIndexFollowPagesWithoutContent
            if (noIndexFollowPagesWithoutContent.includes(currentStepString)) {
                return NOINDEX_FOLLOW;
            }
        }

        return robots;
    }

    render() {
        return (
            <Meta
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerProps() }
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
