import { connect } from 'react-redux';

import {
    mapDispatchToProps, mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import { isUpcomingFlashSale } from 'Util/FlashSale';
import {
    getFormattedFinalPrice, getPriceWithOptions,
    getProductDiscountPercentage, getRoundedRegularPrice
} from 'Util/Price';
import { CONFIGURABLE, SIMPLE } from 'Util/Product/Types';

import ProductPrice from './ProductPrice.component';

/** @namespace AdmScandipwa/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {
    getPriceWithOptions(finalPrice) {
        return getPriceWithOptions({ finalPrice, productProps: this.props });
    }

    __getFlashUpdatedData(product) {
        if (isUpcomingFlashSale(product) && !this.__isPLPPriceBlock()) {
            return { product: { ...product, flash_sale: {} } };
        }

        return product;
    }

    __getProductWitVariant() {
        const {
            product: { variants = [], minimumVariantValueIndex, type_id = SIMPLE } = {},
            product,
            configurableVariantIndex
        } = this.props;

        // IF there is no variant selected then set the mininum value variant index
        if (variants?.length && (configurableVariantIndex === -1
                || configurableVariantIndex === null
                || configurableVariantIndex === undefined)) {
            return this.__getFlashUpdatedData(variants[minimumVariantValueIndex || 0]);
        }

        if (variants?.length && type_id === CONFIGURABLE && variants[configurableVariantIndex]) {
            return this.__getFlashUpdatedData(variants[configurableVariantIndex]);
        }

        return this.__getFlashUpdatedData(product);
    }

    __isPLPPriceBlock() {
        const { isPLP } = this.props;

        return isPLP;
    }

    __getPriceRangeWithFlashSale(product) {
        const { price } = this.props;

        const { minimum_price = {} } = price;

        if (isUpcomingFlashSale(product) && !this.__isPLPPriceBlock()) {
            return {
                ...price, minimum_price: { ...minimum_price, discount: { percent_off: 0 } }
            };
        }

        return price;
    }

    __getProductProps() {
        const product = this.__getProductWitVariant();
        return {
            productProps: {
                ...this.props,
                product,
                price: this.__getPriceRangeWithFlashSale(product)
            }
        };
    }

    getFormattedFinalPrice() {
        return getFormattedFinalPrice(this.__getProductProps());
    }

    getRoundedRegularPrice() {
        return getRoundedRegularPrice(this.__getProductProps());
    }

    render() {
        return (
            <ProductPrice
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerProps() }
              discountPercentage={ getProductDiscountPercentage(this.__getProductProps()) }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
