import PropTypes from 'prop-types';
import { lazy } from 'react';

import { WidgetFactory as SourceWidgetFactory }
    from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    CATALOG_PRODUCT_CAROUSAL_LIST,
    CATALOG_PRODUCT_LIST,
    NEW_PRODUCTS,
    RECENTLY_VIEWED,
    SLIDER
} from './WidgetFactory.config';

import './WidgetFactory.style';

// eslint-disable-next-line max-len
export const ProductListWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/ProductListWidget'));
// eslint-disable-next-line max-len
export const NewProducts = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/NewProducts'));
// eslint-disable-next-line max-len
export const HomeSlider = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/SliderWidget'));
// eslint-disable-next-line max-len
export const RecentlyViewedWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/RecentlyViewedWidget'));
// eslint-disable-next-line max-len
export const CatalogProductCarousalList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/CatalogProductCarousalList'));

/** @namespace AdmScandipwa/Component/WidgetFactory/Component/WidgetFactoryComponent */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    static propTypes = {
        type: PropTypes.string.isRequired
    };

    renderMap = {
        [SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback
        },
        [NEW_PRODUCTS]: {
            component: NewProducts
        },
        [CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget
        },
        [RECENTLY_VIEWED]: {
            component: RecentlyViewedWidget
        },
        [CATALOG_PRODUCT_CAROUSAL_LIST]: {
            component: CatalogProductCarousalList
        }
    };
}

export default WidgetFactoryComponent;
