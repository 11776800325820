import PropTypes from 'prop-types';

import FieldDate from 'Component/FieldDate';
import FieldFile from 'Component/FieldFile';
import FieldInput from 'Component/FieldInput/FieldInput.component';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';

import {
    CHECKBOX_TYPE, DATE_TYPE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXTAREA_TYPE
} from './Field.config';

/** @namespace AdmScandipwa/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceField {
    /**
     * extended to change validationStatus prop type
     * @extends
     */

    static propTypes = {
        ...SourceField.propTypes,
        validationStatus: PropTypes.bool
    };

    /**
     * isDisabled modifier added
     * @override
     */

    renderLabel() {
        const {
            id,
            label,
            validation,
            isDisabled,
            type
        } = this.props;
        const isRequired = validation.includes('notEmpty');
        const noRenderLabel = type === CHECKBOX_TYPE || type === RADIO_TYPE;

        if (!label || noRenderLabel) {
            return null;
        }

        return (
            <label
              block="Field"
              elem="Label"
              mods={ { isRequired, isDisabled } }
              htmlFor={ id }
            >
                { label }
            </label>
        );
    }

    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case FILE_TYPE:
            return this.renderTypeFile();
        case DATE_TYPE:
            return this.renderDateType();
        default:
            return this.renderTypeText();
        }
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            incrementValue = 1,
            onKeyEnterDown,
            handleChange
        } = this.props;

        return (
            <>
                <FieldInput
                  /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                  { ...this.props }
                  type="number"
                  readOnly
                    // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                  aria-label={ __('Value') }
                />
                <button
                  disabled={ +value === max }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + incrementValue) }
                  aria-label={ __('Add') }
                />
                <button
                  disabled={ +value === min }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - incrementValue) }
                  aria-label={ __('Subtract') }
                />
            </>
        );
    }

    renderDateType() {
        return (
            <FieldDate
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              type="text"
            />
        );
    }

    renderTypeFile() {
        return (
            <FieldFile
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              type="file"
            />
        );
    }

    /**
     * isCompanionField Mod added
     * @extends
     */

    render() {
        const {
            mix,
            type,
            message,
            validationStatus,
            isCompanionField
        } = this.props;

        return (
            <div
              block="Field"
              mods={ {
                  type,
                  hasError: validationStatus === false || !!message,
                  isValid: validationStatus === true,
                  isCompanionField: !!isCompanionField
              } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }
}

export default FieldComponent;
