import BrowserDatabase from 'Util/BrowserDatabase';
import getStore from 'Util/Store';

export * from 'SourceUtil/Auth/Token';

export const AUTH_TOKEN = 'auth_token';

export const TWENTY_FOUR_HOUR = 86400;

/** @namespace AdmScandipwa/Util/Auth/Token/setAuthorizationToken */
export const setAuthorizationToken = (token) => {
    const state = getStore().getState();
    const {
        cookie_lifetime = TWENTY_FOUR_HOUR
    } = state.ConfigReducer;

    BrowserDatabase.setItem(token, AUTH_TOKEN, cookie_lifetime);
};
