import Field from 'Component/Field';
import { BRAND } from 'Component/ProductAttributeValue/ProductAttributeValue.config';
import { ProductAttributeValue as SourceProductAttributeValue } from
    'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

/** @namespace AdmScandipwa/Component/ProductAttributeValue/Component/ProductAttributeValueComponent */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    renderBrand(value, label) {
        return (
            <div>{ label || value || __('N/A') }</div>
        );
    }

    renderDropdown(value) {
        const { attribute: { attribute_code }, isSelected } = this.props;
        const newLabel = attribute_code === BRAND ? value.toLowerCase() : value;
        const applyTextTransform = attribute_code === BRAND;

        return (
            <Field
              id={ value }
              name={ value }
              type="checkbox"
              label={ newLabel }
              value={ value }
              mix={ {
                  block: 'ProductAttributeValue',
                  elem: 'Text',
                  mods: { isSelected, applyTextTransform }
              } }
              checked={ isSelected }
            />
        );
    }

    renderAttributeByType() {
        const { attribute: { attribute_type } } = this.props;

        switch (attribute_type) {
        case 'select':
            return this.renderSelectAttribute();
        case 'boolean':
            return this.renderBooleanAttribute();
        case 'text':
            return this.renderTextAttribute();
        case 'weight':
            return this.renderWeightAttribute();
        case 'multiselect':
            return this.renderMultiSelectAttribute();
        case 'media_image':
            return this.renderImageAttribute();
        case 'textarea':
            return this.renderTextAreaAttribute();
        default:
            return this.renderPlaceholder();
        }
    }

    renderWeightAttribute() {
        const { attribute: { attribute_value }, weightUnit } = this.props;
        const converted_attribute_value = `${parseFloat(attribute_value).toFixed(2) } ${weightUnit}`;
        return this.renderStringValue(converted_attribute_value);
    }

    /**
     * @override to add brand Schema
     */

    renderStringValue(value, label) {
        const { isFormattedAsText, isSelected, attribute: { attribute_code } } = this.props;
        const isSwatch = label;

        if (isFormattedAsText) {
            return attribute_code === 'brand' ? this.renderBrand(value, label) : label || value || __('N/A');
        }

        if (!isSwatch) {
            return this.renderDropdown(value);
        }

        return (
                <span
                  block="ProductAttributeValue"
                  elem="String"
                  mods={ { isSelected } }
                  title={ label }
                >
                    { value }
                </span>
        );
    }
}
export default ProductAttributeValueComponent;
