import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { getFormattedDateStringYYYYMMDD } from 'Util/Date';
import { Field } from 'Util/Query';

/** @namespace AdmScandipwa/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    /**
     * @extends to add @function _getCustomAddressesField to @function _getAddressesField
     */

    _getAddressesField() {
        return new Field('addresses')
            .addFieldList([...this._getAddressFields(), ...this._getCustomAddressesField()]);
    }

    getCustomerIdQuery() {
        return new Field('km_fetchCustomer')
            .addField(new Field('customer_id'));
    }

    getUpdateInformationMutation(options) {
        const customerData = {
            ...options,
            date_of_birth: getFormattedDateStringYYYYMMDD(options.date_of_birth)
        };

        return new Field('updateCustomer')
            .addArgument('input', 'CustomerInput!', customerData)
            .addField(this._getCustomerField());
    }

    getCreateAccountMutation(options) {
        const { customer, password } = options;

        const customerData = {
            ...customer,
            password,
            date_of_birth: getFormattedDateStringYYYYMMDD(customer.date_of_birth)
        };

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', customerData)
            .addField(this._getCustomerField());
    }

    _getCustomAddressesField() {
        return [
            'city_id',
            'district',
            'district_id'
        ];
    }

    getDeleteAccountMutation() {
        return new Field('deleteCustomer')
            .addArgument('action', 'String!', 'delete');
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'all_reward_card_no',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'gender',
            'date_of_birth',
            this._getAddressesField()
        ];
    }

    getAllRewardValidation(cardNumber) {
        return new Field('km_validateAllRewardNo')
            .addArgument('guestCartId', 'String!', '')
            .addArgument('cardNo', 'String!', cardNumber)
            .addFieldList(['card_no', 'is_valid']);
    }
}

export default new MyAccountQuery();
