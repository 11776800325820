import { productHasStock } from 'Util/Product';

export * from 'SourceUtil/Cart/Cart.js';

/** @namespace AdmScandipwa/Util/Cart/hasItemError */
export const hasItemError = (cartProps) => {
    const {
        totals: { items }
    } = cartProps;

    return items.some((item) => productHasStock({ item }) === false);
};

/** @namespace AdmScandipwa/Util/Cart/hasMinimumAmountError */
export const hasMinimumAmountError = (cartProps) => {
    const {
        minimumOrderAmount,
        minimumOrderEnabled,
        totals: { subtotal, items }
    } = cartProps;

    return minimumOrderEnabled && subtotal <= minimumOrderAmount && items.length;
};
