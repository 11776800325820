import { FORM_IDS, LOCATION_DATA } from 'Component/SelectLocationPopup/SelectLocationPopup.config';
import {
    debounce,
    formatURI, getGraphqlEndpoint, getStoreCodePath, getWindowId,
    handleConnectionError, HTTP_201_CREATED,
    HTTP_410_GONE, listenForBroadCast, putPersistedQuery
} from 'SourceUtil/Request/Request';
import { getAuthorizationToken } from 'Util/Auth';
import { isSeoCrawler } from 'Util/Bots';
import BrowserDatabase from 'Util/BrowserDatabase';
import { GRAPHQL_AUTHORIZATION_ERROR } from 'Util/Request/Request.config';
import getStore from 'Util/Store';

import { ignoreOosCheckList } from './Request.config';

/** @namespace AdmScandipwa/Util/Request/prerenderDefaultLocation */
export const prerenderDefaultLocation = () => window.prerendererLoc.replace('selected-location: ', '');

/** @namespace AdmScandipwa/Util/Request/isGenerateCustomerTokenQuery */
export const isGenerateCustomerTokenQuery = (query = '') => query.includes('generateCustomerToken');
/** @namespace AdmScandipwa/Util/Request/ignoreOosCheck */
export const ignoreOosCheck = (query = '') => !!ignoreOosCheckList.find((item) => query.includes(item));

/**
 * @returns {city,district,region} selected data or false
 * @namespace AdmScandipwa/Util/Request/selectedLocation
 */
export const selectedLocation = () => {
    if (isSeoCrawler()) {
        return prerenderDefaultLocation();
    }

    const locationData = BrowserDatabase.getItem(LOCATION_DATA) || {};
    const { CITY, REGION, DISTRICT } = FORM_IDS;
    const selectedData = {
        city: locationData[CITY]?.value || false,
        district: locationData[DISTRICT]?.value || false,
        region: locationData[REGION]?.value || false
    };

    return JSON.stringify(selectedData);
};

/** @namespace AdmScandipwa/Util/Request/checkForErrors */
export const checkForErrors = (res) => new Promise((resolve, reject) => {
    const { errors, data } = res;
    if (errors) {
        const isAuthorizationError = (Array.isArray(errors) ? errors : [])
            .find((error) => error?.extensions?.category === GRAPHQL_AUTHORIZATION_ERROR);

        if (isAuthorizationError) {
            const MyAccountDispatcher = import('../../store/MyAccount/MyAccount.dispatcher');
            MyAccountDispatcher.then(
                ({ default: dispatcher }) => dispatcher.logout(true, getStore().dispatch)
            );
        }

        return reject(errors);
    }

    return resolve(data);
});

/** @namespace AdmScandipwa/Util/Request/parseResponse */
export const parseResponse = (promise) => new Promise((resolve, reject) => {
    promise.then(
        /** @namespace AdmScandipwa/Util/Request/then */
        (res) => res.json().then(
            /** @namespace AdmScandipwa/Util/Request/json/then */
            (res) => resolve(checkForErrors(res)),
            /** @namespace AdmScandipwa/Util/Request/json/then */
            () => handleConnectionError('Can not transform JSON!') && reject()
        ),
        /** @namespace AdmScandipwa/Util/Request/then */
        (err) => handleConnectionError('Can not establish connection!') && reject(err)
    );
});

/** @namespace AdmScandipwa/Util/Request/getAppSource */
export const getAppSource = () => {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const isIOS = !!navigator.platform.match(/iPhone|iPod|iPad/);
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;
    // eslint-disable-next-line no-nested-ternary
    const isTWA = isAndroid ? window.sessionStorage.getItem('isAndroidApp') === 'true'
        : (isIOS ? !/safari/i.test(navigator.userAgent) : false);

    if (isAndroid && isTWA) {
        return 'android-app';
    }
    if (isAndroid && isPWA) {
        return 'android-pwa';
    }
    if (isAndroid) {
        return 'android-web';
    }
    if (isIOS && isTWA) {
        return 'ios-app';
    }
    if (isIOS && isPWA) {
        return 'ios-pwa';
    }
    if (isIOS) {
        return 'ios-web';
    }
    if (isPWA) {
        return 'desktop-pwa';
    }

    return 'desktop-web';
};

/** @namespace AdmScandipwa/Util/Request/appendTokenToHeaders */
export const appendTokenToHeaders = (headers) => {
    const token = getAuthorizationToken();

    return {
        ...headers,
        'Request-Source': getAppSource(),
        Authorization: token ? `Bearer ${token}` : ''
    };
};

/**
 * @extends to add 'Select-Location and Is-GenerateCustomerToken-Query' Header
 * @namespace AdmScandipwa/Util/Request/postFetch
 */
export const postFetch = (graphQlURI, query, variables) => fetch(graphQlURI,
    {
        method: 'POST',
        body: JSON.stringify({ query, variables }),
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Selected-Location': selectedLocation(),
            'Is-GenerateCustomerToken-Query': isGenerateCustomerTokenQuery(query),
            ignoreOosCheck: ignoreOosCheck(query),
            'Is-Pdp': false,
            'Is-Plp': false
        })
    });

/** @namespace AdmScandipwa/Util/Request/executePost */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;
    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables));
};

/**
 * @extends to add 'Select-Location and Is-GenerateCustomerToken-Query' Header
 * @namespace AdmScandipwa/Util/Request/getFetch
 */

export const getFetch = (uri, name) => fetch(uri,
    {
        method: 'GET',
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            'Application-Model': `${name}_${getWindowId()}`,
            Accept: 'application/json',
            'Selected-Location': selectedLocation(),
            'Is-GenerateCustomerToken-Query': isGenerateCustomerTokenQuery(),
            ignoreOosCheck: false,
            'Is-Pdp': name === 'Product',
            'Is-Plp': name === 'Category' || name === 'ProductListInfo' || name === 'ProductList'
        })
    });

/** @namespace AdmScandipwa/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace AdmScandipwa/Util/Request/getFetch/then */
            (res) => {
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace AdmScandipwa/Util/Request/putPersistedQuery/then */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace AdmScandipwa/Util/Request/getFetch/then */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else {
                    resolve(res);
                }
            }
        );
    }));
};

export {
    debounce,
    formatURI, getGraphqlEndpoint, getStoreCodePath, getWindowId,
    handleConnectionError, listenForBroadCast, putPersistedQuery
};
